import React, { useState, useEffect } from "react";
import axios from 'axios';

async function getMedia(token, tappa) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/media?id_tappa_cammino=${tappa}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    }
  })
    .then(data => data.json())
  return result;
}



async function deleteImg(token, tappa) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/media/${tappa}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    }
  })
    .then(data => data.json())
  return result;

}


const Gallery = (props) => {
  const [file, setFile] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [media, setmedia] = useState([]);


  const media_ = useEffect(async () => {
    if (props.token_ && props.tappa) {
      let result = await getMedia(props.token_, props.tappa);
      if (result.length > 0) {
        await setmedia(result);
      }
    }
  }, []);

  async function uploadSingleFile(e) {
    let gallery_ = gallery;
    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    );

    gallery_.push(e.target.files)


    await setGallery(gallery_)
    setFile([...file, ...ImagesArray]);

  }

  async function upload(e) {


    for (let i = 0; i < gallery.length; i++) {
      const formData = new FormData()
      const file = gallery[i][0]
      formData.append('profileImg', file)
      formData.append('body', JSON.stringify({
        id_tappa_cammino: props.tappa
      }))
      let result = await axios.post(`${process.env.REACT_APP_API_KEY}/admin/media`, formData, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${props.token_}`
        },
      })
    }

    let result = await getMedia(props.token_, props.tappa);
    if (result.length > 0) {
      await setFile([]);
      await setGallery([]);
      await setmedia(result);
    }

    e.preventDefault();

  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  return (
    <div className="mb-2">
      <div className="preview" style={{ marginTop: "40px" }}>

        <label data-error="wrong" data-success="right" for="orangeForm-name">Gallery</label>
        {media?.map((immagine, index) =>
          <div>
            <img src={immagine.path} />
            <div className="div-img" style={{textAlign:"center", display:"block"}}>
              <img
                className="cestino-gallery"
                onClick={async () => {
                  //setShowDelete(true);
                  let delete_img = await deleteImg(props.token_, immagine.id)
                  if (delete_img.status == "ok") {
                    let result = await getMedia(props.token_, props.tappa);
                    await setmedia(result);
                  }
                }}
                style={{ cursor: "pointer", width: "10px" }} src="/deleteTable.svg" />
            </div>
          </div>
        )
        }
      </div>

      <label style={{ marginTop: "40px" }} data-error="wrong" data-success="right" for="orangeForm-name">Aggiungi Immagini alla Gallery</label>


      <div className="form-group preview">
        {file?.length >= 0 &&
          file.map((item, index) => {
            return (
              <div key={item}>
                <img src={item} alt="" />
                <div className="div-img" style={{textAlign:"center", display:"block"}}>
                <img
                  className="cestino-gallery"
                  onClick={() => {
                    //setShowDelete(true);
                    deleteFile(index)
                  }}
                  style={{ cursor: "pointer", width: "10px" }} src="/deleteTable.svg" />
              </div>
              </div>
            );
          })}
      </div>

      <div className="form-group">
        <input
          style={{ marginTop: "20px" }}
          type="file"
          multiple
          disabled={file.length === 5}
          className="form-control"
          onChange={(e) => {
            let upload = uploadSingleFile(e)

          }}

        />
      </div>
      <button
        type="button"
        className="btn btn-primary btn-block btn-preview"
        onClick={() => {
          let upload_ = upload()
        }}
      >
        Carica Immagini
      </button>
    </div>
  );
};

export default Gallery;
