/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "../App.css";
import NavbarHorizontal from "./NavbarHorizonal";
import { Link } from "react-router-dom";
import useToken from "../Components/useToken";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

async function getAlloggi(token) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/alloggi?stato=0`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());
  return result;
}

async function getAlloggi1(token) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/prenotazioni?stato=In%20attesa%20di%20conferma`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  let utenti = [];

  let result_ = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());

  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < result_.length; j++) {
      if (result[i].id_utente == result_[j].id) {
        utenti.push(result_);
      }
    }
  }

  return { result, utenti };
}

export default function NavbarVertical({}) {
  const [conferma, setConferma] = useState(false);
  const [conferma1, setConferma1] = useState(false);

  const { token, setToken } = useToken();
  const [currentURL, setcurrentURL] = useState(false);
  const location = useLocation();

  /*const get_alloggi = useEffect(async () => {

        await setcurrentURL(location.pathname)



        let result1 = await getAlloggi1(token);
        if (result1.result.length > 0) {
            await setConferma(true);
        }
        else {
            await setConferma(false);
        }

        let result = await getAlloggi(token);

        if (result.length > 0) {
            await setConferma1(true);
        }
        else {
            await setConferma1(false);
        }

        setInterval(async () => {
            let result1 = await getAlloggi1(token);
            if (result1.result.length > 0) {

                await setConferma(true);
            }
            else {
                await setConferma(false);
            }

            let result = await getAlloggi(token);

            if (result.length > 0) {
                await setConferma1(true);
            }
            else {
                await setConferma1(false);
            }

        }, 500000);


    }, []);*/

  return (
    <div className="login-wrapper">
      <div>
        <NavbarHorizontal />
      </div>
      <div
        className="nav flex-column nav-pills navbar-vertical-custom"
        style={{
          height: "96vh",
          position: "absolute",
          left: "2vh",
          top: "2vh",
          width: "280px",
          borderRadius: "20px",
          zIndex: "100",
          paddingTop: "20px",
          paddingLeft: "35px",
          paddingRight: "35px",
          backgroundColor: "#ffffff",
          color: "#ffffff",
          display: "flex",
          justifyContent: "center",
        }}
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <Link
          to={!location.pathname.includes("/en") ? `/cammini` : `/en/cammini`}
        >
          <a
            onClick={() => {
              setcurrentURL("/cammini");
            }}
            style={{ marginBottom: "20px" }}
            className={
              currentURL == "/cammini" ||
              currentURL == "/" ||
              currentURL == "/en"
                ? "button2-menu button2-menu-active"
                : "button2-menu"
            }
          >
            <img
              className={
                currentURL == "/cammini" ||
                currentURL == "/" ||
                currentURL == "/en"
                  ? "menu-green-menu"
                  : ""
              }
              style={{ margin: "0 auto" }}
              src="/camminiIcon.svg"
            />
            Cammini
          </a>
        </Link>
        <Link
          to={
            !location.pathname.includes("/en") ? `/itinerari` : `/en/itinerari`
          }
        >
          <a
            onClick={() => {
              setcurrentURL("/itinerari");
            }}
            style={{ marginBottom: "20px" }}
            className={
              currentURL == "/itinerari"
                ? "button2-menu button2-menu-active"
                : "button2-menu"
            }
          >
            <img
              className={currentURL == "/itinerari" ? "menu-green-menu" : ""}
              style={{ margin: "0 auto" }}
              src="/camminiIcon.svg"
            />
            Itinerari
          </a>
        </Link>
        <Link to={!location.pathname.includes("/en") ? `/tappe` : `/en/tappe`}>
          <a
            style={{ marginBottom: "20px" }}
            onClick={() => {
              setcurrentURL("/tappe");
            }}
            className={
              currentURL == "/tappe"
                ? "button2-menu button2-menu-active"
                : "button2-menu"
            }
          >
            <img
              className={currentURL == "/tappe" ? "menu-green-menu" : ""}
              style={{ margin: "0 auto" }}
              src="/tappeIcone.svg"
              alt=""
            />
            Tappe
          </a>
        </Link>
        <Link
          to={!location.pathname.includes("/en") ? `/alloggi` : `/en/alloggi`}
          style={{ position: "relative" }}
        >
          <a
            style={{ marginBottom: "20px" }}
            onClick={() => {
              setcurrentURL("/alloggi");
            }}
            className={
              currentURL === "/alloggi"
                ? "button2-menu button2-menu-active"
                : "button2-menu"
            }
          >
            <img
              className={currentURL === "/alloggi" ? "menu-green-menu" : ""}
              style={{ margin: "0 auto" }}
              src="/struttureIcon.svg"
              alt=""
            />
            Strutture
          </a>{" "}
          {conferma1 && (
            <div
              style={{
                position: "absolute",
                width: "7px",
                height: "7px",
                bottom: "14px",
                right: "0%",
                backgroundColor: "red",
                borderRadius: "100%",
              }}
            ></div>
          )}
        </Link>
        <Link
          to={
            !location.pathname.includes("/en")
              ? `/prenotazioni`
              : `/en/prenotazioni`
          }
          style={{ position: "relative" }}
        >
          <a
            style={{ marginBottom: "20px" }}
            onClick={() => {
              setcurrentURL("/prenotazioni");
            }}
            className={
              currentURL == "/prenotazioni"
                ? "button2-menu button2-menu-active"
                : "button2-menu "
            }
          >
            <img
              alt=""
              className={currentURL === "/prenotazioni" ? "menu-green-menu" : ""}
              style={{ margin: "0 auto" }}
              src="/prenotazioniIcon.svg"
            />
            Prenotazioni
          </a>
          {conferma && (
            <div
              style={{
                position: "absolute",
                width: "7px",
                height: "7px",
                bottom: "14px",
                right: "0%",
                backgroundColor: "red",
                borderRadius: "100%",
              }}
            ></div>
          )}
        </Link>
        <Link
          to={!location.pathname.includes("/en") ? `/utenti` : `/en/utenti`}
        >
          <a
            style={{ marginBottom: "20px" }}
            onClick={() => {
              setcurrentURL("/utenti");
            }}
            className={
              currentURL == "/utenti"
                ? "button2-menu button2-menu-active"
                : "button2-menu"
            }
          >
            <img
              className={currentURL == "/utenti" ? "menu-green-menu" : ""}
              style={{ margin: "0 auto" }}
              src="/utentiIcon.svg"
              alt=""
            />
            Utenti
          </a>
        </Link>
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          <button
            className={
              !location.pathname.includes("/en") ? "button-lingue-active" : ""
            }
            onClick={async () => {
              window.location = "/";
            }}
            style={{
              marginRight: "2%",
              border: "none",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "15px",
              height: "40px",
              width: "50%",
              fontSize: "0.9em",
            }}
          >
            It
          </button>
          <button
            className={
              location.pathname.includes("/en") ? "button-lingue-active" : ""
            }
            onClick={async () => {
              window.location = "/en";
            }}
            style={{
              marginLeft: "2%",
              border: "none",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "15px",
              height: "40px",
              width: "50%",
              fontSize: "0.9em",
            }}
          >
            En
          </button>
        </div>
        <div style={{ marginTop: "15px" }}>
          <a
            href="mailto:info@slowbags.eu"
            style={{
              marginBottom: "35px",
              position: "relative",
              left: "15px",
              cursor: "pointer",
            }}
            onClick={() => {
              setcurrentURL("/aiuto");
            }}
            className={
              currentURL == "/aiuto"
                ? "button2-menu button2-menu-active"
                : "button2-menu"
            }
          >
            <img
              className={currentURL == "/aiuto" ? "menu-green-menu" : ""}
              style={{ margin: "0 auto" }}
              src="/aiutoIcon.svg"
              alt=""
            />
            Supporto
          </a>
          <br></br>
          <Link
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("admin");
              window.location.reload();
            }}
            style={{ position: "relative", top: "15px" }}
            to="/login"
          >
            <a
              style={{ marginBottom: "20px", color: "#9D9D9D" }}
              onClick={() => {
                setcurrentURL("/login");
              }}
              className={
                currentURL == "/login"
                  ? "button2-menu button2-menu-active"
                  : "button2-menu"
              }
            >
              <img style={{ margin: "0 auto" }} src="/logout.svg" alt="" />
              Log-out
            </a>
          </Link>
          <div style={{ textAlign: "center" }}>
            <img
              alt=""
              style={{
                position: "relative",
                top: "75px",
                left: "20px",
                width: "140px",
              }}
              src="/logo.svg"
            ></img>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
