/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { Offcanvas } from "react-bootstrap";

import useToken from "../Components/useToken";
import SearchForm from "../Components/SearchForm";
import ModalError from "../Components/ModalError";
import ModalDeletePrenotazione from "../Components/ModalDeletePrenotazione";
import {
  getAlloggi,
  getAlloggiByStatus,
  getAllUtenti,
  getStrutture,
  getTappeAll,
  deletecammino,
  getPrenotazioni,
  updatecammino,
  getCammino,
  checkCampi,
  insertcammino2,
  insertcammino
} from "../Common/utils";

const mapStatus = {
  1: { name: "Pagato", color: "#A9DE5B" },
  2: { name: "Annullato", color: "#FF1400" },
  3: { name: "In attesa", color: "#5552f3" },
  4: { name: "Non pagato", color: "#DE8900" },
  4: { name: "In attesa di conferma", color: "#DE8900" },
}

const Tappe = () => {
  const [open, setOpen] = useState(false);

  const { token } = useToken();
  const [alloggi, setAlloggi] = useState([]);
  const [alloggiBackup, setAlloggiBackup] = useState([]);
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showAddPrenotazione, setShowAddPrenotazione] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [data_prenotata, setDataPrenotata] = useState();
  const [id_prenotazione_, setIdPrenotazione_] = useState(0);
  const [giorno, setGiorno] = useState("");
  const [users_all, setAllUsers] = useState([]);
  const [id_cammino, setIdcammino] = useState(0);

  const [strutture, setStrutture] = useState([]);
  const [tappe_all, setTappeAll] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [stato_prenotazione, setStatoPrenotazione] = useState(
    "Pagato"
  );
  const [newGiorno, setNewGiorno] = useState({});
  const [cammino_associato, setCamminoAssociato] = useState("");
  const [bagagli, setBagagli] = useState(0);
  const [totale, setTotale] = useState(0);
  const [loader, setLoader] = useState(false);
  const [prenotazioni, setPrenotazioni] = useState([]);
  const [id_alloggio_inizio, setIdAlloggioInizio] = useState(0);
  const [id_alloggio_fine, setIdAlloggioFine] = useState(0);
  const [id_utente, setIdUtente] = useState(0);
  const [nome_tappa_inizio, setNomeTappaInizio] = useState("");
  const [nome_tappa_fine, setNomeTappaFine] = useState("");

  const search = (keyword) =>
    setAlloggi(
      alloggiBackup.filter((el) =>
        el.stato_prenotazione.toUpperCase().includes(keyword.toUpperCase())
      )
    );

  /* Handle functions */
  const handleShowUpdate = () => setShowUpdate(true);

  const handleShow = () => setShow(true);

  const handleClosePrenotazione = () => {
    setShowAddPrenotazione(false);
    setModalError(false);
  };

  const updateConfermaStatus = async () => {
    const result = await getAlloggiByStatus(token);
  };

  const handleCloseUpdate = async () => {
    setShowUpdate(false);
    setOpen();
    setPrenotazioni([]);
    await updateConfermaStatus();
  };

  const handleShowInfo = () => setShowInfo(true);

  const handleCloseInfo = async () => {
    setShowInfo(false);
    setOpen();
    setPrenotazioni([]);
    await updateConfermaStatus();
  };

  const handleClose = async () => {
    setShow(false);
    setModalError(false);
    await updateConfermaStatus();
  };

  const start = async () => {
    setLoader(true);
    const result = await getAlloggi(token);
    setAlloggi(result);
    setAlloggiBackup(result);
    setLoader(false);
    await updateConfermaStatus();
    setInterval(async () => {
      const result = await getAlloggi(token);
      setAlloggi(result);
      setAlloggiBackup(result);
      await updateConfermaStatus();
    }, 500000);
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <div>
      <ModalError modalError={modalError} setModalError={setModalError} />
      <ModalDeletePrenotazione
        deletecammino={deletecammino}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        handleCloseInfo={handleCloseInfo}
        id_cammino={id_cammino}
        token={token}
        getAlloggi={getAlloggi}
        setAlloggi={setAlloggi}
        setOpen={setOpen}
      />


      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showUpdate}
        onHide={handleCloseUpdate}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseUpdate}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Prenotazioni - Modifica
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <h1
              style={{
                fontSize: "1.3em",
                fontWeight: "700",
                marginBottom: "0",
                marginTop: "30px",
              }}
            >
              Modifica
            </h1>
            <h2 style={{ fontSize: "1.6em", fontWeight: "300" }}>
              {nome_tappa_inizio} - {nome_tappa_fine}
            </h2>
            <div>
              <div>
                <div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Stato prenotazione
                    </label>
                    <select
                      defaultValue={stato_prenotazione}
                      onChange={async (e) => {
                        await setStatoPrenotazione(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value="Pagato">Pagato</option>
                      <option value="In attesa di conferma">In attesa</option>
                      <option value="Non pagato">Non pagato</option>
                      <option value="Annullato">Annullato</option>
                    </select>

                    <div className="  mb-2">
                      <i className="fas fa-user prefix grey-text"></i>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Totale
                      </label>
                      <input
                        defaultValue={totale}
                        onChange={async (e) => {
                          await setTotale(e.target.value);
                        }}
                        type="number"
                        id="orangeForm-name"
                        className="form-control validate"
                      />
                    </div>

                    <div className="  mb-2">
                      <i className="fas fa-user prefix grey-text"></i>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Quantità bagagli
                      </label>
                      <input
                        defaultValue={bagagli}
                        onChange={async (e) => {
                          await setBagagli(e.target.value);
                        }}
                        type="number"
                        id="orangeForm-name"
                        className="form-control validate"
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <button
                    onClick={async () => {
                      let check_campi = await checkCampi(
                        stato_prenotazione,
                        data_prenotata,
                        totale,
                        bagagli
                      );
                      if (check_campi == true) {
                        await setModalError(false);
                        let resultInsert = await updatecammino(
                          token,
                          id_cammino,
                          id_prenotazione_,
                          stato_prenotazione,
                          data_prenotata,
                          totale,
                          bagagli
                        );
                        if (resultInsert.statusText == "OK") {
                          handleCloseUpdate();
                          await setModalError(false);
                          let result_post_update = await getAlloggi(token);
                          setAlloggi(result_post_update);
                          setAlloggiBackup(result_post_update);
                          if (stato_prenotazione == "In attesa di conferma") {
                            window.location.reload();
                          }
                        }
                      } else {
                        await setModalError(check_campi);
                      }
                    }}
                    className="btn btn-deep-orange close btn-update"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div></div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header>
          <img
            onClick={handleClose}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Prenotazioni - Aggiungi
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <div>
              <div>
                <div>
                  <h1
                    style={{
                      fontSize: "1.3em",
                      fontWeight: "700",
                      marginBottom: "40px",
                      marginTop: "30px",
                    }}
                  >
                    Aggiungi Prenotazione
                  </h1>
                </div>

                <div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>

                    <label>Utente</label>
                    <select
                      onChange={async (e) => {
                        await setIdUtente(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value={"seleziona un utente"}>
                        Seleziona un utente
                      </option>
                      {users_all.map((utente, index) => {
                        return (
                          <option value={utente.id}>
                            {utente.id} - {utente.nome} {utente.cognome}
                          </option>
                        );
                      })}
                    </select>

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Stato prenotazione
                    </label>
                    <select
                      defaultValue={"Pagato"}
                      onChange={async (e) => {
                        await setStatoPrenotazione(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value="Pagato">Pagato</option>
                      <option value="In attesa di conferma">In attesa</option>
                      <option value="Non pagato">Non pagato</option>
                      <option value="Annullato">Annullato</option>
                    </select>

                    <div className="  mb-2">
                      <i className="fas fa-user prefix grey-text"></i>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Totale
                      </label>
                      <input
                        defaultValue={totale}
                        onChange={async (e) => {
                          await setTotale(e.target.value);
                        }}
                        type="number"
                        id="orangeForm-name"
                        className="form-control validate"
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    onClick={async () => {
                      let check_campi = await checkCampi(
                        stato_prenotazione,
                        data_prenotata,
                        totale,
                        bagagli
                      );
                      if (check_campi == true) {
                        await setModalError(false);
                        let resultInsert = await insertcammino(
                          token,
                          id_utente,
                          stato_prenotazione,
                          totale
                        );
                        if (resultInsert.statusText == "OK") {
                          handleClose();
                          await setModalError(false);
                          let result_post_delete = await getAlloggi(token);
                          setAlloggi(result_post_delete);
                          setAlloggiBackup(result_post_delete);
                        }
                      } else {
                        await setModalError(check_campi);
                      }
                    }}
                    className="btn btn-deep-orange close btn-update"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Inserisci Prenotazione
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showAddPrenotazione}
        onHide={handleClosePrenotazione}
      >
        <Offcanvas.Header>
          <img
            onClick={handleClosePrenotazione}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Prenotazioni - Aggiungi
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <div>
              <div>
                <div>
                  <h1
                    style={{
                      fontSize: "1.3em",
                      fontWeight: "700",
                      marginBottom: "40px",
                      marginTop: "30px",
                    }}
                  >
                    Aggiungi Prenotazione
                  </h1>
                </div>

                <div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Costo
                    </label>
                    <input
                      onChange={async (e) => {
                        await setNewGiorno({
                          ...newGiorno,
                          costo: e.target.value,
                        })
                      }}
                      type="number"
                      id="orangeForm-name"
                      className="form-control validate"
                    />

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Data
                    </label>
                    <input
                      onChange={async (e) => {
                        await setNewGiorno({
                          ...newGiorno,
                          data: e.target.value,
                        })
                      }}
                      type="date"
                      id="orangeForm-name"
                      className="form-control validate"
                    />

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Stato
                    </label>
                    <select
                      onChange={async (e) => {
                        await setNewGiorno({
                          ...newGiorno,
                          stato: e.target.value,
                        })
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value="1">Pagato</option>
                      <option value="2">Cancellato</option>
                      <option value="3">Rimborsato</option>
                      <option value="4">Link inviato</option>
                    </select>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Tappa d'inizio
                    </label>

                    {
                      <select
                        onChange={async (e) => {
                          await setNewGiorno({
                            ...newGiorno,
                            tappa_inizio: e.target.value,
                            nome_tappa_inizio: e.target.selectedOptions[0].getAttribute(
                              "data-name"
                            )
                          })
                        }}
                        type="text"
                        id="orangeForm-name"
                        className="form-control validate"
                      >
                        <option value={"seleziona un utente"}>
                          Seleziona una tappa
                        </option>
                        {tappe_all.map((tappa, index) => {
                          return (
                            <option value={tappa.id} data-name={tappa.nome}>
                              {tappa.id} - {tappa.nome}
                            </option>
                          );
                        })}
                      </select>
                    }

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Tappa di fine
                    </label>
                    {
                      <select
                        onChange={async (e) => {
                          await await setNewGiorno({
                            ...newGiorno,
                            tappa_fine: e.target.value,
                            nome_tappa_fine: e.target.selectedOptions[0].getAttribute(
                              "data-name"
                            )
                          })
                        }}
                        type="text"
                        id="orangeForm-name"
                        className="form-control validate"
                      >
                        <option value={"seleziona un utente"}>
                          Seleziona una tappa
                        </option>
                        {tappe_all.map((tappa, index) => {
                          return (
                            <option value={tappa.id} data-name={tappa.nome}>
                              {tappa.id} - {tappa.nome}
                            </option>
                          );
                        })}
                      </select>
                    }

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Struttura di ritiro
                    </label>
                    {
                      <select
                        onChange={async (e) => {
                          setNewGiorno({
                            ...newGiorno,
                            alloggio_ritiro: e.target.value,
                          })
                        }}
                        type="text"
                        id="orangeForm-name"
                        className="form-control validate"
                      >
                        <option value={"seleziona un utente"}>
                          Seleziona un alloggio
                        </option>

                        {strutture.map((alloggio, index) => {
                          return (
                            <option value={alloggio.id}>
                              {alloggio.id} - {alloggio.nome}
                            </option>
                          );
                        })}
                      </select>
                    }

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Struttura di consegna
                    </label>
                    {
                      <select
                        onChange={async (e) => {
                          setNewGiorno({
                            ...newGiorno,
                            alloggio_consegna: e.target.value,
                          })
                        }}
                        type="text"
                        id="orangeForm-name"
                        className="form-control validate"
                      >
                        <option value={"seleziona un utente"}>
                          Seleziona un alloggio
                        </option>
                        {strutture.map((alloggio, index) => {
                          return (
                            <option value={alloggio.id}>
                              {alloggio.id} - {alloggio.nome}
                            </option>
                          );
                        })}
                      </select>
                    }

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Numero Bagagli
                    </label>
                    <input
                      onChange={async (e) => {
                        await setNewGiorno({
                          ...newGiorno,
                          bagagli: e.target.value,
                        })
                      }}
                      type="number"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    onClick={async () => {
                      await setModalError(false);
                      let resultInsert = await insertcammino2(
                        token,
                        newGiorno.tappa_inizio,
                        newGiorno.tappa_fine,
                        newGiorno.costo,
                        newGiorno.data,
                        newGiorno.alloggio_ritiro,
                        newGiorno.alloggio_consegna,
                        newGiorno.bagagli,
                        newGiorno.stato,
                        id_prenotazione_,
                        id_utente,
                        newGiorno.nome_tappa_inizio,
                        newGiorno.nome_tappa_fine
                      );
                      if (resultInsert.status == 200) {
                        handleClosePrenotazione();
                        //window.location.reload();
                      } else {
                        await setModalError(resultInsert.data);
                      }
                    }}
                    className="btn btn-deep-orange close btn-update"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Inserisci Prenotazione 12 {id_utente}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showInfo}
        onHide={handleCloseInfo}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseInfo}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Prenotazioni - Info
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div>
              <div>
                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <div
                    style={{ bottom: "50px", left: "30px", textAlign: "right" }}
                  >
                    <img
                      onClick={() => {
                        setShowInfo(false);
                        setShowUpdate(true);
                      }}
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        width: "40px",
                      }}
                      src="/updateTable.svg"
                    />
                    <img
                      onClick={() => {
                        setShowDelete(true);
                        setShowInfo(false);
                      }}
                      style={{
                        cursor: "pointer",
                        display: "inline-block",
                        marginLeft: "10px",
                        width: "40px",
                      }}
                      src="/deleteTable.svg"
                    />
                  </div>
                  <div className=" mb-2">
                    <h2
                      style={{
                        color: "#B1B1B1",
                        fontSize: "1.5em",
                        fontWeight: "300",
                      }}
                    >
                      {giorno}
                    </h2>
                    <h2
                      style={{
                        color: "#2F2F2F",
                        fontSize: "1.5em",
                        fontWeight: "600",
                        marginBottom: "20px",
                      }}
                    >
                      {nome_tappa_inizio} - {nome_tappa_fine}
                    </h2>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Id Prenotazione</b>
                    <p>{id_cammino}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Id utente</b>
                    <p>{id_utente}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Data Prenotata</b>
                    <p>{new Date(data_prenotata).toLocaleDateString()}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Totale Prenotazione</b>
                    <p>{totale}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Stato Prenotazione</b>
                    <p>{stato_prenotazione}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Nº Bagagli</b>
                    <p>{bagagli}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Partenza</b>
                    <p>{nome_tappa_inizio}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Arrivo</b>
                    <p>{nome_tappa_fine}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Alloggio di Partenza</b>
                    <p>{id_alloggio_inizio}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Alloggio di Arrivo</b>
                    <p>{id_alloggio_fine}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div
        style={{
          position: "absolute",
          top: "0",
          paddingTop: "65px",
          width: "100%",
          backgroundColor: "#F4F4F4",
          zIndex: "1000",
        }}
      >
        <h1 className="title-sezione">
          <img
            src="prenotazioniIcon.svg"
            style={{
              position: "relative",
              bottom: "3px",
              width: "23px",
              marginRight: "12px",
            }}
            alt=""
          />
          Prenotazioni
        </h1>
        <div style={{ marginTop: "40px", width: "75%" }}>
          <SearchForm nome="Cerca" search={search} />
          <button
            variant="primary"
            onClick={async () => {
              let users_all = await getAllUtenti(token);
              await setAllUsers(users_all);
              handleShow();
            }}
            className="btn btn-default btn-rounded mb-4 aggiungi-struttura "
            data-toggle="modal"
            data-target="#modalRegisterForm"
            style={{
              position: "relative",
              float: "right",
              border: "none",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "20px",
              height: "50px",
              width: "20%",
              fontSize: "0.9em",
              minWidth: "200px",
            }}
          >
            Aggiungi Prenotazione
          </button>
        </div>
      </div>
      <div
        className="alloggi overflow-responsive"
        style={{
          marginTop: "240px",
          paddingBottom: "60px",
          borderRadius: "25px",
          position: "relative",
          bottom: "70px",
        }}
      >
        <div
          style={{
            width: "100%",
            paddingLeft: "23px",
            paddingRight: "23px",
            fontWeight: "600",
            fontSize: "1em",
          }}
          className=" row "
        >
          <div className="col-1">Id</div>
          <div className="col-4">Utente</div>
          <div className="col-4">Totale</div>
        </div>
        {!loader && (
          <div
            className="row prenotazioni"
            style={{ width: "100%", maxWidth: "max-content" }}
          >
            {alloggi?.result?.map((cammino, index) => (
              <div>
                <div className="tr-white" key={cammino.id}>
                  <div
                    scope="row"
                    style={{
                      width: "100%",
                      paddingLeft: "23px",
                      paddingRight: "23px",
                    }}
                  >
                    <div
                      className="col-1"
                      style={{ paddingLeft: "0px", display: "inline-block" }}
                    >
                      {cammino.id}
                    </div>
                    <div
                      style={{
                        paddingLeft: "3px",
                        fontWeight: "700",
                        display: "inline-block",
                      }}
                      className="col-4"
                    >
                      {alloggi.utenti[index].nome}{" "}
                      {alloggi.utenti[index].cognome}
                    </div>
                    <div
                      style={{
                        paddingLeft: "15px",
                        fontWeight: "300",
                        display: "inline-block",
                      }}
                      className="col-2"
                    >
                      {cammino.totale} €
                    </div>
                    <div
                      className="col-4"
                      style={{
                        paddingLeft: "15px",
                        display: "inline-block",
                        textAlign: "right",
                        position: "relative",
                      }}
                    >
                      {open != cammino.id && (
                        <button
                          onClick={async () => {
                            await setIdPrenotazione_(cammino.id);
                            let prenotazioni_singole = await getPrenotazioni(
                              token,
                              cammino.id
                            );
                            let cammino_associato = await getCammino(
                              token,
                              prenotazioni_singole[0]?.id_tappa_inizio,
                              cammino.id
                            );
                            console.log(cammino_associato);
                            await setTotale(cammino.totale);
                            await setPrenotazioni(prenotazioni_singole);
                            if (cammino_associato)
                              await setCamminoAssociato(cammino_associato.nome);
                            await setStatoPrenotazione(cammino.stato);
                            await setIdUtente(alloggi.utenti[index].id);
                            setOpen(cammino.id);

                          }}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          className="btn-prenotazione"
                          style={{
                            color: "#B1B1B1",
                            fontWeight: "600",
                            fontSize: "1.1em",
                            border: "1px solid #B1B1B1",
                            borderRadius: "15px",
                            backgroundColor: "transparent",
                            height: "48px",
                          }}
                        >
                          Dettagli
                        </button>
                      )}
                      {open == cammino.id && (
                        <button
                          onClick={async () => {
                            setOpen(false);
                            await setTotale(0);
                            await setIdUtente(alloggi.utenti[index].id);
                            await setStatoPrenotazione("");
                            await setPrenotazioni([]);
                            await setCamminoAssociato("");
                          }}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          className="btn-prenotazione btn-prenotazione1"
                          style={{
                            color: "#ffffff",
                            fontWeight: "600",
                            fontSize: "1.1em",
                            border: "none",
                            borderRadius: "15px",
                            backgroundColor: "#FF9D00",
                            height: "48px",
                          }}
                        >
                          Chiudi
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <Collapse in={open == cammino.id}>
                  <div id="example-collapse-text">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {prenotazioni?.length > 0 &&
                        prenotazioni?.sort((a, b) => new Date(a.data_prenotazione) - new Date(b.data_prenotazione)).
                          map((cammino, index) => (
                            <div
                              style={{
                                position: "relative",
                                width: "33%",
                                marginBottom: "20px",
                                padding: "5px"
                              }}
                              key={cammino.id}
                            >
                              <div
                                className="div-cammini"
                                style={{
                                  display: "inline-block",
                                  backgroundColor: "#ffffff",
                                  position: "relative",
                                  borderRadius: "20px",
                                  padding: "19px",
                                  width: "100%",
                                }}
                              >
                                <p
                                  style={{
                                    paddingTop: "20px",
                                    color: "#B1B1B1",
                                    fontWeight: "300",
                                    fontSize: "1em",
                                    marginBottom: "3px",
                                  }}
                                >
                                  {new Date(cammino.data_prenotazione).toLocaleDateString()}
                                </p>
                                <p
                                  style={{
                                    color: "#2F2F2F",
                                    fontWeight: "600",
                                    fontSize: "1em",
                                    marginBottom: "3px",
                                  }}
                                >
                                  {cammino.nome_tappa_inizio} -{" "}
                                  {cammino.nome_tappa_fine}
                                </p>
                                <p
                                  style={{
                                    color: mapStatus[cammino.stato].color,
                                    fontWeight: "bold",
                                    fontSize: "1em",
                                    marginBottom: "3px",
                                  }}
                                >
                                  Stato: {
                                    mapStatus[cammino.stato].name
                                  }
                                </p>
                                <p
                                  style={{
                                    color: "#2F2F2F",
                                    fontWeight: "300",
                                    fontSize: "1em",
                                    marginBottom: "3px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Costo: {cammino.costo_prenotazione_singola}€
                                </p>
                                <p
                                  style={{
                                    color: "#2F2F2F",
                                    fontWeight: "300",
                                    fontSize: "1em",
                                    marginBottom: "3px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Bagagli: {cammino.quantita_bagagli}
                                </p>

                                <div>
                                  <button
                                    onClick={async () => {
                                      let giorno = `Giorno ${index + 1}`;
                                      await setGiorno(giorno);
                                      await setIdcammino(cammino.id);
                                      await setBagagli(cammino.quantita_bagagli);
                                      await setNomeTappaInizio(
                                        cammino.nome_tappa_inizio
                                      );
                                      await setNomeTappaFine(
                                        cammino.nome_tappa_fine
                                      );
                                      await setDataPrenotata(
                                        cammino.data_prenotazione
                                      );
                                      await setIdAlloggioInizio(
                                        cammino.alloggio_inizio_nome
                                      );
                                      console.log(cammino);
                                      await setIdAlloggioFine(
                                        cammino.alloggio_fine_nome
                                      );
                                      await setStatoPrenotazione(
                                        mapStatus[cammino.stato].name

                                      );

                                      handleShowInfo();
                                    }}
                                    style={{
                                      backgroundColor: "transparent",
                                      boxShadow: "none",
                                      border: "none",
                                      paddingLeft: "0",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "30px",
                                        marginRight: "5px",
                                      }}
                                      src="./infoTable.svg"
                                    />
                                  </button>
                                  <button
                                    onClick={async () => {
                                      let giorno = `Giorno ${index + 1}`;
                                      await setGiorno(giorno);
                                      await setIdcammino(cammino.id);
                                      await setBagagli(cammino.quantita_bagagli);
                                      await setNomeTappaInizio(
                                        cammino.nome_tappa_inizio
                                      );
                                      await setNomeTappaFine(
                                        cammino.nome_tappa_fine
                                      );
                                      await setDataPrenotata(
                                        cammino.data_prenotazione
                                      );
                                      await setIdAlloggioInizio(
                                        cammino.id_alloggio_inizio
                                      );
                                      await setIdAlloggioFine(
                                        cammino.id_alloggio_fine
                                      );
                                      handleShowUpdate();
                                    }}
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      boxShadow: "none",
                                      paddingLeft: "0",
                                    }}
                                  >
                                    <img
                                      style={{ width: "30px" }}
                                      src="./updateTable.svg"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      <div
                        style={{
                          position: "relative",
                          width: "33%",
                          marginBottom: "20px",
                          minHeight: "160px",
                          padding: "5px"
                        }}
                        key={cammino.id}
                      >

                        <div
                          className="div-cammini"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            backgroundColor: "#B1B1B1",
                            color: "#fff",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            padding: "5px",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "20px",
                            marginTop: "0px",
                            marginBottom: "20px",
                            minHeight: "220px",
                          }}
                          key={cammino.id}
                        >
                          <div
                            onClick={async () => {
                              await setStrutture(await getStrutture(token));
                              await setTappeAll(await getTappeAll(token));
                              await setShowAddPrenotazione(true);
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "1.5em",
                                fontWeight: "100",
                                marginBottom: "5px",
                                lineHeight: "1em",
                              }}
                            >
                              +
                            </p>
                            <p style={{ fontWeight: "bold" }}>
                              Aggiungi giorno
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        )}

        {loader && (
          <div style={{ textAlign: "center", paddingTop: "200px" }}>
            <img src="/loader.gif" style={{ borderRadius: "100%" }} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tappe;
