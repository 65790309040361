import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import Alloggi from './Pages/Alloggi';
import Itinerari from './Pages/Itinerari';
import Cammini from './Pages/Cammini';
import Tappe from './Pages/Tappe';
import Prenotazioni from './Pages/Prenotazioni';
import Utenti from './Pages/Utenti';
import NavbarVertical from './Components/NavbarVertical';
import useToken from './Components/useToken';
import AlloggiEn from './Pages/en/Alloggi';
import ItinerariEn from './Pages/en/Itinerari';
import CamminiEn from './Pages/en/Cammini';
import TappeEn from './Pages/en/Tappe';
import PrenotazioniEn from './Pages/en/Prenotazioni';
import UtentiEn from './Pages/en/Utenti';


function App() {
  const { token, setToken } = useToken();
  const { admin, setAdmin } = useToken();

  if (!token) {
    return <Login setAdmin={setAdmin} setToken={setToken} />
  }

  return (
    <div>

      <Router>
        <div className='row' style={{ height: "100vh" }}>
          <div className='col-12 col-lg-2' style={{ paddingRight: "0", backgroundColor: "#F4F4F4", zIndex: "0" }}>
            <NavbarVertical />
          </div>
          <div className='col-12 col-lg-10 col-right-responsive' style={{ minHeight: "883px", backgroundColor: "#F4F4F4", paddingLeft: "60px", paddingRight: "90px", paddingTop: "60px", paddingBottom: "50px" }}>

            <Fragment>
              <Routes>
                <Route exact path='/' element={<Cammini />} />
              </Routes>
              <Routes>
                <Route exact path='/login' element={<Login />} />
              </Routes>
              <Routes>
                <Route exact path='/alloggi' element={<Alloggi />} />
              </Routes>
              <Routes>
                <Route exact path='/cammini' element={<Cammini />} />
              </Routes>
              <Routes>
                <Route exact path='/itinerari' element={<Itinerari />} />
              </Routes>
              <Routes>
                <Route exact path='/tappe' element={<Tappe />} />
              </Routes>
              <Routes>
                <Route exact path='/utenti' element={<Utenti />} />
              </Routes>
              <Routes>
                <Route exact path='/prenotazioni' element={<Prenotazioni />} />
              </Routes>
              <Routes>
                <Route exact path='/en' element={<CamminiEn />} />
              </Routes>
              <Routes>
                <Route exact path='/login' element={<Login />} />
              </Routes>
              <Routes>
                <Route exact path='/en/alloggi' element={<AlloggiEn />} />
              </Routes>
              <Routes>
                <Route exact path='/en/cammini' element={<CamminiEn />} />
              </Routes>
              <Routes>
                <Route exact path='/en/itinerari' element={<ItinerariEn />} />
              </Routes>
              <Routes>
                <Route exact path='/en/tappe' element={<TappeEn />} />
              </Routes>
              <Routes>
                <Route exact path='/en/utenti' element={<UtentiEn />} />
              </Routes>
              <Routes>
                <Route exact path='/en/prenotazioni' element={<PrenotazioniEn />} />
              </Routes>
            </Fragment>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;

