import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../Components/CheckoutForm";
import useToken from "../../Components/useToken";
import React, { useState, useEffect } from "react";
import { Modal, Button, Collapse } from "react-bootstrap";
import SearchForm from "../../Components/SearchForm";
import axios from "axios";
import { Navbar, Nav, Container, Offcanvas } from "react-bootstrap";

async function getAlloggi(token) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/prenotazioni`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());
  let utenti = [];
  if (result != null) {
    result.sort(function (a, b) {
      return b.id - a.id;
    });
  }

  let result_ = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());

  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < result_.length; j++) {
      if (result[i].id_utente == result_[j].id) {
        await utenti.push(result_[j]);
      }
    }
  }

  return { result, utenti };
}

async function getAllUtenti(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function getStrutture(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/alloggi`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function getTappeAll(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/tappe`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function getAlloggi2(token) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/prenotazioni?stato=Pagato`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  let utenti = [];

  let result_ = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());

  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < result_.length; j++) {
      if (result[i].id_utente == result_[j].id) {
        await utenti.push(result_[j]);
      }
    }
  }
  return { result, utenti };
}
async function getAlloggi3(token) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/prenotazioni?stato=Annullato`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  let utenti = [];

  let result_ = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());

  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < result_.length; j++) {
      if (result[i].id_utente == result_[j].id) {
        await utenti.push(result_[j]);
      }
    }
  }
  return { result, utenti };
}

async function getAlloggi1(token) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/prenotazioni?stato=In%20attesa%20di%20conferma`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  let utenti = [];

  let result_ = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());

  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < result_.length; j++) {
      if (result[i].id_utente == result_[j].id) {
        await utenti.push(result_[j]);
      }
    }
  }

  return { result, utenti };
}

async function getUtente(token, utente) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/users?id=${utente}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  return result;
}

async function getPrenotazioni(token, id) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/tappe_prenotazioni_alloggi?id_prenotazione=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  return result;
}

async function getTappe(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/cammini`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function getCammino(token, id) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/tappe?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  let result_cammino = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/cammini?id=${result[0].id_cammino}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  return result_cammino[0];
}

async function deletecammino(token, id_cammino) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/tappe_prenotazioni_alloggi/${id_cammino}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());

  return result;
}

async function checkCampi(stato_prenotazione, data_prenotata, totale, bagagli) {
  if (!stato_prenotazione || stato_prenotazione == "") {
    return "Errore. Inserire stato prenotazione";
  } else if (!data_prenotata || data_prenotata == "") {
    return "Errore. Inserire una data di prenotazione.";
  } else if (!totale || totale == 0) {
    return "Errore. Inserire Totale prenotazione.";
  } else return true;
}

async function checkCampiInsert(
  tappa_inizio,
  tappa_fine,
  costo,
  data,
  struttura_inizio,
  struttura_fine,
  bagagli,
  id_prenotazione
) {
  if (!costo || costo == 0) {
    return "Errore. Inserire costo.";
  } else if (!data || data == "") {
    return "Errore. Inserire data.";
  } else if (!tappa_inizio || tappa_inizio == "") {
    return "Errore. Inserire tappa inizio.";
  } else if (!tappa_fine || tappa_fine == "") {
    return "Errore. Inserire tappa fine.";
  } else if (!struttura_inizio || struttura_inizio == "") {
    return "Errore. Inserire struttura di ritiro bagaglio.";
  } else if (!struttura_fine || struttura_fine == "") {
    return "Errore. Inserire struttura di ritiro bagaglio.";
  } else if (!bagagli || bagagli == "") {
    return "Errore. Inserire bagagli.";
  } else if (!id_prenotazione || id_prenotazione == "") {
    return "Errore. Inserire id prenotazione.";
  } else return true;
}

async function insertcammino(token, id_utente, stato_prenotazione, totale) {
  const body = {
    id_utente: id_utente,
    stato: stato_prenotazione,
    totale: totale,
  };

  let result = await axios.post(
    `${process.env.REACT_APP_API_KEY}/admin/prenotazioni`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result;
}

async function updatecammino(
  token,
  id_cammino,
  id_prenotazione,
  stato_prenotazione,
  data_prenotata,
  totale,
  bagagli
) {
  const body = { stato: stato_prenotazione, totale: totale };
  let result = await axios.put(
    `${process.env.REACT_APP_API_KEY}/admin/prenotazioni/${id_prenotazione}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const formData = new FormData();

  //const str = data_prenotata;

  //const date = new Date(str);
  const body_ = {
    quantita_bagagli: bagagli,
    //  data_prenotazione: date
  };

  if (result.statusText == "OK") {
    let result_ = await axios.put(
      `${process.env.REACT_APP_API_KEY}/admin/tappe_prenotazioni_alloggi/${id_cammino}`,
      body_,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result_;
  }
}

async function updatecammino1(token, id_prenotazione) {
  const body = { stato: "Pagato" };
  let result = await axios.put(
    `${process.env.REACT_APP_API_KEY}/admin/prenotazioni/${id_prenotazione}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
}

const Tappe = () => {
  const search = (keyword) =>
    setAlloggi(
      alloggiBackup.filter((el) =>
        el.stato_prenotazione.toUpperCase().includes(keyword.toUpperCase())
      )
    );

  const searchLuogo = (keyword) =>
    setAlloggi(
      alloggiBackup.filter((el) =>
        el.luogo.toUpperCase().includes(keyword.toUpperCase())
      )
    );

  const searchEmail = (keyword) =>
    setAlloggi(
      alloggiBackup.filter((el) =>
        el.email.toUpperCase().includes(keyword.toUpperCase())
      )
    );

  const [filtri, setFiltri] = useState(false);
  const [open, setOpen] = useState(false);
  const { token, setToken } = useToken();
  const [alloggi, setAlloggi] = useState([]);
  const [alloggiBackup, setAlloggiBackup] = useState([]);
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [showAddPrenotazione, setShowAddPrenotazione] = useState(false);

  const [showUpdate, setShowUpdate] = useState(false);
  const [modalError, setModalError] = useState("");
  const [data_prenotata, setDataPrenotata] = useState();
  const [id_prenotazione_, setIdPrenotazione_] = useState(0);

  const handleShowUpdate = () => setShowUpdate(true);

  const handleClosePrenotazione = () => {
    setShowAddPrenotazione(false);
    setModalError("");
  };

  const handleCloseUpdate = async () => {
    setShowUpdate(false);
    setOpen();
    setPrenotazioni([]);
    let result1 = await getAlloggi1(token);
    if (result1.result.length > 0) {
      await setConferma(true);
    } else {
      await setConferma(false);
    }
  };

  const handleShowInfo = async () => setShowInfo(true);
  const handleCloseInfo = async () => {
    setShowInfo(false);
    setOpen();
    setPrenotazioni([]);
    let result1 = await getAlloggi1(token);
    if (result1.result.length > 0) {
      await setConferma(true);
    } else {
      await setConferma(false);
    }
  };
  const handleClose = async () => {
    setShow(false);
    setModalError("");
    setTappe([]);
    setNome("");
    setDescrizione("");
    setFoto("");
    setPrezzoMin(0);
    setPrezzoMax(0);
    setLink("");
    setLuogo("");
    setNumeroTelefono(0);
    setEmail("");
    setTappa(0);
    setPrePrenotazione(0);
    let result1 = await getAlloggi1(token);
    if (result1.result.length > 0) {
      await setConferma(true);
    } else {
      await setConferma(false);
    }
  };

  const [alloggio_ritiro, setAlloggioRitiro] = useState();
  const [alloggio_consegna, setAlloggioConsegna] = useState();
  const handleShow = () => setShow(true);
  const [tappe, setTappe] = useState([]);
  const [nome, setNome] = useState("");
  const [giorno, setGiorno] = useState("");
  const [users_all, setAllUsers] = useState([]);
  const [id_cammino, setIdcammino] = useState(0);
  const [descrizione, setDescrizione] = useState("");
  const [foto, setFoto] = useState("");
  const [file, setFile] = useState();

  const [strutture, setStrutture] = useState([]);
  const [tappe_all, setTappeAll] = useState([]);

  const [prezzo_min, setPrezzoMin] = useState(0);
  const [prezzo_max, setPrezzoMax] = useState(0);
  const [link, setLink] = useState("");
  const [luogo, setLuogo] = useState("");
  const [numero_telefono, setNumeroTelefono] = useState(0);
  const [email, setEmail] = useState("");
  const [tappa, setTappa] = useState(0);
  const [costo_pre_prenotazione, setPrePrenotazione] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [stato_prenotazione, setStatoPrenotazione] = useState(false);
  const [cammino_associato, setCamminoAssociato] = useState("");
  const [bagagli, setBagagli] = useState(0);
  const [totale, setTotale] = useState(0);
  const [loader, setLoader] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [conferma, setConferma] = useState(false);
  const [filtraTesto, setFiltraTesto] = useState("FILTRA");
  const [tappa_inizio, setTappaInizio] = useState(0);
  const [tappa_fine, setTappaFine] = useState(0);

  const [costo, setCosto] = useState(0);
  const [utente, setUtente] = useState();
  const [prenotazioni, setPrenotazioni] = useState([]);

  const [data, setData] = useState();

  const [totale_prenotazione_associata, setTotalePrenotazioneAssociata] =
    useState(0);
  const [stato_prenotazione_associata, setStatoPrenotazioneAssociata] =
    useState("");

  const [id_prenotazione_associata, setIdPrenotazioneAssociata] = useState(0);
  const [id_tappa_inizio, setIdTappaInizio] = useState();
  const [id_tappa_fine, setIdTappaFine] = useState();
  const [id_alloggio_inizio, setIdAlloggioInizio] = useState(0);
  const [id_alloggio_fine, setIdAlloggioFine] = useState(0);
  const [id_utente, setIdUtente] = useState(0);
  const [quantita_bagagli, setQuantitaBagagli] = useState(0);
  const [nome_tappa_inizio, setNomeTappaInizio] = useState("");
  const [nome_tappa_fine, setNomeTappaFine] = useState("");

  const [livello, setLivello] = useState(0);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [tempo, setTempo] = useState(0);
  const [distanza, setDistanza] = useState(0);
  const [ordine, setOrdine] = useState(0);

  const get_alloggi = useEffect(async () => {
    setLoader(true);
    let result = await getAlloggi(token);
    setAlloggi(result);
    setAlloggiBackup(result);
    setLoader(false);

    let result1 = await getAlloggi1(token);
    if (result1.result.length > 0) {
      await setConferma(true);
    } else {
      await setConferma(false);
    }

    setInterval(async () => {
      let result = await getAlloggi(token);
      setAlloggi(result);
      setAlloggiBackup(result);
      setLoader(false);

      let result1 = await getAlloggi1(token);
      if (result1.result.length > 0) {
        await setConferma(true);
      } else {
        await setConferma(false);
      }
    }, 500000);
  }, []);

  return (
    <div>
      <Modal
        show={modalError != ""}
        onHide={() => {
          setModalError("");
        }}
      >
        <Modal.Body>
          <div>
            <img
              onClick={() => {
                setModalError("");
              }}
              className="btn-close-img"
              src="/closeBtn.svg"
            />
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          >
            <img
              style={{ width: "60px" }}
              className="btn-close-img1"
              src="/errorImage.svg"
            />
            <div
              style={{
                color: "#FF1400",
                fontSize: "1.4em",
                fontWeight: "500",
                marginTop: "20px",
                marginBottom: "0px",
              }}
            >
              {modalError}
              <br></br>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showDelete} onHide={handleCloseInfo}>
        <Modal.Body>
          <div>
            <img
              onClick={() => {
                setShowDelete(false);
              }}
              className="btn-close-img"
              src="/closeBtn.svg"
            />
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          >
            <img
              style={{ width: "50px" }}
              className="btn-close-img1"
              src="/deletePopup.svg"
            />
            <p
              style={{
                color: "#FF1400",
                fontSize: "1.7em",
                fontWeight: "500",
                marginTop: "20px",
                marginBottom: "0px",
              }}
            >
              Elimina Prenotazione
            </p>
            <p
              style={{
                color: "#2F2F2F",
                fontSize: "1.1em",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              Vuoi eliminare prenotazione con id{" "}
              <span style={{ fontWeight: "700" }}>{id_cammino}?</span>
            </p>
            <button
              onClick={async () => {
                await deletecammino(token, id_cammino);
                let result_post_delete = await getAlloggi(token);
                setShowDelete(false);
                setOpen();
                setAlloggi(result_post_delete);
              }}
              className="buttonDelete"
              style={{
                marginTop: "15px",
                fontWeight: "600",
                height: "55px",
                backgroundColor: "transparent",
                border: "1px solid #FF1400",
                color: "#FF1400",
                fontSize: "1em",
                width: "40%",
                borderRadius: "20px",
              }}
            >
              Elimina
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showUpdate}
        onHide={handleCloseUpdate}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseUpdate}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Prenotazioni - Modifica
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <h1
              style={{
                fontSize: "1.3em",
                fontWeight: "700",
                marginBottom: "0",
                marginTop: "30px",
              }}
            >
              Modifica
            </h1>
            <h2 style={{ fontSize: "1.6em", fontWeight: "300" }}>
              {nome_tappa_inizio} - {nome_tappa_fine}
            </h2>
            <div>
              <div>
                <div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Stato prenotazione
                    </label>
                    <select
                      defaultValue={stato_prenotazione}
                      onChange={async (e) => {
                        await setStatoPrenotazione(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value="Pagato">Pagato</option>
                      <option value="In attesa di conferma">In attesa</option>
                      <option value="Non pagato">Non pagato</option>
                      <option value="Annullato">Annullato</option>
                    </select>

                    <div className="  mb-2">
                      <i className="fas fa-user prefix grey-text"></i>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Totale
                      </label>
                      <input
                        defaultValue={totale}
                        onChange={async (e) => {
                          await setTotale(e.target.value);
                        }}
                        type="number"
                        id="orangeForm-name"
                        className="form-control validate"
                      />
                    </div>

                    <div className="  mb-2">
                      <i className="fas fa-user prefix grey-text"></i>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Quantità bagagli
                      </label>
                      <input
                        defaultValue={bagagli}
                        onChange={async (e) => {
                          await setBagagli(e.target.value);
                        }}
                        type="number"
                        id="orangeForm-name"
                        className="form-control validate"
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <button
                    onClick={async () => {
                      let check_campi = await checkCampi(
                        stato_prenotazione,
                        data_prenotata,
                        totale,
                        bagagli
                      );
                      if (check_campi == true) {
                        await setModalError("");
                        let resultInsert = await updatecammino(
                          token,
                          id_cammino,
                          id_prenotazione_,
                          stato_prenotazione,
                          data_prenotata,
                          totale,
                          bagagli
                        );
                        if (resultInsert.statusText == "OK") {
                          handleCloseUpdate();
                          await setFile();
                          await setModalError("");
                          let result_post_update = await getAlloggi(token);
                          setAlloggi(result_post_update);
                          setAlloggiBackup(result_post_update);
                          if (stato_prenotazione == "In attesa di conferma") {
                            window.location.reload();
                          }
                        }
                      } else {
                        await setModalError(check_campi);
                      }
                    }}
                    className="btn btn-deep-orange close btn-update"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div></div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header>
          <img
            onClick={handleClose}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Prenotazioni - Aggiungi
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <div>
              <div>
                <div>
                  <h1
                    style={{
                      fontSize: "1.3em",
                      fontWeight: "700",
                      marginBottom: "40px",
                      marginTop: "30px",
                    }}
                  >
                    Aggiungi Prenotazione
                  </h1>
                </div>

                <div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>

                    <label>Utente</label>
                    <select
                      onChange={async (e) => {
                        await setIdUtente(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value={"seleziona un utente"}>
                        Seleziona un utente
                      </option>
                      {users_all.map((utente, index) => {
                        return (
                          <option value={utente.id}>
                            {utente.id} - {utente.nome} {utente.cognome}
                          </option>
                        );
                      })}
                    </select>

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Stato prenotazione
                    </label>
                    <select
                      defaultValue={stato_prenotazione}
                      onChange={async (e) => {
                        await setStatoPrenotazione(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value="Pagato">Pagato</option>
                      <option value="In attesa di conferma">In attesa</option>
                      <option value="Non pagato">Non pagato</option>
                      <option value="Annullato">Annullato</option>
                    </select>

                    <div className="  mb-2">
                      <i className="fas fa-user prefix grey-text"></i>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Totale
                      </label>
                      <input
                        defaultValue={totale}
                        onChange={async (e) => {
                          await setTotale(e.target.value);
                        }}
                        type="number"
                        id="orangeForm-name"
                        className="form-control validate"
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    onClick={async () => {
                      let check_campi = await checkCampi(
                        stato_prenotazione,
                        totale,
                        bagagli
                      );
                      if (check_campi == true) {
                        await setModalError("");
                        let resultInsert = await insertcammino(
                          token,
                          id_utente,
                          stato_prenotazione,
                          totale
                        );
                        console.log(resultInsert);
                        if (resultInsert.statusText == "OK") {
                          handleClose();
                          await setModalError("");
                          let result_post_delete = await getAlloggi(token);
                          setAlloggi(result_post_delete);
                          setAlloggiBackup(result_post_delete);
                        }
                      } else {
                        await setModalError(check_campi);
                      }
                    }}
                    className="btn btn-deep-orange close btn-update"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Inserisci Prenotazione
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showAddPrenotazione}
        onHide={handleClosePrenotazione}
      >
        <Offcanvas.Header>
          <img
            onClick={handleClosePrenotazione}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Prenotazioni - Aggiungi
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <div>
              <div>
                <div>
                  <h1
                    style={{
                      fontSize: "1.3em",
                      fontWeight: "700",
                      marginBottom: "40px",
                      marginTop: "30px",
                    }}
                  >
                    Aggiungi Prenotazione
                  </h1>
                </div>

                <div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Costo
                    </label>
                    <input
                      onChange={async (e) => {
                        await setCosto(e.target.value);
                      }}
                      type="number"
                      id="orangeForm-name"
                      className="form-control validate"
                    />

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Data
                    </label>
                    <input
                      onChange={async (e) => {
                        await setCosto(e.target.value);
                      }}
                      type="date"
                      id="orangeForm-name"
                      className="form-control validate"
                    />

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Tappa d'inizio
                    </label>

                    {
                      <select
                        onChange={async (e) => {
                          await setTappaInizio(e.target.value);
                        }}
                        type="text"
                        id="orangeForm-name"
                        className="form-control validate"
                      >
                        <option value={"seleziona un utente"}>
                          Seleziona una tappa
                        </option>
                        {tappe_all.map((tappa, index) => {
                          return (
                            <option value={tappa.id}>
                              {tappa.id} - {tappa.nome}
                            </option>
                          );
                        })}
                      </select>
                    }

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Tappa di fine
                    </label>
                    {
                      <select
                        onChange={async (e) => {
                          await setTappaFine(e.target.value);
                        }}
                        type="text"
                        id="orangeForm-name"
                        className="form-control validate"
                      >
                        <option value={"seleziona un utente"}>
                          Seleziona una tappa
                        </option>
                        {tappe_all.map((tappa, index) => {
                          return (
                            <option value={tappa.id}>
                              {tappa.id} - {tappa.nome}
                            </option>
                          );
                        })}
                      </select>
                    }

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Struttura di ritiro
                    </label>
                    {
                      <select
                        onChange={async (e) => {
                          await setAlloggioRitiro(e.target.value);
                        }}
                        type="text"
                        id="orangeForm-name"
                        className="form-control validate"
                      >
                        <option value={"seleziona un utente"}>
                          Seleziona un alloggio
                        </option>

                        {strutture.map((alloggio, index) => {
                          return (
                            <option value={alloggio.id}>
                              {alloggio.id} - {alloggio.nome}
                            </option>
                          );
                        })}
                      </select>
                    }

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Struttura di consegna
                    </label>
                    {
                      <select
                        onChange={async (e) => {
                          await setAlloggioConsegna(e.target.value);
                        }}
                        type="text"
                        id="orangeForm-name"
                        className="form-control validate"
                      >
                        <option value={"seleziona un utente"}>
                          Seleziona un alloggio
                        </option>
                        {strutture.map((alloggio, index) => {
                          return (
                            <option value={alloggio.id}>
                              {alloggio.id} - {alloggio.nome}
                            </option>
                          );
                        })}
                      </select>
                    }

                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Numero Bagagli
                    </label>
                    <input
                      onChange={async (e) => {
                        await setBagagli(e.target.value);
                      }}
                      type="number"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    onClick={async () => {
                      let check_campi = await checkCampiInsert(
                        tappa_inizio,
                        tappa_fine,
                        costo,
                        data,
                        alloggio_ritiro,
                        alloggio_consegna,
                        bagagli,
                        id_prenotazione_
                      );
                      if (check_campi == true) {
                        await setModalError("");
                        let resultInsert = await insertcammino(
                          token,
                          id_utente,
                          stato_prenotazione,
                          totale
                        );
                        console.log(resultInsert);
                        if (resultInsert.statusText == "OK") {
                          handleClose();
                          await setModalError("");
                          let result_post_delete = await getAlloggi(token);
                          setAlloggi(result_post_delete);
                          setAlloggiBackup(result_post_delete);
                        }
                      } else {
                        await setModalError(check_campi);
                      }
                    }}
                    className="btn btn-deep-orange close btn-update"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Inserisci Prenotazione
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showInfo}
        onHide={handleCloseInfo}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseInfo}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Prenotazioni - Info
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div>
              <div>
                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <div
                    style={{ bottom: "50px", left: "30px", textAlign: "right" }}
                  >
                    <img
                      onClick={() => {
                        setShowInfo(false);
                        setShowUpdate(true);
                      }}
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        width: "40px",
                      }}
                      src="/updateTable.svg"
                    />
                    <img
                      onClick={() => {
                        setShowDelete(true);
                        setShowInfo(false);
                      }}
                      style={{
                        cursor: "pointer",
                        display: "inline-block",
                        marginLeft: "10px",
                        width: "40px",
                      }}
                      src="/deleteTable.svg"
                    />
                  </div>
                  <div className=" mb-2">
                    <h2
                      style={{
                        color: "#B1B1B1",
                        fontSize: "1.5em",
                        fontWeight: "300",
                      }}
                    >
                      {giorno}
                    </h2>
                    <h2
                      style={{
                        color: "#2F2F2F",
                        fontSize: "1.5em",
                        fontWeight: "600",
                        marginBottom: "20px",
                      }}
                    >
                      {nome_tappa_inizio} - {nome_tappa_fine}
                    </h2>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Id Prenotazione</b>
                    <p>{id_cammino}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Id utente</b>
                    <p>{id_utente}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Data Prenotata</b>
                    <p>{new Date(data_prenotata).toLocaleDateString()}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Totale Prenotazione</b>
                    <p>{totale}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Stato Prenotazione</b>
                    <p>{stato_prenotazione}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Nº Bagagli</b>
                    <p>{bagagli}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Partenza</b>
                    <p>{nome_tappa_inizio}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Arrivo</b>
                    <p>{nome_tappa_fine}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Id alloggio di Partenza</b>
                    <p>{id_alloggio_inizio}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Id alloggio di Arrivo</b>
                    <p>{id_alloggio_fine}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div
        style={{
          position: "absolute",
          top: "0",
          paddingTop: "65px",
          width: "100%",
          backgroundColor: "#F4F4F4",
          zIndex: "1000",
        }}
      >
        <h1 className="title-sezione">
          <img
            src="/prenotazioniIcon.svg"
            style={{
              position: "relative",
              bottom: "3px",
              width: "23px",
              marginRight: "12px",
            }}
          />
          Prenotazioni
        </h1>
        <div style={{ marginTop: "40px", width: "75%" }}>
          <SearchForm nome="Cerca" search={search} />

          <button
            variant="primary"
            onClick={async () => {
              let users_all = await getAllUtenti(token);
              await setAllUsers(users_all);

              handleShow();
              let get_tappe = await getTappe();
              await setTappe(get_tappe);
            }}
            className="btn btn-default btn-rounded mb-4 aggiungi-struttura "
            data-toggle="modal"
            data-target="#modalRegisterForm"
            style={{
              position: "relative",
              float: "right",
              border: "none",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "20px",
              height: "50px",
              width: "20%",
              fontSize: "0.9em",
            }}
          >
            Aggiungi Prenotazione
          </button>
        </div>
      </div>
      <div
        className="alloggi overflow-responsive"
        style={{
          marginTop: "240px",
          paddingBottom: "60px",
          borderRadius: "25px",
          position: "relative",
          bottom: "70px",
        }}
      >
        <div
          style={{
            width: "100%",
            paddingLeft: "23px",
            paddingRight: "23px",
            fontWeight: "600",
            fontSize: "1em",
          }}
          className=" row "
        >
          <div className="col-2">ID Prenotazione</div>
          <div className="col-2">Utente</div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setFiltri(!filtri);
            }}
            className="col-2"
          >
            {conferma && (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "7px",
                  height: "7px",
                  bottom: "1px",
                  right: "8px",
                  backgroundColor: "red",
                  borderRadius: "100%",
                }}
              ></div>
            )}
            Stato
            <div
              onClick={() => {
                setFiltri(!filtri);
              }}
              className="circle pulse green1"
            >
              <img
                style={{
                  width: "11px",
                  left: "0px",
                  bottom: "6px",
                  position: "relative",
                }}
                src="/filtri.png"
              />
            </div>
            {filtri && (
              <div style={{ position: "absolute", width: "13%" }}>
                <select
                  defaultValue={stato_prenotazione}
                  onChange={async (e) => {
                    if (e.target.value == 3) {
                      let result = await getAlloggi1(token);
                      await setOpen1(true);
                      setAlloggi(result);
                      setAlloggiBackup(result);
                    } else if (e.target.value == 2) {
                      let result = await getAlloggi2(token);
                      await setOpen1(true);
                      setAlloggi(result);
                      setAlloggiBackup(result);
                    } else if (e.target.value == 4) {
                      let result = await getAlloggi3(token);
                      await setOpen1(true);
                      setAlloggi(result);
                      setAlloggiBackup(result);
                    } else if (e.target.value == 1) {
                      let result = await getAlloggi(token);
                      await setOpen1(true);
                      setAlloggi(result);
                      setAlloggiBackup(result);
                    }
                  }}
                  type="text"
                  id="orangeForm-name"
                  className="form-control validate"
                >
                  <option value={1}>FILTRA</option>
                  <option value={1}>Tutte</option>
                  <option value={2}>Confermato</option>
                  <option value={3}>In Progresso</option>
                  <option value={4}>Annullato</option>
                </select>
                <span
                  style={{
                    position: "relative",
                    left: "160px",
                    color: "#1a1a1a",
                    fontWeight: "300",
                    pointerEvents: "none",
                    cursor: "pointer",
                    bottom: "30px",
                  }}
                >
                  &#x2938;
                </span>
              </div>
            )}
          </div>
          <div className="col-2">Totale</div>
        </div>
        {!loader && (
          <div
            className=" row prenotazioni"
            style={{ width: "1400px", maxWidth: "max-content" }}
          >
            {alloggi?.result?.map((cammino, index) => (
              <div>
                <div className="tr-white" key={cammino.id}>
                  <div
                    scope="row"
                    style={{
                      width: "100%",
                      paddingLeft: "23px",
                      paddingRight: "23px",
                    }}
                  >
                    <div
                      className="col-2"
                      style={{ paddingLeft: "0px", display: "inline-block" }}
                    >
                      {cammino.id}
                    </div>
                    <div
                      style={{
                        paddingLeft: "3px",
                        fontWeight: "700",
                        display: "inline-block",
                      }}
                      className="col-2"
                    >
                      {" "}
                      {alloggi.utenti[index].nome}{" "}
                      {alloggi.utenti[index].cognome}
                    </div>
                    <div
                      style={{
                        paddingLeft: "3spx",
                        fontWeight: "700",
                        display: "inline-block",
                        color: `${
                          cammino.stato == "Pagato"
                            ? "#A9DE5B"
                            : cammino.stato == "In attesa di conferma"
                            ? "#DE8900"
                            : cammino.stato == "Annullato"
                            ? "#5552f3"
                            : "#FF1400"
                        }`,
                      }}
                      className="col-2"
                    >
                      {cammino.stato}
                    </div>
                    <div
                      style={{
                        paddingLeft: "15px",
                        fontWeight: "300",
                        display: "inline-block",
                      }}
                      className="col-2"
                    >
                      {cammino.totale} €
                    </div>
                    <div
                      className="col-4"
                      style={{
                        paddingLeft: "15px",
                        display: "inline-block",
                        textAlign: "right",
                        position: "relative",
                      }}
                    >
                      {cammino.stato == "In attesa di conferma" && (
                        <button
                          onClick={async () => {
                            let resultInsert = await updatecammino1(
                              token,
                              cammino.id
                            );
                            if (resultInsert.affectedRows == 1) {
                              let result = await getAlloggi1(token);
                              await setOpen1(true);
                              setAlloggi(result);
                              setAlloggiBackup(result);
                              window.location.reload();
                            }
                          }}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          className="btn-prenotazione btn-prenotazione2"
                          style={{
                            boxShadow: "0px 3px 6px #00000029",
                            backgroundColor: "#9B3601",
                            color: "#ffffff",
                            fontWeight: "600",
                            fontSize: "1em",
                            width: "50%",
                            border: "none",
                            borderRadius: "15px",
                            height: "48px",
                            position: "absolute",
                            right: "330px",
                          }}
                        >
                          {" "}
                          Conferma Prenotazione
                        </button>
                      )}

                      {open != cammino.id && (
                        <button
                          onClick={async () => {
                            setOpen(cammino.id);
                            let prenotazioni_singole = await getPrenotazioni(
                              token,
                              cammino.id
                            );
                            let cammino_associato = await getCammino(
                              token,
                              prenotazioni_singole[0].id_tappa_inizio
                            );
                            await setIdPrenotazione_(cammino.id);
                            await setTotale(cammino.totale);
                            await setPrenotazioni(prenotazioni_singole);
                            await setCamminoAssociato(cammino_associato.nome);
                            await setStatoPrenotazione(cammino.stato);
                            await setIdUtente(alloggi.utenti[index].id);
                          }}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          className="btn-prenotazione"
                          style={{
                            color: "#B1B1B1",
                            fontWeight: "600",
                            fontSize: "1.1em",
                            width: "70%",
                            border: "1px solid #B1B1B1",
                            borderRadius: "15px",
                            backgroundColor: "transparent",
                            height: "48px",
                            position: "relative",
                            bottom: "0px",
                          }}
                        >
                          Dettagli
                        </button>
                      )}
                      {open == cammino.id && (
                        <button
                          onClick={async () => {
                            setOpen(false);
                            await setTotale(0);
                            await setIdUtente(0);
                            await setStatoPrenotazione("");
                            await setPrenotazioni([]);
                            await setCamminoAssociato("");
                          }}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          className="btn-prenotazione btn-prenotazione1"
                          style={{
                            color: "#ffffff",
                            fontWeight: "600",
                            fontSize: "1.1em",
                            width: "70%",
                            border: "none",
                            borderRadius: "15px",
                            backgroundColor: "#FF9D00",
                            height: "48px",
                            position: "relative",
                            bottom: "0px",
                          }}
                        >
                          Chiudi Dettagli
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <Collapse in={open == cammino.id}>
                  <div id="example-collapse-text">
                    <div style={{ display: "flex" }}>
                      {prenotazioni?.length > 0 &&
                        prenotazioni?.map((cammino, index) => (
                          <div
                            className="div-cammini"
                            style={{
                              position: "relative",
                              height: "180px",
                              padding: "5px",
                              marginLeft: "1%",
                              width: "24%",
                              display: "inline-block",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 3px 6px #00000029",
                              borderRadius: "20px",
                              marginTop: "20px",
                            }}
                            key={cammino.id}
                          >
                            <div
                              style={{
                                paddingLeft: "10",
                                paddingBottom: "0",
                                display: "inline-block",
                                position: "relative",
                                bottom: "20px",
                                left: "5px",
                              }}
                            >
                              <p
                                style={{
                                  paddingTop: "37px",
                                  color: "#B1B1B1",
                                  fontWeight: "300",
                                  fontSize: "1em",
                                  marginBottom: "3px",
                                }}
                              >
                                Giorno {index + 1}
                              </p>
                              <p
                                style={{
                                  color: "#2F2F2F",
                                  fontWeight: "600",
                                  fontSize: "1em",
                                  marginBottom: "3px",
                                }}
                              >
                                {cammino.nome_tappa_inizio} -{" "}
                                {cammino.nome_tappa_fine}
                              </p>
                              <p
                                style={{
                                  color: "#2F2F2F",
                                  fontWeight: "300",
                                  fontSize: "1em",
                                  marginBottom: "3px",
                                  fontStyle: "italic",
                                }}
                              >
                                {cammino_associato}
                              </p>
                              <div>
                                <button
                                  onClick={async () => {
                                    let giorno = `Giorno ${index + 1}`;
                                    await setGiorno(giorno);
                                    await setIdcammino(cammino.id);
                                    await setBagagli(cammino.quantita_bagagli);
                                    await setNomeTappaInizio(
                                      cammino.nome_tappa_inizio
                                    );
                                    await setNomeTappaFine(
                                      cammino.nome_tappa_fine
                                    );
                                    await setDataPrenotata(
                                      cammino.data_prenotazione
                                    );
                                    await setIdAlloggioInizio(
                                      cammino.id_alloggio_inizio
                                    );
                                    await setIdAlloggioFine(
                                      cammino.id_alloggio_fine
                                    );
                                    handleShowInfo();
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                    border: "none",
                                    paddingLeft: "0",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "30px",
                                      marginRight: "5px",
                                    }}
                                    src="/infoTable.svg"
                                  />
                                </button>
                                <button
                                  onClick={async () => {
                                    let giorno = `Giorno ${index + 1}`;
                                    await setGiorno(giorno);
                                    await setIdcammino(cammino.id);
                                    await setBagagli(cammino.quantita_bagagli);
                                    await setNomeTappaInizio(
                                      cammino.nome_tappa_inizio
                                    );
                                    await setNomeTappaFine(
                                      cammino.nome_tappa_fine
                                    );
                                    await setDataPrenotata(
                                      cammino.data_prenotazione
                                    );
                                    await setIdAlloggioInizio(
                                      cammino.id_alloggio_inizio
                                    );
                                    await setIdAlloggioFine(
                                      cammino.id_alloggio_fine
                                    );
                                    handleShowUpdate();
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    boxShadow: "none",
                                    paddingLeft: "0",
                                  }}
                                >
                                  <img
                                    style={{ width: "30px" }}
                                    src="/updateTable.svg"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                      <div
                        className="div-cammini"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          backgroundColor: "#B1B1B1",
                          color: "#fff",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                          padding: "5px",
                          marginLeft: "1%",
                          width: "24%",
                          height: "180px",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: "20px",
                          marginTop: "20px",
                        }}
                        key={cammino.id}
                      >
                        <div
                          onClick={async () => {
                            let strutture = await getStrutture(token);
                            await setStrutture(strutture);

                            let tappe = await getTappeAll(token);
                            await setTappeAll(tappe);

                            await setShowAddPrenotazione(true);
                          }}
                        >
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "1.5em",
                              fontWeight: "100",
                              marginBottom: "5px",
                              lineHeight: "1em",
                            }}
                          >
                            +
                          </p>
                          <p style={{ fontWeight: "bold" }}>
                            Aggiungi prenotazione
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>

                <td className="btn-pren" style={{ width: "300px" }}></td>
              </div>
            ))}
          </div>
        )}

        {loader && (
          <div style={{ textAlign: "center", paddingTop: "200px" }}>
            <img src="/loader.gif" style={{ borderRadius: "100%" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tappe;
