import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../Components/CheckoutForm";
import useToken from "../Components/useToken";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SearchForm from "../Components/SearchForm";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Offcanvas } from "react-bootstrap";

async function getCammini(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/cammini`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function insertRegione(token, title, id_nazione) {
  let result = await axios.post(
    `${process.env.REACT_APP_API_KEY}/admin/regione`,
    {
      title: title,
      id_nazione: id_nazione,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    }
  );
  return result.data;
}

async function insertNazione(token, title) {
  let result = await axios.post(
    `${process.env.REACT_APP_API_KEY}/admin/nazione`,
    {
      title: title,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    }
  );
  return result.data;
}

async function getNazioni(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/nazione`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function getNazione(token, id) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/nazione/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());
  return result;
}

async function getRegioni(token, id) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/regione?id_nazione=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());
  return result;
}

async function getTag(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/tag`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function getSingleTag(token, id) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/tag?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());
  return result;
}

async function deletecammino(token, id_cammino, foto) {
  const formData = new FormData();
  formData.append(
    "body",
    JSON.stringify({
      attivo: 1,
      immagine_profilo: foto,
    })
  );
  let result = await axios.put(
    `${process.env.REACT_APP_API_KEY}/admin/cammini/${id_cammino}`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

async function checkCampi(
  nome,
  descrizione,
  file,
  luogo,
  upload_preview,
  foto
) {
  if (
    file &&
    file.type != "image/png" &&
    file &&
    file.type != "image/jpeg" &&
    file &&
    file.type != "image/webp"
  ) {
    return "Formato Immagine non supportato.";
  }
  if (file && file.size > 10000000) {
    return "Immagine troppo pesante. Inserire immagine più leggera.";
  }

  if (upload_preview == "/immagine_profilo_default.svg" && !foto) {
    return "Errore. Inserire Immagine";
  }

  if (!nome || nome == "") {
    return "Errore. Inserire nome";
  }

  if (!descrizione || descrizione == "") {
    return "Errore. Inserire descrizione";
  }
  if (!luogo || luogo == "") {
    return "Errore. Inserire una nazione o un luogo.";
  } else return true;
}

async function checkCampiTag(nome, luogo, file, upload_preview) {
  if (
    file &&
    file.type != "image/png" &&
    file &&
    file.type != "image/jpeg" &&
    file &&
    file.type != "image/webp"
  ) {
    return "Formato Immagine non supportato.";
  }
  if (file && file.size > 10000000) {
    return "Immagine troppo pesante. Inserire immagine più leggera.";
  }

  if (upload_preview == "/immagine_profilo_default.svg") {
    return "Errore. Inserire Immagine";
  }

  if (!nome || nome == "") {
    return "Errore. Inserire nome";
  }
  if (!luogo || luogo == "") {
    return "Errore. Inserire una nazione o un luogo.";
  } else return true;
}

async function insertcammino(
  token,
  id_cammino,
  nome,
  descrizione,
  file,
  prezzo_min,
  prezzo_max,
  link,
  luogo,
  numero_telefono,
  email,
  costo_pre_prenotazione,
  id_tappa,
  attivo,
  minimo_giorni,
  id_tag
) {
  const formData = new FormData();
  descrizione.replace(/\n\r?/g, "<br />");
  formData.append("profileImg", file);
  formData.append(
    "body",
    JSON.stringify({
      nome: nome,
      descrizione: descrizione,
      nazione: luogo,
      attivo: attivo,
      minimo_giorni: minimo_giorni,
      id_tag: id_tag,
    })
  );
  let result = await axios.post(
    `${process.env.REACT_APP_API_KEY}/admin/cammini`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    }
  );
  return result.data;
}

async function inserttag(
  token,
  insert_nome,
  insert_luogo,
  insert_file,
  id_cammino,
  id_nazione
) {
  let id = id_cammino;
  if (id == 0 || id == undefined) {
    id = null;
  }
  const formData = new FormData();
  formData.append("profileImg", insert_file);
  formData.append(
    "body",
    JSON.stringify({
      nome: insert_nome,
      luogo: insert_luogo,
      id_cammino: id,
      id_nazione: id_nazione,
    })
  );
  let result = await axios.post(
    `${process.env.REACT_APP_API_KEY}/admin/tag`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    }
  );
  return result.data;
}

async function updatecammino(
  token,
  id_cammino,
  nome,
  file2,
  descrizione,
  prezzo_min,
  prezzo_max,
  link,
  luogo,
  numero_telefono,
  email,
  costo_pre_prenotazione,
  tappa,
  foto,
  attivo,
  minimo_giorni,
  tag
) {
  const formData = new FormData();
  descrizione.replace(/\n\r?/g, "<br />");
  if (file2) {
    formData.append("profileImg", file2);
    formData.append(
      "body",
      JSON.stringify({
        nome: nome,
        descrizione: descrizione,
        nazione: luogo,
        attivo: attivo,
        minimo_giorni: minimo_giorni,
        id_tag: tag,
      })
    );
  } else {
    formData.append(
      "body",
      JSON.stringify({
        nome: nome,
        descrizione: descrizione,
        nazione: luogo,
        immagine_profilo: foto,
        attivo: attivo,
        minimo_giorni: minimo_giorni,
        id_tag: tag,
      })
    );
  }

  let result = await axios.put(
    `${process.env.REACT_APP_API_KEY}/admin/cammini/${id_cammino}`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
}

const Itinerari = () => {
  const search = (keyword) => {
    let alloggi = alloggiBackup.filter((el) =>
      el.nome?.toUpperCase().includes(keyword.toUpperCase())
    );
    if (alloggi?.length == 0) {
      alloggi = alloggiBackup.filter((el) =>
        el.nazione?.toUpperCase().includes(keyword.toUpperCase())
      );
    }

    setCammini(alloggi);
  };

  const { token, setToken } = useToken();
  const [alloggi, setCammini] = useState([]);
  const [alloggiBackup, setCamminiBackup] = useState([]);
  const [show, setShow] = useState(false);
  const [show_tag, setShowTag] = useState(false);
  const [insert_nome, setInsertNome] = useState();
  const [insert_luogo, setInsertLuogo] = useState();
  const [insert_file, setInsertFile] = useState();
  const [input_visibility, setInputVisibility] = useState(false);
  const [input_visibility2, setInputVisibility2] = useState(false);
  const [selected_nazione, setSelectedNazione] = useState();

  const [nuova_nazione_input, setNazioneInput] = useState();
  const [nuova_regione_input, setRegioneInput] = useState();

  const [nazione_selezionata, setNazioneSelezionata] = useState();
  const [selected_regione, setSelectedRegione] = useState();

  const [regioni, setRegioni] = useState([]);

  const [id_tag_cammino, setIdTagCammino] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [modalError, setModalError] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [upload_preview, setUploadPreview] = useState(
    "/immagine_profilo_default.svg"
  );
  const [upload_preview_tag, setUploadPreviewTag] = useState(
    "/immagine_profilo_default.svg"
  );
  const [attivo, setAttivo] = useState(0);
  const [loader, setLoader] = useState(false);

  const handleShowUpdate = () => setShowUpdate(true);
  const handleCloseUpdate = async () => {
    await setSelectedtag();
    setShowUpdate(false);
    await setNome("");
    await setDescrizione("");
    await setFoto("");
    await setLuogo("");
    await setMinimoGiorni(0);
    await setAttivo(0);
    await setIdcammino();
    await setUploadPreview("/immagine_profilo_default.svg");
  };

  const handleCloseTag = async () => {
    setShowTag(false);
    setUploadPreviewTag("/immagine_profilo_default.svg");
    let tag = await getTag(token);
    await setTag(tag);
    await setInsertFile();
    await setInsertLuogo();
    await setInsertNome();
    await setSelectedtag();
  };

  const handleShowInfo = () => setShowInfo(true);
  const handleCloseInfo = async () => {
    await setSelectedtag();
    setShowInfo(false);
    await setNome("");
    await setMinimoGiorni(0);
    await setAttivo(0);
    await setDescrizione("");
    await setFoto("");
    await setLuogo("");
    await setIdcammino("");
    await setUploadPreview("/immagine_profilo_default.svg");
  };
  const handleClose = () => {
    setShow(false);
    setSelectedtag();
    setModalError("");
    setTappe([]);
    setNome("");
    setDescrizione("");
    setFoto("");
    setPrezzoMin(0);
    setPrezzoMax(0);
    setLink("");
    setLuogo("");
    setNumeroTelefono(0);
    setEmail("");
    setTappa(0);
    setPrePrenotazione(0);
    setUploadPreview("/immagine_profilo_default.svg");
  };

  const handleShow = () => setShow(true);
  const [tappe, setTappe] = useState([]);
  const [nome_tag, setNomeTag] = useState();

  const [nome, setNome] = useState("");
  const [id_cammino, setIdcammino] = useState(0);
  const [descrizione, setDescrizione] = useState("");
  const [foto, setFoto] = useState("");
  const [fotoTag, setFotoTag] = useState("");

  const [file, setFile] = useState();
  const [prezzo_min, setPrezzoMin] = useState(0);
  const [minimo_giorni, setMinimoGiorni] = useState(0);

  const [nazione, setNazione] = useState([]);
  const [tag, setTag] = useState();
  const [selected_tag, setSelectedtag] = useState();

  const [prezzo_max, setPrezzoMax] = useState(0);
  const [link, setLink] = useState("");
  const [luogo, setLuogo] = useState("");
  const [numero_telefono, setNumeroTelefono] = useState(0);
  const [email, setEmail] = useState("");
  const [tappa, setTappa] = useState(0);
  const [costo_pre_prenotazione, setPrePrenotazione] = useState(0);

  const get_alloggi = useEffect(async () => {
    let result = await getCammini(token);
    let tag_ = await getTag(token);
    let nazione_ = await getNazioni(token);
    setNazione(nazione_);

    setTag(tag_);
    setCammini(result);
    setCamminiBackup(result);
  }, []);

  async function getTappe(token, id) {
    let result = await fetch(
      `${process.env.REACT_APP_API_KEY}/admin/tappe?id_cammino=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((data) => data.json());

    let tappe = [];

    result.sort((a, b) =>
      a.Ordine > b.Ordine ? 1 : b.Ordine > a.Ordine ? -1 : 0
    );

    for (let i = 0; i < result.length; i++) {
      tappe.push(result[i].nome);
    }

    return tappe;
  }

  const defaultList = ["A", "B", "C", "D", "E"];
  // React state to track order of items
  const [itemList, setItemList] = useState(defaultList);

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
  };

  return (
    <div>
      <Modal
        show={modalError != ""}
        onHide={() => {
          setModalError("");
        }}
      >
        <Modal.Body>
          <div>
            <img
              onClick={() => {
                setModalError("");
              }}
              className="btn-close-img"
              src="/closeBtn.svg"
            />
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          >
            <img
              style={{ width: "60px" }}
              className="btn-close-img1"
              src="/errorImage.svg"
            />
            <div
              style={{
                color: "#FF1400",
                fontSize: "1.4em",
                fontWeight: "500",
                marginTop: "20px",
                marginBottom: "0px",
              }}
            >
              {modalError}
              <br></br>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
      >
        <Modal.Body>
          <div>
            <img
              onClick={() => {
                setShowDelete(false);
              }}
              className="btn-close-img"
              src="/closeBtn.svg"
            />
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          >
            <img
              style={{ width: "50px" }}
              className="btn-close-img1"
              src="/deletePopup.svg"
            />
            <p
              style={{
                color: "#FF1400",
                fontSize: "1.7em",
                fontWeight: "500",
                marginTop: "20px",
                marginBottom: "0px",
              }}
            >
              Disattiva Itinerario
            </p>
            <p
              style={{
                color: "#2F2F2F",
                fontSize: "1.1em",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              Vuoi disattivare{" "}
              <span style={{ fontWeight: "700" }}>
                {nome}?<br></br>
              </span>
            </p>
            <button
              onClick={async () => {
                await deletecammino(token, id_cammino, foto);
                let result_post_delete = await getCammini(token);
                setShowDelete(false);
                setCammini(result_post_delete);
              }}
              className="buttonDelete"
              style={{
                marginTop: "15px",
                fontWeight: "600",
                height: "55px",
                backgroundColor: "transparent",
                border: "1px solid #FF1400",
                color: "#FF1400",
                fontSize: "1em",
                width: "40%",
                borderRadius: "20px",
              }}
            >
              Disattiva
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showUpdate}
        onHide={handleCloseUpdate}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseUpdate}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Itinerari - Modifica
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <h1
              style={{
                fontSize: "1.3em",
                fontWeight: "700",
                marginBottom: "0",
                marginTop: "30px",
              }}
            >
              Modifica
            </h1>
            <h2 style={{ fontSize: "1.6em", fontWeight: "300" }}>{nome}</h2>
            <div>
              <div>
                <div>
                  <div style={{ marginTop: "40px" }} className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      style={{ marginBottom: "20px" }}
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Cambia Immagine
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                          display: "inline-block",
                          backgroundImage: `url(${
                            foto != "" &&
                            foto != null &&
                            upload_preview == "/immagine_profilo_default.svg"
                              ? foto
                              : upload_preview
                          })`,
                          backgroundSize: "cover",
                          borderRadius: "100%",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <label
                        htmlFor={`upload-photo-Immagine`}
                        className="file"
                        style={{
                          width: "70%",
                          position: "relative",
                          left: "20px",
                          bottom: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>
                          <h6
                            style={{
                              display: "inline-block",
                              marginBottom: "0px",
                            }}
                          >
                            Cambia Immagine
                          </h6>
                        </span>
                        <input
                          type="file"
                          style={{
                            opacity: "0",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          id={`upload-photo-Immagine`}
                          onChange={async (e) => {
                            if (e.target.files[0]) {
                              await setUploadPreview(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }
                            await setFile(e.target.files[0]);
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Attivo
                    </label>
                    <select
                      defaultValue={attivo}
                      onChange={async (e) => {
                        await setAttivo(e.target.value);
                      }}
                      type="select"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value={0}>Attivo</option>
                      <option value={1}>Non Attivo</option>
                    </select>
                  </div>

                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Nome itinerario
                    </label>
                    <input
                      defaultValue={nome}
                      onChange={async (e) => {
                        await setNome(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>
                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Descrizione itinerario
                    </label>
                    <textarea
                      style={{ whiteSpace: "pre-line" }}
                      defaultValue={descrizione}
                      onChange={async (e) => {
                        await setDescrizione(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>

                  <label
                    data-error="wrong"
                    data-success="right"
                    for="orangeForm-name"
                    style={{ position: "relative", marginTop: "30px" }}
                  >
                    Ordine Tappe{" "}
                    <img
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "2px",
                        width: "30px",
                      }}
                      src="/drag.png"
                    />
                  </label>

                  <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                      {(provided) => (
                        <div
                          className="list-container"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {itemList.map((item, index) => (
                            <Draggable
                              key={item}
                              draggableId={item}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="item-container"
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  {item}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "30px",
                      marginTop: "30px",
                    }}
                  >
                    <div>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Cammino
                      </label>

                      {tag?.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "35px",
                            }}
                          >
                            <input
                              onChange={async (e) => {
                                await setSelectedtag(item);
                                await setIdTagCammino(item.id);
                                await setInputVisibility(true);
                                let idNazione = tag.find(
                                  (x) => x.id == selected_tag.id_tag
                                );
                                idNazione = idNazione.id_nazione
                                  ? idNazione.id_nazione
                                  : null;
                                if (idNazione == null) {
                                  alert(
                                    "Errore nell'ottenimento della nazione"
                                  );
                                  return;
                                }
                                let regione_ = await getRegioni(
                                  token,
                                  item.id_nazione
                                );
                                setRegioni(regione_);
                                let nazione_selected = await getNazione(
                                  token,
                                  item.id_nazione
                                );
                                await setSelectedNazione(
                                  nazione_selected[0].title
                                );
                              }}
                              checked={item.id == id_tag_cammino}
                              style={{
                                width: "18px",
                                marginRight: "10px",
                                position: "relative",
                                top: "4px",
                              }}
                              type="radio"
                              id={item.nome}
                              name={item.nome}
                              value={item.id}
                            />
                            <label for="vehicle1">{item.nome}</label>
                          </div>
                        );
                      })}

                      <button
                        onClick={async () => {
                          setShowTag(true);
                          setUploadPreviewTag("/immagine_profilo_default.svg");

                          setInputVisibility(false);
                        }}
                        style={{
                          marginBottom: "30px",
                          background: "none",
                          border: "none",
                          fontSize: "0.9em",
                          color: "#707070",
                          marginTop: "0px",
                        }}
                      >
                        + Aggiungi Cammino
                      </button>
                    </div>
                  </div>

                  {id_tag_cammino != null && (
                    <div className="  mb-2">
                      <i className="fas fa-user prefix grey-text"></i>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Regione
                      </label>
                      <p
                        style={{
                          marginTop: "10px",
                          background: "none",
                          border: "none",
                          fontSize: "0.9em",
                          color: "#707070",
                        }}
                      >
                        {selected_nazione}
                      </p>
                      <div
                        style={{
                          marginTop: "10px",
                          paddingLeft: "7%",
                          marginBottom: "16px",
                        }}
                      >
                        {
                          //map regioni and return input type checkbox with label and value id regione
                          regioni.length > 0 &&
                            regioni?.map((item, index) => {
                              return (
                                item.title && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      height: "35px",
                                    }}
                                  >
                                    <input
                                      onChange={async (e) => {
                                        //if is the first time that i click on the regione i set the regione selected
                                        if (luogo == "") {
                                          await setLuogo(item.title);
                                        }

                                        //add ", item.title" to selected regione
                                        if (e.target.checked) {
                                          await setLuogo(
                                            luogo + " " + item.title
                                          );
                                        } else {
                                          //remove " item.title" to selected regione
                                          await setLuogo(
                                            luogo.replace(" " + item.title, "")
                                          );
                                        }
                                      }}
                                      checked={luogo.includes(item.title)}
                                      style={{
                                        width: "18px",
                                        marginRight: "10px",
                                        position: "relative",
                                        top: "4px",
                                      }}
                                      type="checkbox"
                                      id={item.nome}
                                      name={item.nome}
                                      value={item.id}
                                    />
                                    <label for="vehicle1">{item.title}</label>
                                  </div>
                                )
                              );
                            })
                        }

                        {input_visibility2 && (
                          <div style={{ marginTop: "12px" }}>
                            <label
                              data-error="wrong"
                              data-success="right"
                              for="orangeForm-name"
                              style={{ marginBottom: "10px" }}
                            >
                              Nuova regione
                            </label>
                            <div style={{ display: "flex" }}>
                              <input
                                style={{ width: "80%" }}
                                onChange={async (e) => {
                                  await setRegioneInput(e.target.value);
                                }}
                                type="text"
                                id="orangeForm-name"
                                className="form-control validate"
                              />
                              <button
                                onClick={async () => {
                                  let idNazione = tag.find(
                                    (x) => x.id == selected_tag.id_tag
                                  );
                                  idNazione = idNazione.id_nazione
                                    ? idNazione.id_nazione
                                    : null;
                                  if (idNazione == null) {
                                    alert(
                                      "Errore nell'ottenimento della nazione"
                                    );
                                    return;
                                  }
                                  console.log("idNazione:", idNazione);
                                  let insert = await insertRegione(
                                    token,
                                    nuova_regione_input,
                                    idNazione
                                  );
                                  if (insert.status == "ok") {
                                    //push regione_input to regioni
                                    setInputVisibility2(false);
                                    let regione_ = await getRegioni(
                                      token,
                                      idNazione
                                    );
                                    setRegioni(regione_);
                                  } else {
                                    alert("errore inserimento regione");
                                  }
                                }}
                                style={{
                                  position: "relative",
                                  float: "right",
                                  border: "none",
                                  boxShadow: "0px 3px 6px #00000029",
                                  borderRadius: "20px",
                                  height: "45px",
                                  marginLeft: "2%",
                                  fontSize: "0.9em",
                                }}
                              >
                                Aggiungi
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <button
                    onClick={async () => {
                      await setInputVisibility2(!input_visibility2);
                    }}
                    style={{
                      marginBottom: "30px",
                      background: "none",
                      border: "none",
                      fontSize: "0.9em",
                      color: "#707070",
                      marginTop: "0px",
                      paddingLeft: "7%",
                      position: "relative",
                      bottom: "15px",
                    }}
                  >
                    + Aggiungi regione
                  </button>

                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Minimo Giorni
                    </label>
                    <input
                      defaultValue={minimo_giorni}
                      onChange={async (e) => {
                        await setMinimoGiorni(e.target.value);
                      }}
                      type="number"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>
                </div>
                <div className="">
                  {loader && (
                    <div style={{ textAlign: "center", paddingTop: "20px" }}>
                      <img src="/loader.gif" style={{ borderRadius: "100%" }} />
                    </div>
                  )}

                  {!loader && (
                    <button
                      onClick={async () => {
                        setLoader(true);
                        let check_campi = await checkCampi(
                          nome,
                          descrizione,
                          file,
                          luogo,
                          upload_preview,
                          foto
                        );
                        if (check_campi == true) {
                          await setModalError("");
                          let resultInsert = await updatecammino(
                            token,
                            id_cammino,
                            nome,
                            file,
                            descrizione,
                            prezzo_min,
                            prezzo_max,
                            link,
                            luogo,
                            numero_telefono,
                            email,
                            costo_pre_prenotazione,
                            tappa,
                            foto,
                            attivo,
                            minimo_giorni,
                            selected_tag.id
                          );
                          if (resultInsert.status == "ok") {
                            let result_tappe = await fetch(
                              `${process.env.REACT_APP_API_KEY}/admin/tappe?id_cammino=${id_cammino}`,
                              {
                                method: "GET",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${token}`,
                                  "Access-Control-Allow-Origin": "*",
                                },
                              }
                            ).then((data) => data.json());

                            for (let i = 0; i < result_tappe.length; i++) {
                              for (let j = 0; j < itemList.length; j++) {
                                if (itemList[j] == result_tappe[i].nome) {
                                  const formData = new FormData();
                                  formData.append(
                                    "body",
                                    JSON.stringify({
                                      Ordine: j + 1,
                                      foto_tappa: result_tappe[i].foto_tappa,
                                    })
                                  );
                                  let result = await axios.put(
                                    `${process.env.REACT_APP_API_KEY}/admin/tappe/${result_tappe[i].id}`,
                                    formData,
                                    {
                                      headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${token}`,
                                      },
                                    }
                                  );
                                }
                              }
                            }

                            handleCloseUpdate();
                            await setFile();
                            await setModalError("");
                            let result_post_update = await getCammini(token);
                            setCammini(result_post_update);
                            setCamminiBackup(result_post_update);
                          }
                          setLoader(false);
                        } else {
                          setLoader(false);
                          await setModalError(check_campi);
                        }
                      }}
                      className="btn btn-deep-orange close btn-update"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Salva
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={show_tag}
        onHide={handleCloseTag}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseTag}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Cammino - Aggiungi
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <div>
              <div>
                <div>
                  <div style={{ marginTop: "40px" }} className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      style={{ marginBottom: "20px" }}
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Cambia Immagine
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                          display: "inline-block",
                          backgroundImage: `url(${
                            fotoTag != "" &&
                            fotoTag != null &&
                            upload_preview_tag ==
                              "/immagine_profilo_default.svg"
                              ? foto
                              : upload_preview_tag
                          })`,
                          backgroundSize: "cover",
                          borderRadius: "100%",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <label
                        htmlFor={`upload-photo-Immagine`}
                        className="file"
                        style={{
                          width: "70%",
                          position: "relative",
                          left: "20px",
                          bottom: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>
                          <h6
                            style={{
                              display: "inline-block",
                              marginBottom: "0px",
                            }}
                          >
                            Cambia Immagine
                          </h6>
                        </span>
                        <input
                          type="file"
                          style={{
                            opacity: "0",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          id={`upload-photo-Immagine`}
                          onChange={async (e) => {
                            if (e.target.files[0]) {
                              await setUploadPreviewTag(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }
                            await setInsertFile(e.target.files[0]);
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Nome
                    </label>
                    <input
                      defaultValue={insert_nome}
                      onChange={async (e) => {
                        await setInsertNome(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>

                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                      style={{ marginBottom: "10px" }}
                    >
                      Nazione
                    </label>
                    {nazione.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "35px",
                          }}
                        >
                          <input
                            onChange={async (e) => {
                              if (e.target.checked) {
                                await setNazioneSelezionata(item);
                              }
                            }}
                            type="radio"
                            style={{
                              width: "25px",
                              position: "relative",
                              top: "5px",
                              marginRight: "7px",
                            }}
                            name="site_name"
                            value={item.id}
                          />
                          <label
                            style={{
                              fontSize: "0.9em",
                              color: "#707070",
                              fontWeight: "300",
                            }}
                          >
                            {item.title}
                          </label>
                        </div>
                      );
                    })}

                    {input_visibility && (
                      <div style={{ marginTop: "12px" }}>
                        <label
                          data-error="wrong"
                          data-success="right"
                          for="orangeForm-name"
                          style={{ marginBottom: "10px" }}
                        >
                          Nuova nazione
                        </label>
                        <div style={{ display: "flex" }}>
                          <input
                            style={{ width: "80%" }}
                            onChange={async (e) => {
                              await setNazioneInput(e.target.value);
                            }}
                            type="text"
                            id="orangeForm-name"
                            className="form-control validate"
                          />
                          <button
                            onClick={async () => {
                              let insert = await insertNazione(
                                token,
                                nuova_nazione_input
                              );
                              if (insert.status == "ok") {
                                let result = await getNazioni(token);
                                setNazione(result);
                                setInputVisibility(false);
                              }
                            }}
                            style={{
                              position: "relative",
                              float: "right",
                              border: "none",
                              boxShadow: "0px 3px 6px #00000029",
                              borderRadius: "20px",
                              height: "45px",
                              marginLeft: "2%",
                              fontSize: "0.9em",
                            }}
                          >
                            Aggiungi
                          </button>
                        </div>
                      </div>
                    )}

                    <button
                      onClick={async () => {
                        await setInputVisibility(!input_visibility);
                      }}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "0.9em",
                        color: "#707070",
                        marginTop: "0px",
                      }}
                    >
                      + Aggiungi Nazione
                    </button>
                  </div>
                </div>
                <div className="">
                  {loader && (
                    <div style={{ textAlign: "center", paddingTop: "20px" }}>
                      <img src="/loader.gif" style={{ borderRadius: "100%" }} />
                    </div>
                  )}
                  {!loader && (
                    <button
                      onClick={async () => {
                        setLoader(true);
                        let check_campi = await checkCampiTag(
                          insert_nome,
                          nazione_selezionata.title,
                          insert_file,
                          upload_preview_tag
                        );
                        if (check_campi == true) {
                          await setModalError("");
                          let resultInsert = await inserttag(
                            token,
                            insert_nome,
                            nazione_selezionata.title,
                            insert_file,
                            id_cammino,
                            nazione_selezionata.id
                          );
                          if (resultInsert.status == "ok") {
                            handleCloseTag();
                            await setModalError("");
                          }
                          setLoader(false);
                        } else {
                          setLoader(false);
                          await setModalError(check_campi);
                        }
                      }}
                      className="btn btn-deep-orange close btn-update"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Aggiungi
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header>
          <img
            onClick={handleClose}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Itinerari - Aggiungi
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <div>
              <div>
                <div>
                  <h1
                    style={{
                      fontSize: "1.3em",
                      fontWeight: "700",
                      marginBottom: "40px",
                      marginTop: "30px",
                    }}
                  >
                    Aggiungi Itinerario
                  </h1>

                  <div style={{ marginTop: "40px" }} className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      style={{ marginBottom: "20px" }}
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Inserisci Immagine
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                          display: "inline-block",
                          backgroundImage: `url(${
                            foto != "" &&
                            foto != null &&
                            upload_preview == "/immagine_profilo_default.svg"
                              ? foto
                              : upload_preview
                          })`,
                          backgroundSize: "cover",
                          borderRadius: "100%",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <label
                        htmlFor={`upload-photo-Immagine`}
                        className="file"
                        style={{
                          width: "70%",
                          position: "relative",
                          left: "20px",
                          bottom: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>
                          <h6
                            style={{
                              display: "inline-block",
                              marginBottom: "0px",
                            }}
                          >
                            Inserisci Immagine
                          </h6>
                        </span>
                        <input
                          type="file"
                          style={{
                            opacity: "0",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          id={`upload-photo-Immagine`}
                          onChange={async (e) => {
                            if (e.target.files[0]) {
                              await setUploadPreview(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }
                            await setFile(e.target.files[0]);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Attivo
                    </label>
                    <select
                      defaultValue={attivo}
                      onChange={async (e) => {
                        await setAttivo(e.target.value);
                      }}
                      type="select"
                      id="orangeForm-name"
                      className="form-control validate"
                    >
                      <option value={0}>Attivo</option>
                      <option value={1}>Non Attivo</option>
                    </select>
                  </div>
                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Nome itinerario
                    </label>
                    <input
                      onChange={async (e) => {
                        await setNome(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>
                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Descrizione itinerario
                    </label>
                    <textarea
                      onChange={async (e) => {
                        await setDescrizione(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "30px",
                      marginTop: "30px",
                    }}
                  >
                    <div>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Cammino
                      </label>

                      {tag?.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "35px",
                            }}
                          >
                            <input
                              onChange={async (e) => {
                                await setSelectedtag(item);
                                await setInputVisibility(true);
                                let idNazione = tag.find(
                                  (x) => x.id == selected_tag.id_tag
                                );
                                idNazione = idNazione.id_nazione
                                  ? idNazione.id_nazione
                                  : null;
                                if (idNazione == null) {
                                  alert(
                                    "Errore nell'ottenimento della nazione"
                                  );
                                  return;
                                }

                                let regione_ = await getRegioni(
                                  token,
                                  idNazione
                                );
                                setRegioni(regione_);
                                let nazione_selected = await getNazione(
                                  token,
                                  item.id_nazione
                                );
                                await setSelectedNazione(
                                  nazione_selected[0].title
                                );
                              }}
                              checked={
                                selected_tag?.id == item.id ? true : false
                              }
                              style={{
                                width: "18px",
                                marginRight: "10px",
                                position: "relative",
                                top: "4px",
                              }}
                              type="radio"
                              id={item.nome}
                              name={item.nome}
                              value={item.id}
                            />
                            <label for="vehicle1">{item.nome}</label>
                          </div>
                        );
                      })}
                      <button
                        onClick={async () => {
                          setShowTag(true);
                          setUploadPreviewTag("/immagine_profilo_default.svg");

                          setInputVisibility(false);
                        }}
                        style={{
                          marginBottom: "30px",
                          background: "none",
                          border: "none",
                          fontSize: "0.9em",
                          color: "#707070",
                          marginTop: "0px",
                        }}
                      >
                        + Aggiungi Cammino
                      </button>
                    </div>
                  </div>

                  {input_visibility && (
                    <div className="  mb-2">
                      <i className="fas fa-user prefix grey-text"></i>
                      <label
                        data-error="wrong"
                        data-success="right"
                        for="orangeForm-name"
                      >
                        Regione
                      </label>
                      <p
                        style={{
                          marginTop: "10px",
                          background: "none",
                          border: "none",
                          fontSize: "0.9em",
                          color: "#707070",
                        }}
                      >
                        {selected_nazione}
                      </p>
                      <div
                        style={{
                          marginTop: "10px",
                          paddingLeft: "7%",
                          marginBottom: "16px",
                        }}
                      >
                        {
                          //map regioni and return input type checkbox with label and value id regione
                          regioni.length > 0 &&
                            regioni?.map((item, index) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "35px",
                                  }}
                                >
                                  <input
                                    onChange={async (e) => {
                                      //if is the first time that i click on the regione i set the regione selected
                                      if (selected_regione == "") {
                                        await setSelectedRegione(item.title);
                                      }

                                      //add ", item.title" to selected regione
                                      if (e.target.checked) {
                                        await setSelectedRegione(
                                          selected_regione + " " + item.title
                                        );
                                      } else {
                                        //remove " item.title" to selected regione
                                        await setSelectedRegione(
                                          selected_regione.replace(
                                            " " + item.title,
                                            ""
                                          )
                                        );
                                      }
                                    }}
                                    style={{
                                      width: "18px",
                                      marginRight: "10px",
                                      position: "relative",
                                      top: "4px",
                                    }}
                                    type="checkbox"
                                    id={item.nome}
                                    name={item.nome}
                                    value={item.id}
                                  />
                                  <label for="vehicle1">{item.title}</label>
                                </div>
                              );
                            })
                        }

                        {input_visibility2 && (
                          <div style={{ marginTop: "12px" }}>
                            <label
                              data-error="wrong"
                              data-success="right"
                              for="orangeForm-name"
                              style={{ marginBottom: "10px" }}
                            >
                              Nuova regione
                            </label>
                            <div style={{ display: "flex" }}>
                              <input
                                style={{ width: "80%" }}
                                onChange={async (e) => {
                                  await setRegioneInput(e.target.value);
                                }}
                                type="text"
                                id="orangeForm-name"
                                className="form-control validate"
                              />
                              <button
                                onClick={async () => {
                                  console.log(tag);
                                  let insert = await insertRegione(
                                    token,
                                    nuova_regione_input,
                                    selected_tag.id_nazione
                                  );
                                  if (insert.status == "ok") {
                                    setInputVisibility2(false);
                                    let idNazione = tag.find(
                                      (x) => x.id == selected_tag.id_tag
                                    );
                                    idNazione = idNazione.id_nazione
                                      ? idNazione.id_nazione
                                      : null;
                                    if (idNazione == null) {
                                      alert(
                                        "Errore nell'ottenimento della nazione"
                                      );
                                      return;
                                    }
                                    let regione_ = await getRegioni(
                                      token,
                                      selected_tag.id_nazione
                                    );
                                    setRegioni(regione_);
                                  } else {
                                    alert("errore inserimento regione");
                                  }
                                }}
                                style={{
                                  position: "relative",
                                  float: "right",
                                  border: "none",
                                  boxShadow: "0px 3px 6px #00000029",
                                  borderRadius: "20px",
                                  height: "45px",
                                  marginLeft: "2%",
                                  fontSize: "0.9em",
                                }}
                              >
                                Aggiungi
                              </button>
                            </div>
                          </div>
                        )}

                        <button
                          onClick={async () => {
                            await setInputVisibility2(!input_visibility2);
                          }}
                          style={{
                            marginBottom: "30px",
                            background: "none",
                            border: "none",
                            fontSize: "0.9em",
                            color: "#707070",
                            marginTop: "0px",
                          }}
                        >
                          + Aggiungi regione
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Minimo Giorni
                    </label>
                    <input
                      onChange={async (e) => {
                        await setMinimoGiorni(e.target.value);
                      }}
                      type="number"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>
                </div>
                <div className="">
                  {loader && (
                    <div style={{ textAlign: "center", paddingTop: "20px" }}>
                      <img src="/loader.gif" style={{ borderRadius: "100%" }} />
                    </div>
                  )}
                  {!loader && (
                    <button
                      onClick={async () => {
                        setLoader(true);
                        let check_campi = await checkCampi(
                          nome,
                          descrizione,
                          file,
                          selected_regione,
                          upload_preview,
                          foto
                        );
                        if (check_campi == true) {
                          await setModalError("");
                          let resultInsert = await insertcammino(
                            token,
                            id_cammino,
                            nome,
                            descrizione,
                            file,
                            prezzo_min,
                            prezzo_max,
                            link,
                            selected_regione,
                            numero_telefono,
                            email,
                            costo_pre_prenotazione,
                            tappa,
                            attivo,
                            minimo_giorni,
                            selected_tag.id
                          );
                          if (resultInsert.status == "ok") {
                            setSelectedRegione("");
                            handleClose();
                            await setModalError("");
                            let result_post_delete = await getCammini(token);
                            setCammini(result_post_delete);
                            setCamminiBackup(result_post_delete);
                          }
                          setLoader(false);
                          let result = await getCammini(token);
                          setCammini(result);
                          setCamminiBackup(result);
                        } else {
                          setLoader(false);
                          await setModalError(check_campi);
                        }
                      }}
                      className="btn btn-deep-orange close btn-update"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Aggiungi
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showInfo}
        onHide={handleCloseInfo}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseInfo}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Itinerari - Info
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div>
              <div>
                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <div
                    className="div-image"
                    style={{
                      backgroundImage: `url("${foto}")`,
                      display: "inline-block",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      bottom: "50px",
                      left: "30px",
                    }}
                  >
                    <img
                      onClick={() => {
                        setShowInfo(false);
                        setShowUpdate(true);
                      }}
                      style={{ marginBottom: "13px", cursor: "pointer" }}
                      src="/updateTable.svg"
                    />
                    <br></br>
                    <img
                      onClick={() => {
                        setShowDelete(true);
                        setShowInfo(false);
                      }}
                      style={{ cursor: "pointer" }}
                      src="/deleteTable.svg"
                    />
                  </div>
                  <div className=" mb-2">
                    <p className="nome">{nome}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Attivo</b>
                    <p style={{ color: "#A9DE5B" }}>
                      {attivo == 0 && "Attivo"}
                    </p>
                    <p
                      style={{
                        color: "red",
                        position: "relative",
                        bottom: "10px",
                      }}
                    >
                      {attivo == 1 && "Non Attivo"}
                    </p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Descrizione</b>
                    <p>{descrizione}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Luogo</b>
                    <p>{luogo}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Ordine Tappe</b>
                    {itemList.map(
                      (tappa) =>
                        tappa && (
                          <p
                            key={tappa.id}
                            value={tappa.id}
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            {tappa}
                          </p>
                        )
                    )}
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Minimo Giorni</b>
                    <p>{minimo_giorni}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Cammino</b>
                    <p>{nome_tag}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div></div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div
        style={{
          position: "absolute",
          top: "0",
          paddingTop: "65px",
          width: "100%",
          backgroundColor: "#F4F4F4",
          zIndex: "1000",
        }}
      >
        <h1 className="title-sezione">
          <img
            src="camminiIcon.svg"
            style={{
              position: "relative",
              bottom: "3px",
              width: "23px",
              marginRight: "12px",
            }}
          />
          Itinerari
        </h1>

        <div style={{ marginTop: "40px", width: "75%" }}>
          <SearchForm nome="Cerca" search={search} />

          <button
            variant="primary"
            onClick={async () => {
              setInputVisibility(false);
              setInputVisibility2(false);
              setSelectedRegione("");
              handleShow();
              setSelectedtag();
              let get_tappe = await getTappe();
              await setTappe(get_tappe);
            }}
            className="btn btn-default btn-rounded mb-4 aggiungi-struttura "
            data-toggle="modal"
            data-target="#modalRegisterForm"
            style={{
              position: "relative",
              float: "right",
              border: "none",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "20px",
              height: "50px",
              width: "20%",
              fontSize: "0.9em",
            }}
          >
            Aggiungi Itinerario
          </button>
        </div>
      </div>
      <div
        className="alloggi overflow-responsive Card"
        style={{
          marginTop: "190px",
          paddingBottom: "100px",
          borderRadius: "25px",
          position: "relative",
          left: "",
        }}
      >
        {alloggi.map((cammino) => (
          <div
            className="div-cammini"
            style={{
              padding: "10px",
              marginBottom: "2%",
              marginLeft: "1%",
              display: "inline-block",
              backgroundColor: "#ffffff",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "20px",
              height: "auto",
              maxHeight: "300px",
            }}
            key={cammino.id}
          >
            <div
              style={{
                height: "110px",
                backgroundImage: `url("${cammino.immagine_profilo}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                borderRadius: "20px",
              }}
            ></div>
            <div style={{ padding: "10px", paddingBottom: "0" }}>
              {cammino.attivo == 1 && (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.8em",
                    fontWeight: "700",
                    marginBottom: "0",
                  }}
                >
                  Non Attivo
                </p>
              )}
              {cammino.attivo == 0 && (
                <p
                  style={{
                    color: "#A9DE5B",
                    fontSize: "0.8em",
                    fontWeight: "700",
                    marginBottom: "0",
                  }}
                >
                  Attivo
                </p>
              )}

              <p
                style={{
                  color: "#B1B1B1",
                  fontSize: "1em",
                  fontWeight: "300",
                  marginBottom: "0",
                }}
              >
                {cammino.nazione?.substring(0, 25)}
                {cammino.nazione?.length > 25 ? <>..</> : null}
              </p>
              <p
                style={{
                  color: "#2F2F2F",
                  fontSize: "1em",
                  fontWeight: "700",
                  marginBottom: "18px",
                }}
              >
                {cammino.nome?.substring(0, 25)}
                {cammino.nome?.length > 25 ? <>..</> : null}
              </p>

              <div>
                <button
                  onClick={async () => {
                    await setNome(cammino.nome);
                    await setDescrizione(cammino.descrizione);
                    await setFoto(cammino.immagine_profilo);
                    await setLuogo(cammino.nazione);
                    await setAttivo(cammino.attivo);
                    await setMinimoGiorni(cammino.minimo_giorni);
                    await setSelectedtag(cammino);
                    let nome_tag = await getSingleTag(token, cammino.id_tag);
                    if (nome_tag[0]?.nome) {
                      await setNomeTag(nome_tag[0].nome);
                    } else {
                      await setNomeTag("Nessun Tag associato.");
                    }
                    let get_tappe = await getTappe(token, cammino.id);
                    await setItemList(get_tappe);
                    handleShowInfo();
                  }}
                  style={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    border: "none",
                    paddingLeft: "0",
                  }}
                >
                  <img style={{ width: "25px" }} src="./infoTable.svg" />
                </button>
                <button
                  onClick={async () => {
                    await setNome(cammino.nome);
                    await setDescrizione(cammino.descrizione);
                    await setFoto(cammino.immagine_profilo);
                    await setLuogo(cammino.nazione);
                    await setSelectedtag(cammino);
                    await setAttivo(cammino.attivo);
                    await setIdcammino(cammino.id);
                    let get_tappe = await getTappe(token, cammino.id);
                    await setItemList(get_tappe);
                    await setMinimoGiorni(cammino.minimo_giorni);

                    await setIdTagCammino(cammino.id_tag);
                    await setTappe(get_tappe);
                    console.log(cammino);
                    console.log(tag);
                    let idNazione = tag.find((x) => x.id == cammino.id_tag);
                    idNazione =
                      idNazione && idNazione.id_nazione
                        ? idNazione.id_nazione
                        : null;
                    if (idNazione) {
                      let regione_ = await getRegioni(token, idNazione);
                      setRegioni(regione_);
                    }
                    handleShowUpdate();
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                    paddingLeft: "0",
                  }}
                >
                  <img style={{ width: "25px" }} src="./updateTable.svg" />
                </button>
                <button
                  onClick={async () => {
                    await setAttivo(cammino.attivo);
                    await setNome(cammino.nome);
                    await setDescrizione(cammino.descrizione);
                    await setSelectedtag(cammino.id_tag);
                    await setFoto(cammino.immagine_profilo);
                    await setLuogo(cammino.nazione);
                    await setMinimoGiorni(cammino.minimo_giorni);

                    await setIdcammino(cammino.id);
                    let get_tappe = await getTappe(token, cammino.id);
                    await setItemList(get_tappe);
                    await setTappe(get_tappe);
                    setShowDelete(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                    paddingLeft: "0",
                  }}
                >
                  <img style={{ width: "25px" }} src="./deleteTable.svg" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Itinerari;
