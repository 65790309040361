import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";

class CheckoutForm extends React.Component {
  handleSubmit =  async event => {
    event.preventDefault();

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(result.error.message);
    } else {

      fetch(`${process.env.REACT_APP_API_KEY}/stripe-information`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: 'cors',
          method: "POST",
          body: JSON.stringify({
            stripeToken: result.token.id,
            amount: event.target.elements.company.value
          })
        })
        .then(function (res) { console.log(res) })
        .catch(function (res) { console.log(res) })

    }



  };

  render() {
    return (
      <div>
        <div className="product-info">
          <h3 className="product-title">Apple MacBook Pro</h3>
          <h4 className="product-price">€ 2</h4>
        </div>
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <input
          style={{display: "none"}}
            value= {200}
            id="company"
          />
          <button disabled={!this.props.stripe} className="btn-pay">
            Buy Now"@stripe/stripe-js";
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (

        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}

