import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import { Modal, Button } from "react-bootstrap";


async function loginUser(credentials) {

  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
  return result;

}

export default function Login({ setToken, setAdmin }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [modalError, setModalError] = useState("");


  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      email,
      password
    });

    if (token.status == "error") {
      setModalError(token.message);
    }
    else {
      setToken(token.token);
      setAdmin(token.admin);
      window.location.href = "/cammini";
    }
  }

  return (
    <div className="login-wrapper">

      <Modal show={modalError != ""} onHide={() => { setModalError("") }}>
        <Modal.Body>
          <div>
            <img onClick={() => { setModalError("") }} className="btn-close-img" src="/closeBtn.svg" />
          </div>
          <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "80px" }}>
            <img style={{ width: "60px" }} className="btn-close-img1" src="/errorImage.svg" />
            <div style={{ color: "#FF1400", fontSize: "1.4em", fontWeight: "500", marginTop: "20px", marginBottom: "0px" }}>{modalError}<br></br></div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="row" style={{ height: "100vh" }}>
        <div className='col-12 ' style={{ display: "table", flexWrap: "wrap", backgroundImage: `url("/img_paesaggio.png")`, backgroundPosition: "center", backgroundSize: "cover" }} >
          <div className='row' style={{ verticalAlign: "middle", display: "table-cell" }}>
            <div className='' style={{ backgroundColor: "#ffffff", width: "470px", height: "700px", position: "relative", left: "150px", borderRadius: "30px" }} >
              <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "50px" }}>
                <img src="/logo_.svg" style={{ width: "220px" }} />
              </div>
              <h1 style={{ fontSize: "1.7em", fontWeight: "700", textAlign: "center" }}>Accedi al gestionale</h1>
              <h1 style={{ fontSize: "1.3em", marginTop: "15px", marginBottom: "60px", fontWeight: "300", textAlign: "center", color: "#777777" }}>Opera, gestisci, organizza.</h1>

              <form style={{ width: "100%", paddingLeft: "70px", paddingRight: "70px" }} onSubmit={handleSubmit}>
                <label>
                  <p style={{ fontSize: "0.9em", color: "#777777", marginBottom: "3px" }}>Email</p>
                  <input style={{ fontSize: "0.9em", height: "55px", width:"100%", border: "1px solid #9D9D9D", borderRadius: "10px", color: "#9D9D9D", paddingLeft: "10px", paddingRight: "10px" }} type="email" onChange={async (e) => { await setEmail(e.target.value); }} />
                </label>
                <label>
                  <p style={{ fontSize: "0.9em", color: "#777777", marginBottom: "3px", marginTop: "20px" }}>Password</p>
                  <input style={{ fontSize: "0.9em", height: "55px",width:"100%", border: "1px solid #9D9D9D", borderRadius: "10px", color: "#9D9D9D", paddingLeft: "10px", paddingRight: "10px" }} type="password" onChange={async (e) => { await setPassword(e.target.value) }} />
                </label>
                <div>
                  <button style={{ backgroundColor: "#9B3601", color: "#ffffff", fontWeight: "500", fontSize: "1.1em", width: "100%", height: "50px", borderRadius: "10px", border: "none", marginTop: "30px" }} type="submit">Log-In</button>
                </div>
                {/*
                <p style={{ marginTop: "50px", fontSize: "0.9em", color: "#777777", marginBottom: "3px" }}>Non ricordi la password?</p>
                <p style={{ fontSize: "0.em", color: "#6262FF", marginBottom: "3px", textDecoration: "underline" }}>Recupera password</p>
  */}
              </form>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setAdmin: PropTypes.func.isRequired
};