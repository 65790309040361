import SearchForm from "../Components/SearchForm";
import React, { useState, useEffect } from "react";
import useToken from "../Components/useToken";
import { Offcanvas } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

async function getNazioni(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/nazione`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function getSingleTag(token, id) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/tag/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

async function checkCampiTag(nome, luogo, file, upload_preview) {
  if (
    file &&
    file.type != "image/png" &&
    file &&
    file.type != "image/jpeg" &&
    file &&
    file.type != "image/webp" &&
    file &&
    file.type != "image/jpg"
  ) {
    return "Formato Immagine non supportato.";
  }
  if (file && file.size > 10000000) {
    return "Immagine troppo pesante. Inserire immagine più leggera.";
  }

  if (upload_preview == "/immagine_profilo_default.svg") {
    return "Errore. Inserire Immagine";
  }

  if (!nome || nome == "") {
    return "Errore. Inserire nome";
  }
  if (!luogo || luogo == "") {
    return "Errore. Inserire una nazione o un luogo.";
  } else return true;
}

async function getNazione(token, id) {
  let result = await fetch(
    `${process.env.REACT_APP_API_KEY}/admin/nazione/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((data) => data.json());
  return result;
}

async function insertNazione(token, title) {
  let result = await axios.post(
    `${process.env.REACT_APP_API_KEY}/admin/nazione`,
    {
      title: title,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    }
  );
  return result.data;
}

async function updatetag(camminoUpdate, token) {
  console.log(camminoUpdate);

  const formData = new FormData();

  if (camminoUpdate.img && camminoUpdate.img != undefined) {
    formData.append("profileImg", camminoUpdate.img);
    formData.append(
      "body",
      JSON.stringify({
        nome: camminoUpdate.nome,
        luogo: camminoUpdate.luogo,
        id_nazione: camminoUpdate.id_nazione,
        active: camminoUpdate.active,
      })
    );
  } else {
    formData.append(
      "body",
      JSON.stringify({
        nome: camminoUpdate.nome,
        luogo: camminoUpdate.luogo,
        id_nazione: camminoUpdate.id_nazione,
        active: camminoUpdate.active,
      })
    );
  }

  let result = await axios.put(
    `${process.env.REACT_APP_API_KEY}/admin/tag/${camminoUpdate.id}`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    }
  );
  return result.data;
}

async function inserttag(
  token,
  insert_nome,
  insert_luogo,
  insert_file,
  id_nazione
) {
  const formData = new FormData();
  formData.append("profileImg", insert_file);
  formData.append(
    "body",
    JSON.stringify({
      nome: insert_nome,
      luogo: insert_luogo,
      id_nazione: id_nazione,
    })
  );
  let result = await axios.post(
    `${process.env.REACT_APP_API_KEY}/admin/tag`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    }
  );
  return result.data;
}

async function getTag(token) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/tag`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
  return result;
}

const Cammini = () => {
  const [alloggi, setCammini] = useState([]);
  const [alloggiBackup, setCamminiBackup] = useState([]);
  const { token, setToken } = useToken();
  const [show_tag, setShowTag] = useState(false);
  const [tag, setTag] = useState();
  const [insert_file, setInsertFile] = useState();
  const [insert_luogo, setInsertLuogo] = useState();
  const [insert_nome, setInsertNome] = useState();
  const [selected_tag, setSelectedtag] = useState();
  const [fotoTag, setFotoTag] = useState("");
  const [upload_preview_tag, setUploadPreviewTag] = useState(
    "/immagine_profilo_default.svg"
  );
  const [foto, setFoto] = useState("");
  const [nazione, setNazione] = useState([]);
  const [nazione_selezionata, setNazioneSelezionata] = useState();
  const [input_visibility, setInputVisibility] = useState(false);
  const [nuova_nazione_input, setNazioneInput] = useState();
  const [loader, setLoader] = useState(false);
  const [modalError, setModalError] = useState("");
  const [nome, setNome] = useState();
  const [luogo, setLuogo] = useState("");
  const [showInfo, setShowInfo] = useState("");
  const [show_update, setShowUpdate] = useState(false);
  const [id, setId] = useState();
  const [camminoUpdate, setCamminoUpdate] = useState({});

  const handleCloseUpdate = async () => {
    let tag = await getTag(token);
    setCammini(tag);
    await setSelectedtag();
    setShowUpdate(false);
    await setInsertNome();
    await setInsertLuogo();
    await setInsertFile("");
    await setNome("");
    await setFoto("");
    await setLuogo("");
    await setUploadPreviewTag("/immagine_profilo_default.svg");
    setCamminoUpdate({});
  };

  const handleCloseInfo = async () => {
    await setSelectedtag();
    setShowInfo(false);
    await setNome("");
    await setFoto("");
    await setLuogo("");
    await setUploadPreviewTag("/immagine_profilo_default.svg");
  };

  const handleCloseTag = async () => {
    setShowTag(false);
    setUploadPreviewTag("/immagine_profilo_default.svg");
    let tag_ = await getTag(token);
    setCammini(tag_);
    let tag = await getTag(token);
    await setTag(tag);
    await setInsertFile();
    await setInsertLuogo();
    await setInsertNome();
    await setSelectedtag();
  };

  const search = (keyword) => {
    let alloggi = alloggiBackup.filter((el) =>
      el.nome.toUpperCase().includes(keyword.toUpperCase())
    );
    if (alloggi?.length == 0) {
      alloggi = alloggiBackup.filter((el) =>
        el.nazione.toUpperCase().includes(keyword.toUpperCase())
      );
    }

    setCammini(alloggi);
  };

  const get_alloggi = useEffect(async () => {
    let nazione_ = await getNazioni(token);
    if (!nazione) {
      localStorage.removeItem("token");
      return;
    }
    setNazione(nazione_);
    let result = await getTag(token);
    setCammini(result);
    setCamminiBackup(result);
  }, []);

  console.log(
    foto != "" &&
    foto != null &&
    upload_preview_tag == "/immagine_profilo_default.svg",
    upload_preview_tag,
    foto
  );
  return (
    <div>
      <Modal
        show={modalError != ""}
        onHide={() => {
          setModalError("");
        }}
      >
        <Modal.Body>
          <div>
            <img
              onClick={() => {
                setModalError("");
              }}
              className="btn-close-img"
              src="/closeBtn.svg"
            />
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          >
            <img
              style={{ width: "60px" }}
              className="btn-close-img1"
              src="/errorImage.svg"
            />
            <div
              style={{
                color: "#FF1400",
                fontSize: "1.4em",
                fontWeight: "500",
                marginTop: "20px",
                marginBottom: "0px",
              }}
            >
              {modalError}
              <br></br>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={showInfo}
        onHide={handleCloseInfo}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseInfo}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Cammini - Info
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div>
              <div>
                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <div
                    className="div-image"
                    style={{
                      backgroundImage: `url("${foto}")`,
                      display: "inline-block",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      bottom: "50px",
                      left: "30px",
                    }}
                  >
                    <img
                      onClick={() => {
                        setShowInfo(false);
                        setShowUpdate(true);
                      }}
                      style={{ marginBottom: "13px", cursor: "pointer" }}
                      src="/updateTable.svg"
                    />
                    <br></br>
                    {/*  <img
                                            onClick={() => {
                                                //setShowDelete(true);
                                                setShowInfo(false)
                                            }}
                                            style={{ cursor: "pointer" }} src="/deleteTable.svg" />
                                        */}
                  </div>
                  <div className=" mb-2">
                    <p className="nome">{nome}</p>
                  </div>

                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Luogo</b>
                    <p>{luogo}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div></div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={show_tag}
        onHide={handleCloseTag}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseTag}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Cammini - Aggiungi
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <div>
              <div>
                <div>
                  <div style={{ marginTop: "40px" }} className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      style={{ marginBottom: "20px" }}
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Cambia Immagine
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                          display: "inline-block",
                          backgroundImage: `url(${fotoTag != "" &&
                              fotoTag != null &&
                              upload_preview_tag ==
                              "/immagine_profilo_default.svg"
                              ? foto
                              : upload_preview_tag
                            })`,
                          backgroundSize: "cover",
                          borderRadius: "100%",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <label
                        htmlFor={`upload-photo-Immagine`}
                        className="file"
                        style={{
                          width: "70%",
                          position: "relative",
                          left: "20px",
                          bottom: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>
                          <h6
                            style={{
                              display: "inline-block",
                              marginBottom: "0px",
                            }}
                          >
                            Cambia Immagine
                          </h6>
                        </span>
                        <input
                          type="file"
                          style={{
                            opacity: "0",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          id={`upload-photo-Immagine`}
                          onChange={async (e) => {
                            if (e.target.files[0]) {
                              await setUploadPreviewTag(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }
                            await setInsertFile(e.target.files[0]);
                          }}
                        />
                      </label>
                    </div>

                    {/* <input type="file" style={{ border: "none", width: "70%", display: "inline-block", position: "relative", left: "20px", bottom: "48px" }} onChange={async (e) => {
                                            if (e.target.files[0]) {
                                                await setUploadPreviewTag(URL.createObjectURL(e.target.files[0]))

                                            }
                                            await setInsertFile(e.target.files[0]);
                                        }} /> */}
                  </div>

                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Nome
                    </label>
                    <input
                      defaultValue={insert_nome}
                      onChange={async (e) => {
                        await setNome(e.target.value);
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>

                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                      style={{ marginBottom: "10px" }}
                    >
                      Nazione
                    </label>
                    {nazione?.map((item, index) => {
                      return (
                        item.title != null && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "35px",
                            }}
                          >
                            <input
                              onChange={async (e) => {
                                if (e.target.checked) {
                                  setLuogo(item.title);
                                  setNazioneInput(item.id);
                                }
                              }}
                              type="radio"
                              style={{
                                width: "25px",
                                position: "relative",
                                top: "5px",
                                marginRight: "7px",
                              }}
                              name="site_name"
                              value={item.id}
                            />
                            <label
                              style={{
                                fontSize: "0.9em",
                                color: "#707070",
                                fontWeight: "300",
                              }}
                            >
                              {item.title}
                            </label>
                          </div>
                        )
                      );
                    })}

                    {input_visibility && (
                      <div style={{ marginTop: "12px" }}>
                        <label
                          data-error="wrong"
                          data-success="right"
                          for="orangeForm-name"
                          style={{ marginBottom: "10px" }}
                        >
                          Nuova nazione
                        </label>
                        <div style={{ display: "flex" }}>
                          <input
                            style={{ width: "80%" }}
                            onChange={async (e) => {
                              await setNazioneInput(e.target.value);
                            }}
                            type="text"
                            id="orangeForm-name"
                            className="form-control validate"
                          />
                          <button
                            onClick={async () => {
                              let insert = await insertNazione(
                                token,
                                nuova_nazione_input
                              );
                              if (insert.status == "ok") {
                                let result = await getNazioni(token);
                                setNazione(result);
                                setInputVisibility(false);
                              }
                            }}
                            style={{
                              position: "relative",
                              float: "right",
                              border: "none",
                              boxShadow: "0px 3px 6px #00000029",
                              borderRadius: "20px",
                              height: "45px",
                              marginLeft: "2%",
                              fontSize: "0.9em",
                            }}
                          >
                            Aggiungi
                          </button>
                        </div>
                      </div>
                    )}

                    <button
                      onClick={async () => {
                        await setInputVisibility(!input_visibility);
                      }}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "0.9em",
                        color: "#707070",
                        marginTop: "0px",
                      }}
                    >
                      + Aggiungi Nazione
                    </button>
                  </div>
                </div>
                <div className="">
                  {loader && (
                    <div style={{ textAlign: "center", paddingTop: "20px" }}>
                      <img src="/loader.gif" style={{ borderRadius: "100%" }} />
                    </div>
                  )}
                  {!loader && (
                    <button
                      onClick={async () => {
                        setLoader(true);
                        let check_campi = await checkCampiTag(
                          nome,
                          luogo,
                          insert_file,
                          upload_preview_tag
                        );
                        if (check_campi == true) {
                          await setModalError("");
                          let resultInsert = await inserttag(
                            token,
                            nome,
                            luogo,
                            insert_file,
                            nuova_nazione_input
                          );
                          if (resultInsert.status == "ok") {
                            handleCloseTag();
                            await setModalError("");
                          }
                          setLoader(false);
                        } else {
                          setLoader(false);
                          await setModalError(check_campi);
                        }
                      }}
                      className="btn btn-deep-orange close btn-update"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Aggiungi
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas-custom"
        placement="end"
        show={show_update}
        onHide={handleCloseUpdate}
      >
        <Offcanvas.Header>
          <img
            onClick={handleCloseUpdate}
            className="btn-close-img"
            src="/closeBtn.svg"
          />
          <p
            style={{
              color: "#B1B1B1",
              fontSize: "2em",
              marginBottom: "0",
              position: "absolute",
              left: "60px",
            }}
          >
            Cammini - Modifica
          </p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <div>
              <div>
                <div>
                  <div style={{ marginTop: "40px" }} className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      style={{ marginBottom: "20px" }}
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Cambia Immagine
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                          display: "inline-block",
                          backgroundImage: `url(${foto != "" &&
                              foto != null &&
                              upload_preview_tag ==
                              "/immagine_profilo_default.svg"
                              ? foto
                              : camminoUpdate.img
                            })`,
                          backgroundSize: "cover",
                          borderRadius: "100%",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <label
                        htmlFor={`upload-photo-Immagine`}
                        className="file"
                        style={{
                          width: "70%",
                          position: "relative",
                          left: "20px",
                          bottom: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>
                          <h6
                            style={{
                              display: "inline-block",
                              marginBottom: "0px",
                            }}
                          >
                            Cambia Immagine
                          </h6>
                        </span>
                        <input
                          type="file"
                          style={{
                            opacity: "0",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          id={`upload-photo-Immagine`}
                          onChange={async (e) => {
                            if (e.target.files[0]) {
                              await setUploadPreviewTag(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }
                            setInsertFile(e.target.files[0]);
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                    >
                      Nome
                    </label>
                    <input
                      defaultValue={camminoUpdate.nome}
                      onChange={(e) => {
                        setCamminoUpdate({
                          ...camminoUpdate,
                          nome: e.target.value,
                        });
                      }}
                      type="text"
                      id="orangeForm-name"
                      className="form-control validate"
                    />
                  </div>
                  <div className="">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                      style={{ display: "inline" }}
                    >
                      Attivo
                    </label>
                    {camminoUpdate.active === 0 && (
                      <input
                        type="checkbox"
                        style={{
                          width: "15px",
                          position: "relative",
                          display: "inline",
                        }}
                        onChange={(e) => {
                          setCamminoUpdate({
                            ...camminoUpdate,
                            active: 1,
                          });
                        }}
                      />
                    )}
                    {camminoUpdate.active === 1 && (
                      <input
                        type="checkbox"
                        style={{
                          width: "15px",
                          position: "relative",
                          display: "inline",
                        }}
                        defaultChecked
                        onChange={(e) => {
                          setCamminoUpdate({
                            ...camminoUpdate,
                            active: 0,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className="  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <label
                      data-error="wrong"
                      data-success="right"
                      for="orangeForm-name"
                      style={{ marginBottom: "10px" }}
                    >
                      Nazione
                    </label>

                    {nazione.map((item, index) => {
                      return (
                        item.title != null && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "35px",
                            }}
                          >
                            <input
                              onChange={async (e) => {
                                if (e.target.checked) {
                                  setCamminoUpdate({
                                    ...camminoUpdate,
                                    luogo: item.title,
                                  });
                                }
                              }}
                              defaultChecked={
                                item.title === camminoUpdate.luogo
                                  ? true
                                  : false
                              }
                              type="radio"
                              style={{
                                width: "25px",
                                position: "relative",
                                top: "5px",
                                marginRight: "7px",
                              }}
                              name="site_name"
                              value={item.id}
                            />
                            <label
                              style={{
                                fontSize: "0.9em",
                                color: "#707070",
                                fontWeight: "300",
                              }}
                            >
                              {item.title}
                            </label>
                          </div>
                        )
                      );
                    })}

                    {input_visibility && (
                      <div style={{ marginTop: "12px" }}>
                        <label
                          data-error="wrong"
                          data-success="right"
                          for="orangeForm-name"
                          style={{ marginBottom: "10px" }}
                        >
                          Nuova nazione
                        </label>
                        <div style={{ display: "flex" }}>
                          <input
                            style={{ width: "80%" }}
                            onChange={async (e) => {
                              await setNazioneInput(e.target.value);
                            }}
                            type="text"
                            id="orangeForm-name"
                            className="form-control validate"
                          />
                          <button
                            onClick={async () => {
                              let insert = await insertNazione(
                                token,
                                nuova_nazione_input
                              );
                              if (insert.status == "ok") {
                                let result = await getNazioni(token);
                                setNazione(result);
                                setInputVisibility(false);
                              }
                            }}
                            style={{
                              position: "relative",
                              float: "right",
                              border: "none",
                              boxShadow: "0px 3px 6px #00000029",
                              borderRadius: "20px",
                              height: "45px",
                              marginLeft: "2%",
                              fontSize: "0.9em",
                            }}
                          >
                            Aggiungi
                          </button>
                        </div>
                      </div>
                    )}

                    <button
                      onClick={async () => {
                        await setInputVisibility(!input_visibility);
                      }}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "0.9em",
                        color: "#707070",
                        marginTop: "0px",
                      }}
                    >
                      + Aggiungi Nazione
                    </button>
                  </div>
                </div>
                <div className="">
                  {loader && (
                    <div style={{ textAlign: "center", paddingTop: "20px" }}>
                      <img src="/loader.gif" style={{ borderRadius: "100%" }} />
                    </div>
                  )}
                  {!loader && (
                    <button
                      onClick={async () => {
                        setLoader(true);
                        await setModalError("");
                        let resultInsert = await updatetag(
                          camminoUpdate,
                          token
                        );
                        if (resultInsert.status == "ok") {
                          handleCloseUpdate();
                          await setModalError("");
                        }
                        setLoader(false);
                      }}
                      className="btn btn-deep-orange close btn-update"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Modifica
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div
        style={{
          position: "absolute",
          top: "0",
          paddingTop: "65px",
          width: "100%",
          backgroundColor: "#F4F4F4",
          zIndex: "1000",
        }}
      >
        <h1 className="title-sezione">
          <img
            src="camminiIcon.svg"
            style={{
              position: "relative",
              bottom: "3px",
              width: "23px",
              marginRight: "12px",
            }}
            alt=""
          />
          Cammini
        </h1>

        <div style={{ marginTop: "40px", width: "75%" }}>
          <SearchForm nome="Cerca" search={search} />

          <button
            variant="primary"
            onClick={async () => {
              await setModalError("");
              await setNome("");
              await setLuogo("");
              await setFoto("");
              await setInsertFile("");
              await setUploadPreviewTag("/immagine_profilo_default.svg");
              setShowTag(true);
            }}
            className="btn btn-default btn-rounded mb-4 aggiungi-struttura "
            data-toggle="modal"
            data-target="#modalRegisterForm"
            style={{
              position: "relative",
              float: "right",
              border: "none",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "20px",
              height: "50px",
              width: "20%",
              fontSize: "0.9em",
            }}
          >
            Aggiungi Cammino
          </button>
        </div>
      </div>

      <div
        className="alloggi overflow-responsive Card"
        style={{
          marginTop: "190px",
          paddingBottom: "100px",
          borderRadius: "25px",
          position: "relative",
          left: "",
        }}
      >
        {alloggi.map((cammino) => (
          <div
            className="div-cammini"
            style={{
              padding: "10px",
              marginBottom: "2%",
              marginLeft: "1%",
              display: "inline-block",
              backgroundColor: "#ffffff",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "20px",
              height: "auto",
              maxHeight: "300px",
            }}
            key={cammino.id}
          >
            <div
              style={{
                height: "110px",
                backgroundImage: `url("${cammino.img}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                borderRadius: "20px",
              }}
            ></div>
            <div style={{ padding: "10px", paddingBottom: "0" }}>
              {cammino.active == 0 && (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.8em",
                    fontWeight: "700",
                    marginBottom: "0",
                  }}
                >
                  Non Attivo
                </p>
              )}
              {cammino.active == 1 && (
                <p
                  style={{
                    color: "#A9DE5B",
                    fontSize: "0.8em",
                    fontWeight: "700",
                    marginBottom: "0",
                  }}
                >
                  Attivo
                </p>
              )}
              <p
                style={{
                  color: "#B1B1B1",
                  fontSize: "1em",
                  fontWeight: "300",
                  marginBottom: "0",
                }}
              >
                {cammino.luogo?.substring(0, 25)}
                {cammino.luogo?.length > 25 ? <>..</> : null}
              </p>
              <p
                style={{
                  color: "#2F2F2F",
                  fontSize: "1em",
                  fontWeight: "700",
                  marginBottom: "18px",
                }}
              >
                {cammino.nome?.substring(0, 25)}
                {cammino.nome?.length > 25 ? <>..</> : null}
              </p>

              <div>
                <button
                  onClick={async () => {
                    await setFoto(cammino.img);
                    await setNome(cammino.nome);
                    await setInsertNome(cammino.nome);
                    await setLuogo(cammino.luogo);
                    setShowInfo(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    border: "none",
                    paddingLeft: "0",
                  }}
                >
                  <img style={{ width: "25px" }} src="./infoTable.svg" />
                </button>
                <button
                  onClick={async () => {
                    await setCamminoUpdate(cammino);
                    setShowUpdate(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                    paddingLeft: "0",
                  }}
                >
                  <img style={{ width: "25px" }} src="./updateTable.svg" />
                </button>

                {/*
                                <button onClick={async () => {



                                }} style={{ backgroundColor: "transparent", border: "none", boxShadow: "none", paddingLeft: "0" }}><img style={{ width: "25px" }} src="./deleteTable.svg" /></button>
                            */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cammini;
