import { Modal } from "react-bootstrap";

export default function ModalError({ modalError, setModalError }) {
    return modalError ? <Modal
        onHide={() => {
            setModalError(false);
        }}
    >
        <Modal.Body>
            <div>
                <img
                    onClick={() => {
                        setModalError("");
                    }}
                    className="btn-close-img"
                    src="/closeBtn.svg"
                    alt="close"
                />
            </div>
            <div
                style={{
                    textAlign: "center",
                    paddingTop: "50px",
                    paddingBottom: "80px",
                }}
            >
                <img
                    style={{ width: "60px" }}
                    className="btn-close-img1"
                    src="/errorImage.svg"
                    alt="error"
                />
                <div
                    style={{
                        color: "#FF1400",
                        fontSize: "1.4em",
                        fontWeight: "500",
                        marginTop: "20px",
                        marginBottom: "0px",
                    }}
                >
                    {modalError}
                    <br></br>
                </div>
            </div>
        </Modal.Body>
    </Modal> : <span></span>;
}