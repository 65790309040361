import React from "react";

function SearchForm(props) {
  return (
    <form style={{ width: "80%", display: "inline-block" }}>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          width: "420px",
          backgroundColor: "#ffffff",
          height: "50px",
          padding: "10px",
          outline: "none",
          borderRadius: "15px",
          border: "none",
        }}
      >
        <img
          alt=""
          style={{ position: "relative", bottom: "8px", left: "5px" }}
          src="/searchIcon.svg"
        />
        <input
          style={{
            height: "45px",
            padding: "10px",
            outline: "none",
            border: "none",
            position: "relative",
            left: "5px",
            bottom: "10px",
            width: "93%",
          }}
          id="action-search"
          className="form-input pl-9 focus:border-gray-300"
          type="search"
          onChange={(e) => {
            props.search(e.target.value);
          }}
          placeholder="Cerca…"
        />
      </div>
    </form>
  );
}

export default SearchForm;
