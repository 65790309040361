import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    try {
      const userToken = JSON.parse(tokenString);
      if (userToken) {
        return userToken
      }
    }
    catch (err) {
      localStorage.removeItem('token');
      console.log(err)
    }
  };

  const getAdmin = () => {
    const tokenString = localStorage.getItem('admin');
    const userAdmin = JSON.parse(tokenString);
    if (userAdmin) {
      return userAdmin
    }
  };

  const [token, setToken] = useState(getToken());
  const [admin, setAdmin] = useState(getAdmin());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  const saveAdmin = userAdmin => {
    localStorage.setItem('admin', JSON.stringify(userAdmin));
    setAdmin(userAdmin);
  };



  return {
    setToken: saveToken,
    setAdmin: saveAdmin,
    token,
    admin
  }
}