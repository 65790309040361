import axios from "axios";

export async function fetchData(url, token) {
    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    }).then((data) => data.json());
}

export async function getAlloggi(token) {
    let result = await fetchData(`${process.env.REACT_APP_API_KEY}/admin/prenotazioni`, token);
    let utenti = [];

    if (result) {
        result.sort((a, b) => b.id - a.id);
    }

    const users = await fetchData(`${process.env.REACT_APP_API_KEY}/admin/users`, token);
    const userMap = new Map(users.map((user) => [user.id, user]));

    utenti = result.map((prenotazione) => userMap.get(prenotazione.id_utente)).filter(Boolean);

    return { result, utenti };
}

export async function getAllUtenti(token) {
    return await fetchData(`${process.env.REACT_APP_API_KEY}/admin/users`, token);
}

export async function getStrutture(token) {
    return await fetchData(`${process.env.REACT_APP_API_KEY}/admin/alloggi`, token);
}

export async function getTappeAll(token) {
    return await fetchData(`${process.env.REACT_APP_API_KEY}/admin/tappe`, token);
}

export async function getAlloggiByStatus(token, status) {
    const result = await fetchData(
        `${process.env.REACT_APP_API_KEY}/admin/prenotazioni?stato=${encodeURIComponent(status)}`,
        token
    );

    const users = await fetchData(`${process.env.REACT_APP_API_KEY}/admin/users`, token);
    const userMap = new Map(users.map((user) => [user.id, user]));

    const utenti = result.map((prenotazione) => userMap.get(prenotazione.id_utente)).filter(Boolean);

    return { result, utenti };
}

export async function getPrenotazioni(token, id) {
    return await fetchData(
        `${process.env.REACT_APP_API_KEY}/admin/tappe_prenotazioni_alloggi?id_prenotazione=${id}`,
        token
    );
}

export async function getTappe(token) {
    return await fetchData(`${process.env.REACT_APP_API_KEY}/admin/cammini`, token);
}

export async function getCammino(token, id, camminoId) {
    const result_cammino = await fetchData(
        `${process.env.REACT_APP_API_KEY}/admin/cammini?id=${camminoId}`,
        token
    );

    return result_cammino[0];
}

export async function deletecammino(token, id_cammino) {
    let result = await fetch(
        `${process.env.REACT_APP_API_KEY}/admin/tappe_prenotazioni_alloggi/${id_cammino}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
    ).then((data) => data.json());

    return result;
}

export async function checkCampi(stato_prenotazione, data_prenotata, totale, bagagli) {
    if (!stato_prenotazione || stato_prenotazione == "") {
        return "Errore. Inserire stato prenotazione";
    } else if (!totale || totale == 0) {
        return "Errore. Inserire Totale prenotazione.";
    } else return true;
}


export async function insertcammino(token, id_utente, stato_prenotazione, totale) {
    const body = {
        id_utente: id_utente,
        stato: stato_prenotazione,
        totale: totale,
    };

    let result = await axios.post(
        `${process.env.REACT_APP_API_KEY}/admin/prenotazioni`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return result;
}

export async function insertcammino2(
    token,
    tappa_inizio,
    tappa_fine,
    costo,
    data,
    alloggio_ritiro,
    alloggio_consegna,
    bagagli,
    stato,
    id_prenotazione_,
    id_utente,
    nome_tappa_inizio,
    nome_tappa_fine
) {
    const body = {
        admin: "admin",
        id_utente: id_utente,
        id_tappa_inizio: tappa_inizio,
        id_tappa_fine: tappa_fine,
        nome_tappa_inizio: nome_tappa_inizio,
        nome_tappa_fine: nome_tappa_fine,
        data_prenotazione: data,
        id_alloggio_inizio: alloggio_ritiro,
        id_alloggio_fine: alloggio_consegna,
        quantita_bagagli: bagagli,
        costo_prenotazione_singola: costo,
        stato: stato,
        id_prenotazione: id_prenotazione_,
    };
    console.log(body);
    let result = await axios.post(
        `${process.env.REACT_APP_API_KEY}/admin/tappe_prenotazioni_alloggi`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return result;
}

export async function updatecammino(
    token,
    id_cammino,
    id_prenotazione,
    stato_prenotazione,
    data_prenotata,
    totale,
    bagagli
) {
    const body = { stato: stato_prenotazione, totale: totale };
    let result = await axios.put(
        `${process.env.REACT_APP_API_KEY}/admin/prenotazioni/${id_prenotazione}`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
    );
    const body_ = {
        quantita_bagagli: bagagli,
    };

    if (result.statusText == "OK") {
        let result_ = await axios.put(
            `${process.env.REACT_APP_API_KEY}/admin/tappe_prenotazioni_alloggi/${id_cammino}`,
            body_,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return result_;
    }
}

export async function updatecammino1(token, id_prenotazione) {
    const body = { stato: "Pagato" };
    let result = await axios.put(
        `${process.env.REACT_APP_API_KEY}/admin/prenotazioni/${id_prenotazione}`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return result.data;
}
export async function getAlloggiPagato(token) {
    return await getAlloggiByStatus(token, "Pagato");
}

export async function getAlloggiAnnullato(token) {
    return await getAlloggiByStatus(token, "Annullato");
}
