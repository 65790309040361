import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import useToken from "../Components/useToken";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import axios from 'axios';
import { data } from 'jquery';



async function getUtente(token, admin) {
  let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users/${admin}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    }
  })
    .then(data => data.json())
  return result;
}
async function checkCampi(nome, cognome, via, cap, citta, nazione, email, file, titolo, telefono, password, admin_) {
  var re = /\S+@\S+\.\S+/;

  if (!nome || nome == "") {
    return "Errore. Inserire nome";
  }
  if (!cognome || cognome == "") {
    return "Errore. Inserire cognome";
  }
  if (!telefono || telefono.length < 10) {
    return "Errore. Inserire Telefono correttamente.";
  }
  else if (!email || email == "" || !re.test(email)) {
    return "Errore. Inserire un'email valida";
  }

  else return true;
}
async function updateAlloggio(token, id, nome, cognome, via, cap, città, nazione, email, file2, titolo, telefono, password, admin, foto) {
  const formData = new FormData()
  if (file2) {
    formData.append('profileImg', file2)
    formData.append('body', JSON.stringify({
      nome: nome,
      cognome: cognome,
      via: via,
      cap: cap,
      citta: città,
      nazione: nazione,
      email: email,
    }))
  }
  else {
    formData.append('body', JSON.stringify({
      nome: nome,
      cognome: cognome,
      via: via,
      cap: cap,
      citta: città,
      nazione: nazione,
      email: email,
      titolo: titolo,
      telefono: telefono,
      password: password,
      admin: admin,
      immagine_profilo: foto
    }))
  }


  let result = await axios.put(`${process.env.REACT_APP_API_KEY}/admin/users/${id}`, formData, {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    },
  })

  if (result.statusText == "OK") {
    return true;
  }
}



export default function NavbarHorizontal(props) {

  const [showUpdate, setShowUpdate] = useState(false);
  const handleShowUpdate = () => setShowUpdate(true);
  const handleCloseUpdate = () => { setUploadPreview("/immagine_profilo_default.svg"); setShowUpdate(false) };
  const [menu, setMenu] = useState(false);
  const [nome, setNome] = useState("");
  const [id, setId] = useState(0);
  const [data, setData] = useState();
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [via, setVia] = useState("");
  const [cap, setCap] = useState(0);
  const [citta, setCitta] = useState("");
  const [telefono, setTelefono] = useState();
  const [titolo, setTitolo] = useState("");
  const [foto, setFoto] = useState("");
  const [admin_, setAdmin_] = useState();
  const [password, setPassword] = useState("");
  const [upload_preview, setUploadPreview] = useState('/immagine_profilo_default.svg');

  const [nazione, setNazione] = useState("");
  const [file, setFile] = useState();
  const { token, setToken } = useToken();
  const { admin, setAdmin } = useToken();
  const [modalError, setModalError] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [image, setImage] = useState(false);
  const handleShowInfo = () => setShowInfo(true);
  const handleCloseInfo = () => setShowInfo(false);


  useEffect(async () => {


    let result = await getUtente(token, admin.id);
    await setNome(result[0].nome);
    await setFoto(result[0].immagine_profilo);
    await setAdmin_(result[0].admin);
    await setPassword(result[0].password);

    await setCognome(result[0].cognome);
    await setTelefono(result[0].telefono);
    await setData(result[0].data_di_nascita);
    await setVia(result[0].via);
    await setCap(result[0].cap);
    await setCitta(result[0].citta);
    await setTitolo(result[0].titolo);
    await setId(result[0].id);
    await setNazione(result[0].nazione);

    await setEmail(result[0].email);
  }, []);

  return (
    <div className="login-wrapper">
      <Modal show={modalError != ""} onHide={() => { setModalError("") }}>
        <Modal.Body>
          <div>
            <img onClick={() => { setModalError("") }} className="btn-close-img" src="/closeBtn.svg" />
          </div>
          <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "80px" }}>
            <img style={{ width: "60px" }} className="btn-close-img1" src="/errorImage.svg" />
            <div style={{ color: "#FF1400", fontSize: "1.4em", fontWeight: "500", marginTop: "20px", marginBottom: "0px" }}>{modalError}<br></br></div>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas className="offcanvas-custom" placement="end" show={showInfo} onHide={handleCloseInfo}>
        <Offcanvas.Header >
          <img onClick={handleCloseInfo} className="btn-close-img" src="/closeBtn.svg" />
          <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Strutture - Info</p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div>
              <div>
                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <div className="div-image1" style={{ backgroundImage: `url("${foto}")`, display: "inline-block", borderRadius: "100%" }}>

                  </div>
                  <div style={{ display: "inline-block", position: "relative", bottom: "45px", left: "30px" }}>
                    <img onClick={() => { setShowInfo(false); setShowUpdate(true) }} style={{ marginBottom: "13px", cursor: "pointer" }} src="/updateTable.svg" /><br></br>

                  </div>
                  <div className=" mb-2">
                    <p className="nome">{nome} {cognome}</p>
                  </div>


                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Email</b>
                    <p>{email}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Telefono</b>
                    <p>{telefono}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Data di nascita</b>
                    <p>{new Date(data).toLocaleDateString()}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Titolo</b>
                    <p>{titolo}</p>
                  </div>
                  <div className="md-form  mb-2">
                    <i className="fas fa-user prefix grey-text"></i>
                    <b>Indirizzo</b>
                    <p>{via}, {cap}, {citta}, {nazione}</p>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>



      <Offcanvas className="offcanvas-custom" placement="end" show={showUpdate} onHide={handleCloseUpdate}>
        <Offcanvas.Header >
          <img onClick={handleCloseUpdate} className="btn-close-img" src="/closeBtn.svg" />
          <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Utenti - Modifica</p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <h1 style={{ fontSize: "1.3em", fontWeight: "700", marginBottom: "0", marginTop: "30px" }}>Modifica</h1>
            <h2 style={{ fontSize: "1.6em", fontWeight: "300" }}>{nome} {cognome}</h2>
            <div>
              <div>
                <div>

                  <div style={{ marginTop: "40px" }} className="  mb-2">

                    <i className="fas fa-user prefix grey-text"></i>
                    <label style={{ marginBottom: "20px" }} data-error="wrong" data-success="right" for="orangeForm-name">Cambia Immagine</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ height: "100px", width: "100px", display: "inline-block", backgroundImage: `url(${(foto != "" && foto != null && upload_preview == '/immagine_profilo_default.svg') ? foto : upload_preview})`, backgroundSize: "cover", borderRadius: "100%", backgroundPosition: "center" }}>
                      </div>
                      <label htmlFor={`upload-photo-Immagine`} className="file" style={{ width: "70%", position: "relative", left: "20px", bottom: "8px", alignItems: "center", justifyContent: "center" }}>
                        <span>
                          <h6 style={
                            { display: "inline-block", marginBottom: "0px" }}>Inserisci Immagine</h6>
                        </span>
                        <input type="file"
                          style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                          id={`upload-photo-Immagine`}
                          onChange={async (e) => {
                            if (e.target.files[0]) {
                              await setUploadPreview(URL.createObjectURL(e.target.files[0]))
                            }
                            await setFile(e.target.files[0]);
                          }}
                        />
                      </label>
                    </div>
                  </div>



                  {
                                /*
                                   <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Tappa associata</label>
                                        <select defaultValue="Seleziona una tappa associata" onChange={async (e) => {
                                            await setTappa(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">

                                            <option value="gergerge">Seleziona una tappa</option>
                                            {tappe.map((tappa) =>
                                                <option key={tappa.id} value={tappa.id} >{tappa.nome}</option>
                                            )}
                                        </select>
                                    </div>
                                            */}
                </div>
                <div>
                  <div>
                    <div>
                      <div>
                        <div className="  mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Nome</label>
                          <input defaultValue={nome} onChange={async (e) => {
                            await setNome(e.target.value);
                          }} type="text" id="orangeForm-name" className="form-control validate" />
                        </div>
                        <div className="  mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Cognome</label>
                          <textarea defaultValue={cognome} onChange={async (e) => {
                            await setCognome(e.target.value);
                          }} type="text" id="orangeForm-name" className="form-control validate" />
                        </div>

                        <div className="  mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Email</label>
                          <textarea defaultValue={email} onChange={async (e) => {
                            await setEmail(e.target.value);
                          }} type="text" id="orangeForm-name" className="form-control validate" />
                        </div>
                        <div className="  mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Ruolo</label>

                          <select defaultValue={admin} onChange={async (e) => {
                            await setAdmin_(e.target.value);
                          }} type="select" id="orangeForm-name" className="form-control validate">
                            <option value={1}>Admin</option>
                            <option value={0}>Cliente</option>

                          </select>
                        </div>
                        <div className="  mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Via</label>
                          <textarea defaultValue={via} onChange={async (e) => {
                            await setVia(e.target.value);
                          }} type="text" id="orangeForm-name" className="form-control validate" />
                        </div>
                        <div className="  mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Cap</label>
                          <input defaultValue={cap} onChange={async (e) => {
                            await setCap(e.target.value);
                          }} type="number" id="orangeForm-name" className="form-control validate" />
                        </div>
                        <div className="  mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Città</label>
                          <textarea defaultValue={citta} onChange={async (e) => {
                            await setCitta(e.target.value);
                          }} type="text" id="orangeForm-name" className="form-control validate" />
                        </div>

                        <div className=" mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Nazione</label>
                          <textarea defaultValue={nazione} onChange={async (e) => {
                            await setNazione(e.target.value);
                          }} type="text" id="orangeForm-name" className="form-control validate" />
                        </div>
                        <div className="  mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Telefono</label>
                          <textarea defaultValue={telefono} onChange={async (e) => {
                            await setTelefono(e.target.value);
                          }} type="text" id="orangeForm-name" className="form-control validate" />
                        </div>

                        <div className=" mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Data di nascita</label>
                          <input defaultValue={data} onChange={async (e) => {
                            await setData(e.target.value);
                          }} type="date" id="orangeForm-name" className="form-control validate" />
                        </div>
                        <div className=" mb-2">
                          <i className="fas fa-user prefix grey-text"></i>
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Titolo</label>
                          <textarea defaultValue={titolo} onChange={async (e) => {
                            await setTitolo(e.target.value);
                          }} type="text" id="orangeForm-name" className="form-control validate" />
                        </div>
                      </div>
                      <div className="">
                        <button onClick={async () => {
                          let check_campi = await checkCampi(nome, cognome, via, cap, citta, nazione, email, file, titolo, telefono, password, admin_);
                          if (check_campi == true) {
                            await setModalError("");
                            let resultInsert = await updateAlloggio(token, id, nome, cognome, via, cap, citta, nazione, email, file, titolo, telefono, password, admin_, foto);
                            if (resultInsert) {
                              handleCloseUpdate();
                              await setFile()
                              await setModalError("")
                              window.location.reload();
                            }
                          }
                          else {
                            await setModalError(check_campi);
                          }
                        }}
                          className="btn btn-deep-orange close btn-update" data-dismiss="modal" aria-label="Close">Salva</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
      <div style={{ width: "280px", zIndex: "1000", position: "absolute", left: "2vh", top: "6vh" }}>
        <div style={{ margin: "0 auto", height: "50px", width: "50px", borderRadius: "100%", backgroundImage: `url("${foto ? foto : upload_preview}")`, backgroundPosition: "center", backgroundSize: "cover" }}>
        </div>
        <div style={{ textAlign: "center", fontSize: "1em", fontWeight: "500", paddingTop: "7px" }}>
          <span href="#" onClick={() => {
            handleShowInfo();
          }}
            style={{ textDecoration: "underline", color: "inherit", cursor: "pointer" }}
          >{nome} {cognome}</span>
        </div>
      </div>
      <br></br>
    </div>
  )
}
