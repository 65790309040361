import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../Components/CheckoutForm"
import useToken from "../../Components/useToken";
import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import SearchForm from "../../Components/SearchForm"
import axios from 'axios';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';



async function getAlloggi(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/alloggi`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())

    // return result from last to first
    return result.reverse();

}

async function getTappeConferma(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/alloggi?stato=0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())
    return result;

}


async function getTappe(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/tappe`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())
    return result;

}

async function deleteAlloggio(token, id_alloggio, foto, nome) {
    const formData = new FormData()
    formData.append('body', JSON.stringify({
        nome: nome,
        attivo: 1,
        foto_alloggio: foto
    }))

    let result = await axios.put(`${process.env.REACT_APP_API_KEY}/admin/alloggi/${id_alloggio}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })



}

async function checkCampi(nome, descrizione, file, link, luogo, numero_telefono, email, tappa, upload_preview, foto, orario_apertura, orario_chiusura) {
    if ((file && file.type != "image/png") && (file && file.type != "image/jpeg") && (file && file.type != "image/webp")) {
        return "Formato Immagine non supportato.";
    }
    if (file && file.size > 1000000) {
        return "Immagine troppo pesante. Inserire immagine più leggera.";
    }

    var re = /\S+@\S+\.\S+/;
    var patternLink = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i');

    if (!nome || nome == "") {
        return "Errore. Inserire nome";
    }
    else if (!luogo || luogo == "") {
        return "Errore. Inserire luogo";
    }
    else if (numero_telefono.length < 9) {
        return "Errore. Inserire numero di telefono";
    }

    else if (!re.test(email)) {
        return "Errore. Inserire un'email valida";
    }
    else if (!tappa || tappa == "") {
        return "Errore. Selezionare una tappa";
    }

    else if (!orario_apertura || orario_apertura == "") {
        return "Errore. Inserire orario di apertura";
    }
    else if (!orario_chiusura || orario_chiusura == "") {
        return "Errore. Inserire orario di chiusura";
    }

    else return true;
}

async function insertAlloggio(token, id_alloggio, nome, descrizione, file, prezzo_min, prezzo_max, link, luogo, numero_telefono, email, costo_pre_prenotazione, id_tappa, attivo, orario_apertura, orario_chiusura) {
    const formData = new FormData()
    formData.append('profileImg', file)
    formData.append('body', JSON.stringify({
        nome_en: nome,
        descrizione: descrizione,
        prezzo_min: prezzo_min,
        prezzo_max: prezzo_max,
        link: link,
        luogo_en: luogo,
        numero_telefono: numero_telefono,
        email: email,
        costo_pre_prenotazione: costo_pre_prenotazione,
        id_tappa: id_tappa,
        attivo: attivo,
        stato: 1,
        orario_apertura: orario_apertura,
        orario_chiusura: orario_chiusura
    }))
    let result = await axios.post(`${process.env.REACT_APP_API_KEY}/admin/alloggi`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })
    return result.data;
}

async function updateAlloggio(token, id_alloggio, nome, file2, descrizione, prezzo_min, prezzo_max, link, luogo, numero_telefono, email, costo_pre_prenotazione, id_tappa, foto, attivo, orario_apertura, orario_chiusura) {

    console.log(numero_telefono)

    const formData = new FormData()
    if (file2) {
        formData.append('profileImg', file2)
        formData.append('body', JSON.stringify({
            nome_en: nome,
            descrizione: descrizione,
            prezzo_min: prezzo_min,
            prezzo_max: prezzo_max,
            link: link,
            luogo_en: luogo,
            numero_telefono: numero_telefono,
            email: email,
            costo_pre_prenotazione: costo_pre_prenotazione,
            id_tappa: id_tappa,
            attivo: attivo,
            orario_apertura: orario_apertura,
            orario_chiusura: orario_chiusura
        }))
    }

    else {
        formData.append('body', JSON.stringify({
            nome_en: nome,
            descrizione: descrizione,
            prezzo_min: prezzo_min,
            prezzo_max: prezzo_max,
            link: link,
            luogo_en: luogo,
            numero_telefono: numero_telefono,
            email: email,
            costo_pre_prenotazione: costo_pre_prenotazione,
            id_tappa: id_tappa,
            foto_alloggio: foto,
            attivo: attivo,
            orario_apertura: orario_apertura,
            orario_chiusura: orario_chiusura
        }))
    }

    let result = await axios.put(`${process.env.REACT_APP_API_KEY}/admin/alloggi/${id_alloggio}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })

    return result.data;
}



async function updateAlloggio1(token, id_alloggio, nome, foto) {

    const formData = new FormData()

    formData.append('body', JSON.stringify({
        nome: nome,
        foto_alloggio: foto,
        stato: 1
    }))

    let result = await axios.put(`${process.env.REACT_APP_API_KEY}/admin/alloggi/${id_alloggio}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })

    return result.data;
}





const Alloggi = () => {

    const search = (keyword) => {

        let alloggi = alloggiBackup.filter((el) => el.nome_en.toUpperCase().includes(keyword.toUpperCase()))
        if (alloggi?.length == 0) {
            alloggi = alloggiBackup.filter((el) => el.luogo_en.toUpperCase().includes(keyword.toUpperCase()))
        }
        if (alloggi?.length == 0) {
            alloggi = alloggiBackup.filter((el) => el.email.toUpperCase().includes(keyword.toUpperCase()))
        }
        setAlloggi(alloggi)
    }

    const [loader, setLoader] = useState(false);
    const { token, setToken } = useToken();
    const [alloggi, setAlloggi] = useState([]);
    const [alloggiBackup, setAlloggiBackup] = useState([]);
    const [show, setShow] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [upload_preview, setUploadPreview] = useState('/immagine_profilo_default.svg');
    const [attivo, setAttivo] = useState(0);
    const [open, setOpen] = useState(false);
    const [conferma, setConferma] = useState(false);
    const [button_conferma, setButtonConferma] = useState(false);
    const [orario_apertura, setOrarioApertura] = useState();
    const [orario_chiusura, setOrarioChiusura] = useState();
    const [nome_referente, setNomeReferente] = useState();


    const [modalError, setModalError] = useState("");


    const handleShowUpdate = () => setShowUpdate(true);
    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setUploadPreview("/immagine_profilo_default.svg");

    }
    const handleShowInfo = () => setShowInfo(true);
    const handleCloseInfo = () => setShowInfo(false);
    const handleClose = () => {
        setShow(false); setModalError(""); setTappe([]); setNome(""); setDescrizione(""); setFoto(""); setPrezzoMin(0); setPrezzoMax(0); setLink(""); setLuogo(""); setNumeroTelefono(0); setEmail(""); setTappa(0); setPrePrenotazione(0); setUploadPreview("/immagine_profilo_default.svg");

    }
    const handleShow = () => setShow(true);
    const [tappe, setTappe] = useState([]);
    const [nome, setNome] = useState("");
    const [id_alloggio, setIdAlloggio] = useState(0);
    const [descrizione, setDescrizione] = useState("");
    const [foto, setFoto] = useState("");
    const [file, setFile] = useState();
    const [prezzo_min, setPrezzoMin] = useState(0);
    const [prezzo_max, setPrezzoMax] = useState(0);
    const [link, setLink] = useState("");
    const [luogo, setLuogo] = useState("");
    const [numero_telefono, setNumeroTelefono] = useState(0);
    const [email, setEmail] = useState("");
    const [tappa, setTappa] = useState(0);
    const [costo_pre_prenotazione, setPrePrenotazione] = useState(0);
    const [id_tappa, setId_tappa] = useState(0);

    const get_alloggi = useEffect(async () => {
        let result = await getAlloggi(token);
        await setAlloggi(result);
        await setAlloggiBackup(result);

        for (let i = 0; i < result.length; i++) {
            if (result[i].stato == 0) {
                await setConferma(true);
                break;
            }
            else {
                await setConferma(false);
            }
        }

        setInterval(async () => {
            let result = await getAlloggi(token);
            await setAlloggi(result);
            await setAlloggiBackup(result);

            for (let i = 0; i < result.length; i++) {
                if (result[i].stato == 0) {
                    await setConferma(true);
                    break;
                }
                else {
                    await setConferma(false);
                }
            }

        }, 500000);


    }, []);



    return (

        <div>

            <Modal show={modalError != ""} onHide={() => { setModalError("") }}>
                <Modal.Body>
                    <div>
                        <img onClick={() => { setModalError("") }} className="btn-close-img" src="/closeBtn.svg" />
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "80px" }}>
                        <img style={{ width: "60px" }} className="btn-close-img1" src="/errorImage.svg" />
                        <div style={{ color: "#FF1400", fontSize: "1.4em", fontWeight: "500", marginTop: "20px", marginBottom: "0px" }}>{modalError}<br></br></div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showDelete} onHide={handleCloseInfo}>
                <Modal.Body>
                    <div>
                        <img onClick={() => { setShowDelete(false) }} className="btn-close-img" src="/closeBtn.svg" />
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "80px" }}>
                        <img style={{ width: "50px" }} className="btn-close-img1" src="/deletePopup.svg" />
                        <p style={{ color: "#FF1400", fontSize: "1.7em", fontWeight: "500", marginTop: "20px", marginBottom: "0px" }} >Disattiva Struttura</p>
                        <p style={{ color: "#2F2F2F", fontSize: "1.1em", fontWeight: "500", marginBottom: "5px" }} >Vuoi disattivare <span style={{ fontWeight: "700" }}>{nome}?</span></p>
                        <button onClick={async () => {
                            await deleteAlloggio(token, id_alloggio, foto, nome);
                            let result_post_delete = await getAlloggi(token);
                            setShowDelete(false)
                            setAlloggi(result_post_delete);
                        }} className="buttonDelete" style={{ marginTop: "15px", fontWeight: "600", height: "55px", backgroundColor: "transparent", border: "1px solid #FF1400", color: "#FF1400", fontSize: "1em", width: "40%", borderRadius: "20px" }}>Disattiva</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Offcanvas className="offcanvas-custom" placement="end" show={showUpdate} onHide={handleCloseUpdate}>
                <Offcanvas.Header >
                    <img onClick={handleCloseUpdate} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Strutture - Modifica</p>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                        <h1 style={{ fontSize: "1.3em", fontWeight: "700", marginBottom: "0", marginTop: "30px" }}>Modifica</h1>
                        <h2 style={{ fontSize: "1.6em", fontWeight: "300" }}>{nome}</h2>
                        <div>
                            <div>
                                <div>

                                    <div style={{ marginTop: "40px" }} className="  mb-2">

                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label style={{ marginBottom: "20px" }} data-error="wrong" data-success="right" for="orangeForm-name">Cambia Immagine</label>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ height: "100px", width: "100px", display: "inline-block", backgroundImage: `url(${(foto != "" && foto != null && upload_preview == '/immagine_profilo_default.svg') ? foto : upload_preview})`, backgroundSize: "cover", borderRadius: "100%", backgroundPosition: "center" }}>
                                            </div>
                                            <label htmlFor={`upload-photo-Immagine`} className="file" style={{ width: "70%", position: "relative", left: "20px", bottom: "8px", alignItems: "center", justifyContent: "center" }}>
                                                <span>
                                                    <h6 style={
                                                        { display: "inline-block", marginBottom: "0px" }}>Cambia Immagine</h6>
                                                </span>
                                                <input type="file"
                                                    style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                                                    id={`upload-photo-Immagine`}
                                                    onChange={async (e) => {
                                                        if (e.target.files[0]) {
                                                            await setUploadPreview(URL.createObjectURL(e.target.files[0]))
                                                        }
                                                        await setFile(e.target.files[0]);
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    {button_conferma &&
                                        <button
                                            onClick={async () => {
                                                let resultInsert = await updateAlloggio1(token, id_alloggio, nome, foto);
                                                if (resultInsert.status == "ok") {
                                                    let get_tappe_conferma = await getTappeConferma(token);
                                                    await setAlloggi(get_tappe_conferma);
                                                    await setAlloggiBackup(get_tappe_conferma);
                                                    await handleCloseUpdate();
                                                }
                                            }}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={open}
                                            className="btn-prenotazione btn-prenotazione2" style={{ boxShadow: "0px 3px 6px #00000029", backgroundColor: "#FF9D00", color: "#ffffff", fontWeight: "600", fontSize: "1em", width: "100%", border: "none", borderRadius: "15px", height: "48px", position: "relative", bottom: "30px" }}> Conferma Struttura</button>

                                    }
                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Attivo</label>
                                        <select defaultValue={attivo} onChange={async (e) => {
                                            await setAttivo(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">
                                            <option value={0}>Attivo</option>
                                            <option value={1}>Non Attivo</option>
                                        </select>
                                    </div>
                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Nome Struttura</label>
                                        <input defaultValue={nome} onChange={async (e) => {

                                            await setNome(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Descrizione alloggio</label>
                                        <textarea defaultValue={descrizione} onChange={async (e) => {
                                            await setDescrizione(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>


                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Link</label>
                                        <input defaultValue={link} onChange={async (e) => {
                                            await setLink(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Luogo</label>
                                        <input defaultValue={luogo} onChange={async (e) => {
                                            await setLuogo(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Telefono</label>
                                        <input defaultValue={numero_telefono} onChange={async (e) => {
                                            await setNumeroTelefono(e.target.value);
                                        }} type="tel" pattern="[0-9]*" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Email</label>
                                        <input defaultValue={email} onChange={async (e) => {
                                            await setEmail(e.target.value);
                                        }} type="email" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Tappa</label>
                                        <select defaultValue={id_tappa} onChange={async (e) => {
                                            await setId_tappa(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">

                                            <option value="gergerge">Seleziona una tappa</option>
                                            {tappe.map((tappa) => {
                                                return tappa.nome_en != null &&
                                                    <option key={tappa.id} value={tappa.id} >{tappa.nome_en}</option>
                                            }
                                            )}
                                        </select>

                                    </div>


                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Orario apertura</label>
                                        <input defaultValue={orario_apertura} onChange={async (e) => {
                                            await setOrarioApertura(e.target.value);
                                        }} type="time" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Orario chiusura</label>
                                        <input defaultValue={orario_chiusura} onChange={async (e) => {
                                            await setOrarioChiusura(e.target.value);
                                        }} type="time" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    {
                                /*
                                   <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Tappa associata</label>
                                        <select defaultValue="Seleziona una tappa associata" onChange={async (e) => {
                                            await setTappa(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">

                                            <option value="gergerge">Seleziona una tappa</option>
                                            {tappe.map((tappa) =>
                                                <option key={tappa.id} value={tappa.id} >{tappa.nome_en}</option>
                                            )}
                                        </select>
                                    </div>
                                            */}
                                </div>
                                <div className="">
                                    {loader && <div style={{ textAlign: "center", paddingTop: "20px" }}><img src="/loader.gif" style={{ borderRadius: "100%" }} /></div>}
                                    {!loader &&
                                        <button onClick={async () => {
                                            await setLoader(true)
                                            let check_campi = await checkCampi(nome, descrizione, file, link, luogo, numero_telefono, email, id_tappa, upload_preview, foto, orario_apertura, orario_chiusura);
                                            if (check_campi == true) {
                                                await setModalError("");

                                                let resultInsert = await updateAlloggio(token, id_alloggio, nome, file, descrizione, prezzo_min, prezzo_max, link, luogo, numero_telefono, email, costo_pre_prenotazione, id_tappa, foto, attivo, orario_apertura, orario_chiusura);
                                                if (resultInsert.status == "ok") {
                                                    handleCloseUpdate();
                                                    let result_post_update = await getAlloggi(token);
                                                    setAlloggi(result_post_update);
                                                    setAlloggiBackup(result_post_update);
                                                }

                                                await setLoader(false)
                                                let result = await getAlloggi(token);
                                                await setAlloggi(result);
                                                await setAlloggiBackup(result);

                                                for (let i = 0; i < result.length; i++) {
                                                    if (result[i].stato == 0) {
                                                        await setConferma(true);
                                                        break;
                                                    }
                                                    else {
                                                        await setConferma(false);
                                                    }
                                                }
                                            }

                                            else {
                                                await setLoader(false)
                                                await setModalError(check_campi);
                                            }

                                        }}

                                            className="btn btn-deep-orange close btn-update" data-dismiss="modal" aria-label="Close">Salva</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>





                </Offcanvas.Body>
            </Offcanvas>



            <Offcanvas className="offcanvas-custom" placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Header>

                    <img onClick={handleClose} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Strutture - Aggiungi</p>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                        <div>
                            <div>
                                <div>
                                    <h1 style={{ fontSize: "1.3em", fontWeight: "700", marginBottom: "40px", marginTop: "30px" }}>Aggiungi Struttura</h1>
                                    <div style={{ marginTop: "40px" }} className="  mb-2">

                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label style={{ marginBottom: "20px" }} data-error="wrong" data-success="right" for="orangeForm-name">Aggiungi Immagine</label>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ height: "100px", width: "100px", display: "inline-block", backgroundImage: `url(${(upload_preview == '/immagine_profilo_default.svg') ? upload_preview : upload_preview})`, backgroundSize: "cover", borderRadius: "100%", backgroundPosition: "center" }}>
                                            </div>
                                            <label htmlFor={`upload-photo-Immagine`} className="file" style={{ width: "70%", position: "relative", left: "20px", bottom: "8px", alignItems: "center", justifyContent: "center" }}>
                                                <span>
                                                    <h6 style={
                                                        { display: "inline-block", marginBottom: "0px" }}>Cambia Immagine</h6>
                                                </span>
                                                <input type="file"
                                                    style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                                                    id={`upload-photo-Immagine`}
                                                    onChange={async (e) => {
                                                        if (e.target.files[0]) {
                                                            await setUploadPreview(URL.createObjectURL(e.target.files[0]))
                                                        }
                                                        await setFile(e.target.files[0]);
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Attivo</label>
                                        <select defaultValue={attivo} onChange={async (e) => {
                                            await setAttivo(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">
                                            <option value={0}>Attivo</option>
                                            <option value={1}>Non Attivo</option>
                                        </select>
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Nome Struttura</label>
                                        <input onChange={async (e) => {
                                            await setNome(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Descrizione Struttura</label>
                                        <textarea onChange={async (e) => {
                                            await setDescrizione(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>


                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Link</label>
                                        <input onChange={async (e) => {
                                            await setLink(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Luogo</label>
                                        <input onChange={async (e) => {
                                            await setLuogo(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Telefono</label>
                                        <input onChange={async (e) => {
                                            await setNumeroTelefono(e.target.value);
                                        }} type="tel" pattern="[0-9]*" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Email</label>
                                        <input onChange={async (e) => {
                                            await setEmail(e.target.value);
                                        }} type="email" id="orangeForm-name" className="form-control validate" />
                                    </div>


                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Tappa</label>
                                        <select defaultValue="Seleziona una tappa associata" onChange={async (e) => {
                                            await setTappa(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">

                                            <option value="gergerge">Seleziona una tappa</option>
                                            {tappe.map((tappa) => {
                                                return tappa.nome_en != null &&
                                                    <option key={tappa.id} value={tappa.id} >{tappa.nome_en}</option>

                                            })}
                                        </select>

                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Orario apertura</label>
                                        <input onChange={async (e) => {
                                            await setOrarioApertura(e.target.value);
                                        }} type="time" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Orario chiusura</label>
                                        <input onChange={async (e) => {
                                            await setOrarioChiusura(e.target.value);
                                        }} type="time" id="orangeForm-name" className="form-control validate" />
                                    </div>


                                </div>
                                <div className="modal-footer d-flex justify-content-center">
                                    {loader && <div style={{ textAlign: "center", paddingTop: "20px" }}><img src="/loader.gif" style={{ borderRadius: "100%" }} /></div>}

                                    {
                                        !loader &&
                                        <button onClick={async () => {
                                            await setLoader(true)
                                            let check_campi = await checkCampi(nome, descrizione, file, link, luogo, numero_telefono, email, tappa, upload_preview, foto, orario_apertura, orario_chiusura);
                                            if (check_campi == true) {
                                                await setModalError("");
                                                let resultInsert = await insertAlloggio(token, id_alloggio, nome, descrizione, file, prezzo_min, prezzo_max, link, luogo, numero_telefono, email, costo_pre_prenotazione, tappa, attivo, orario_apertura, orario_chiusura);
                                                if (resultInsert.status == "ok") {
                                                    handleClose();
                                                    let result_post_delete = await getAlloggi(token);
                                                    setAlloggi(result_post_delete);
                                                    setAlloggiBackup(result_post_delete);

                                                }
                                                await setLoader(false)
                                                window.location.reload();

                                            }

                                            else {
                                                await setLoader(false)

                                                await setModalError(check_campi);
                                            }

                                        }}

                                            className="btn btn-deep-orange close btn-update" data-dismiss="modal" aria-label="Close">Aggiungi</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas className="offcanvas-custom" placement="end" show={showInfo} onHide={handleCloseInfo}>
                <Offcanvas.Header >
                    <img onClick={handleCloseInfo} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Strutture - Info</p>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <div>
                            <div>
                                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                    <div className="div-image" style={{ backgroundImage: `url("${foto}")`, display: "inline-block" }}>

                                    </div>
                                    <div style={{ display: "inline-block", position: "relative", bottom: "50px", left: "30px" }}>
                                        <img onClick={async () => {
                                            setShowInfo(false); setShowUpdate(true);
                                            let get_tappe = await getTappe();
                                            await setTappe(get_tappe);
                                        }} style={{ marginBottom: "13px", cursor: "pointer" }} src="/updateTable.svg" /><br></br>
                                        <img
                                            onClick={() => {
                                                setShowDelete(true);
                                                setShowInfo(false)
                                            }}
                                            style={{ cursor: "pointer" }} src="/deleteTable.svg" />
                                    </div>
                                    <div className=" mb-2">
                                        <p className="nome">{nome}</p>
                                    </div>

                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Attivo</b>
                                        <p style={{ color: "#A9DE5B" }}>{attivo == 0 && "Attivo"}</p>
                                        <p style={{ color: "red", position: "relative", bottom: "10px" }}>{attivo == 1 && "Non Attivo"}</p>
                                    </div>
                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Id Struttura</b>
                                        <p>{id_alloggio}</p>
                                    </div>
                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>id Tappa</b>
                                        <p>{id_tappa}</p>
                                    </div>

                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Località</b>
                                        <p>{luogo}</p>
                                    </div>


                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Email</b>
                                        <p>{email}</p>
                                    </div>


                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Link</b>
                                        <p style={{ wordBreak: "break-all" }}>{link}</p>
                                    </div>

                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Luogo</b>
                                        <p>{luogo}</p>
                                    </div>

                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Telefono</b>
                                        <p>{numero_telefono}</p>
                                    </div>
                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Nome referente</b>
                                        <p>{nome_referente}</p>
                                    </div>
                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Orario apertura</b>
                                        <p>{orario_apertura}</p>
                                    </div>

                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Orario chiusura</b>
                                        <p>{orario_chiusura}</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <div style={{ position: "absolute", top: "0", paddingTop: "65px", width: "100%", backgroundColor: "#F4F4F4", zIndex: "1000" }}>
                <h1 className="title-sezione"><img src="/struttureIcon.svg" style={{ position: "relative", bottom: "3px", width: "23px", marginRight: "12px" }} />Strutture</h1>
                <div style={{ marginTop: "40px", width: "75%", position: "relative", paddingBottom: "25px" }}>
                    <SearchForm nome="Cerca" search={search} />
                    <div style={{ float: "right", position: "absolute", right: "0px", top: "0" }}>
                        {!open &&

                            <button
                                variant="primary"
                                onClick={async () => {
                                    let get_tappe_conferma = await getTappeConferma(token);
                                    await setOpen(true)

                                    //order get_tappe_conferma array from last to first
                                    let get_tappe_conferma_ordered = get_tappe_conferma.sort(function (a, b) {
                                        return b.id - a.id;
                                    });

                                    await setAlloggi(get_tappe_conferma_ordered);
                                    await setAlloggiBackup(get_tappe_conferma_ordered);
                                }} className="btn btn-default btn-rounded mb-4 btn-prenotazione btn-prenotazione2 " data-toggle="modal" data-target="#modalRegisterForm"
                                style={{ backgroundColor: "#FF9D00", color: "#ffffff", fontWeight: "700", border: "none", boxShadow: "0px 3px 6px #00000029", borderRadius: "20px", height: "50px", width: "232px", marginRight: "15px", fontSize: "0.9em" }}>{conferma && <div style={{ position: "absolute", width: "10px", height: "10px", bottom: "72%", backgroundColor: "red", borderRadius: "100%" }}></div>}Strutture da confermare</button>
                        }
                        {open &&
                            <button
                                variant="primary"
                                onClick={async () => {
                                    let result = await getAlloggi(token);

                                    await setOpen(false)
                                    await setAlloggi(result);
                                    await setAlloggiBackup(result);

                                    for (let i = 0; i < result.length; i++) {
                                        if (result[i].stato == 0) {
                                            await setConferma(true);
                                            break;
                                        }
                                        else {
                                            await setConferma(false);
                                        }
                                    }

                                }} className="btn btn-default btn-rounded mb-4 btn-prenotazione btn-prenotazione1 " data-toggle="modal" data-target="#modalRegisterForm"
                                style={{ backgroundColor: "#FF9D00", color: "#ffffff", fontWeight: "700", border: "none", boxShadow: "0px 3px 6px #00000029", borderRadius: "20px", height: "50px", width: "232px", marginRight: "15px", fontSize: "0.9em" }}>Tutte le Strutture</button>
                        }
                        <button
                            variant="primary"
                            onClick={async () => {
                                handleShow();
                                let get_tappe = await getTappe();
                                await setTappe(get_tappe);
                            }} className="btn btn-default btn-rounded mb-4 aggiungi-struttura " data-toggle="modal" data-target="#modalRegisterForm"
                            style={{ border: "none", boxShadow: "0px 3px 6px #00000029", borderRadius: "20px", height: "50px", width: "280px", fontSize: "0.9em" }}>Aggiungi Struttura</button>
                    </div>
                </div>
            </div>
            <div className="alloggi overflow-responsive" style={{ marginTop: "200px", paddingBottom: "60px", borderRadius: "25px", position: "relative", bottom: "70px" }}>



                <table className="table" >
                    <thead>
                        <tr>
                            <th style={{ fontWeight: "700" }} scope="col">ID Struttura</th>
                            <th scope="col">Attivo</th>
                            <th scope="col">Struttura</th>
                            <th scope="col">Località</th>
                            <th scope="col">Link</th>
                            <th scope="col">Email</th>
                            {!open && <th scope="col">Telefono</th>}

                        </tr>
                    </thead>
                    {alloggi.length > 0 &&
                        <tbody>

                            {alloggi?.map((alloggio) =>
                                <tr className="tr-white" key={alloggio.id}>
                                    <th scope="row">{alloggio.id}</th>
                                    {alloggio.attivo == 1 && <td style={{ color: "red", fontSize: "0.8em", fontWeight: "700", marginBottom: "0" }}>Non Attivo</td>}
                                    {alloggio.attivo == 0 && <td style={{ color: "#A9DE5B", fontSize: "0.8em", fontWeight: "700", marginBottom: "0" }}>Attivo</td>}
                                    <td style={{ fontWeight: "600" }}>{alloggio?.nome_en?.substring(0, 11)}..</td>
                                    <td>{alloggio.luogo_en}</td>
                                    <td>{alloggio?.link?.substring(0, 11)}..</td>
                                    <td>{alloggio.email}</td>
                                    {alloggio.stato == 1 && <td>{alloggio.numero_telefono}</td>}

                                    {alloggio.stato == 0 && <button
                                        onClick={async () => {
                                            await setNome(alloggio.nome_en);
                                            await setDescrizione(alloggio.descrizione_en);
                                            await setFoto(alloggio.foto_alloggio);
                                            await setLuogo(alloggio.luogo_en);
                                            await setNumeroTelefono(alloggio.numero_telefono);
                                            await setEmail(alloggio.email);
                                            await setPrePrenotazione(alloggio.costo_pre_prenotazione);
                                            await setPrezzoMin(alloggio.prezzo_min);
                                            await setPrezzoMax(alloggio.prezzo_max);
                                            await setLink(alloggio.link);
                                            await setAttivo(alloggio.attivo);
                                            await setIdAlloggio(alloggio.id);
                                            await setId_tappa(alloggio.id_tappa);
                                            await setButtonConferma(true);
                                            let get_tappe = await getTappe();
                                            await setTappe(get_tappe);
                                            handleShowUpdate();
                                        }}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={open}
                                        className="btn-prenotazione btn-prenotazione2" style={{ boxShadow: "0px 3px 6px #00000029", backgroundColor: "#FF9D00", color: "#ffffff", fontWeight: "600", fontSize: "1em", width: "90%", border: "none", borderRadius: "15px", height: "48px", position: "relative", top: "9px" }}> Conferma Struttura</button>
                                    }
                                    <td style={{ width: "150px" }}>
                                        <button onClick={async () => {
                                            await setIdAlloggio(alloggio.id);
                                            await setNome(alloggio.nome_en);
                                            await setDescrizione(alloggio.descrizione_en);
                                            await setFoto(alloggio.foto_alloggio);
                                            await setLuogo(alloggio.luogo_en);
                                            await setNumeroTelefono(alloggio.numero_telefono);
                                            await setEmail(alloggio.email);
                                            await setPrePrenotazione(alloggio.costo_pre_prenotazione);
                                            await setPrezzoMin(alloggio.prezzo_min);
                                            await setPrezzoMax(alloggio.prezzo_max);
                                            await setLink(alloggio.link);
                                            await setAttivo(alloggio.attivo);
                                            await setId_tappa(alloggio.id_tappa);
                                            await setOrarioApertura(alloggio.orario_apertura);
                                            await setOrarioChiusura(alloggio.orario_chiusura);
                                            await setNomeReferente(alloggio.nome_utente);

                                            handleShowInfo();
                                        }} style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }}><img style={{ width: "25px", position: "relative", bottom: "3px" }} src="/infoTable.svg" /></button>
                                        <button onClick={async () => {

                                            await setNome(alloggio.nome_en);
                                            await setDescrizione(alloggio.descrizione_en);
                                            await setFoto(alloggio.foto_alloggio);
                                            await setLuogo(alloggio.luogo_en);
                                            await setNumeroTelefono(alloggio.numero_telefono);
                                            await setEmail(alloggio.email);
                                            await setPrePrenotazione(alloggio.costo_pre_prenotazione);
                                            await setPrezzoMin(alloggio.prezzo_min);
                                            await setPrezzoMax(alloggio.prezzo_max);
                                            await setLink(alloggio.link);
                                            await setAttivo(alloggio.attivo);
                                            await setIdAlloggio(alloggio.id);

                                            await setOrarioApertura(alloggio.orario_apertura);
                                            await setOrarioChiusura(alloggio.orario_chiusura);
                                            await setNomeReferente(alloggio.nome_en_utente);
                                            await setId_tappa(alloggio.id_tappa);
                                            let get_tappe = await getTappe();
                                            await setTappe(get_tappe);
                                            handleShowUpdate();
                                        }} style={{ backgroundColor: "transparent", border: "none", boxShadow: "none" }}><img style={{ width: "25px", position: "relative", bottom: "3px" }} src="/updateTable.svg" /></button>
                                        <button onClick={async () => {

                                            await setNome(alloggio.nome_en);
                                            await setDescrizione(alloggio.descrizione_en);
                                            await setFoto(alloggio.foto_alloggio);
                                            await setLuogo(alloggio.luogo_en);
                                            await setNumeroTelefono(alloggio.numero_telefono);
                                            await setEmail(alloggio.email);
                                            await setPrePrenotazione(alloggio.costo_pre_prenotazione);
                                            await setPrezzoMin(alloggio.prezzo_min);
                                            await setPrezzoMax(alloggio.prezzo_max);
                                            await setLink(alloggio.link);
                                            await setIdAlloggio(alloggio.id);

                                            await setOrarioApertura(alloggio.orario_apertura);
                                            await setOrarioChiusura(alloggio.orario_chiusura);
                                            await setNomeReferente(alloggio.nome_utente)
                                            await setAttivo(alloggio.attivo);
                                            await setId_tappa(alloggio.id_tappa);
                                            let get_tappe = await getTappe();
                                            await setTappe(get_tappe);
                                            setShowDelete(true);

                                        }} style={{ backgroundColor: "transparent", border: "none", boxShadow: "none" }}><img style={{ width: "25px", position: "relative", bottom: "3px" }} src="/deleteTable.svg" /></button></td>
                                </tr>
                            )}

                        </tbody>
                    }
                </table>


            </div>
        </div>
    );
};

export default Alloggi;