import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../Components/CheckoutForm"
import useToken from "../Components/useToken";
import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import SearchForm from "../Components/SearchForm"
import axios from 'axios';
import { data } from "jquery";
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';



async function getAlloggi(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users?attivo=0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())


    result = result.reverse();


    return result;
}

async function getTappeConferma(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/users?attivo=1`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())
    return result;
}

async function getTappe(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/cammini`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())
    return result;

}
async function deletecammino(token, id_cammino, foto) {
    const formData = new FormData()
    formData.append('body', JSON.stringify({
        attivo: 1,
        immagine_profilo: foto
    }))

    let result = await axios.put(`${process.env.REACT_APP_API_KEY}/admin/users/${id_cammino}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })


}
async function checkCampi(nome, cognome, via, cap, citta, nazione, email, file, admin, telefono, data_di_nascita, titolo, upload_preview, foto) {
    var re = /\S+@\S+\.\S+/;

    if ((file && file.type != "image/png") && (file && file.type != "image/jpg") && (file && file.type != "image/webp")) {
        return "Formato Immagine non supportato.";
    }
    if (file && file.size > 100000) {
        return "Immagine troppo pesante. Inserire immagine più leggera.";
    }

    if (!nome || nome == "") {
        return "Errore. Inserire nome";
    }
    if (!cognome || cognome == "") {
        return "Errore. Inserire descrizione";
    }

    if (!email || email == "" || !re.test(email)) {
        return "Errore. Inserire un'email valida";
    }

    if (!via || via == "") {
        return "Errore. Inserire via.";
    }

    if (!cap || via == 0) {
        return "Errore. Inserire cap.";
    }

    if (!citta || citta == "") {
        return "Errore. Inserire città.";
    }

    if (!nazione || nazione == "") {
        return "Errore. Inserire nazione.";
    }

    if (!telefono || telefono.length < 9) {
        return "Errore. Inserire telefono correttamente.";
    }
    if (!data_di_nascita) {
        return "Errore. Inserire data di nascita.";
    }
    if (!titolo || titolo == "") {
        return "Errore. Inserire titolo.";
    }


    else return true;
}
async function insertcammino(token, id, nome, cognome, via, cap, citta, nazione, email, file, password, admin, telefono, data_di_nascita, titolo, attivo) {
    const formData = new FormData()
    formData.append('profileImg', file)
    formData.append('body', JSON.stringify({
        nome: nome,
        cognome: cognome,
        via: via,
        cap: cap,
        citta: citta,
        nazione: nazione,
        email: email,
        password: password,
        admin: admin,
        telefono: telefono,
        data_di_nascita: data_di_nascita,
        titolo: titolo,
        attivo: attivo
    }))
    let result = await axios.post(`${process.env.REACT_APP_API_KEY}/admin/users/`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })
    return result.data;
}
async function updatecammino(token, id, nome, cognome, via, cap, citta, nazione, email, file, password, admin, telefono, data_di_nascita, titolo, foto, attivo) {

    const formData = new FormData()
    if (file) {
        formData.append('profileImg', file)
        formData.append('body', JSON.stringify({
            nome: nome,
            cognome: cognome,
            via: via,
            cap: cap,
            citta: citta,
            nazione: nazione,
            email: email,
            password: password,
            admin: admin,
            telefono: telefono,
            data_di_nascita: data_di_nascita,
            titolo: titolo,
            attivo: attivo
        }))
    }
    else {
        formData.append('body', JSON.stringify({
            nome: nome,
            cognome: cognome,
            via: via,
            cap: cap,
            citta: citta,
            nazione: nazione,
            email: email,
            password: password,
            admin: admin,
            telefono: telefono,
            data_di_nascita: data_di_nascita,
            titolo: titolo,
            immagine_profilo: foto,
            attivo: attivo
        }))
    }

    let result = await axios.put(`${process.env.REACT_APP_API_KEY}/admin/users/${id}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })
    return result;
}


const Utenti = () => {
    const search = (keyword) => {
        let alloggi = alloggiBackup.filter((el) => el.nome.toUpperCase().includes(keyword.toUpperCase()))
        if (alloggi?.length == 0) {
            alloggi = alloggiBackup.filter((el) => el.cognome.toUpperCase().includes(keyword.toUpperCase()))
        }
        if (alloggi?.length == 0) {
            alloggi = alloggiBackup.filter((el) => el.email.toUpperCase().includes(keyword.toUpperCase()))
        }

        setAlloggi(alloggi)
    }


    const { token, setToken } = useToken();
    const [alloggi, setAlloggi] = useState([]);
    const [alloggiBackup, setAlloggiBackup] = useState([]);
    const [show, setShow] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [open, setOpen] = useState(false);
    const [conferma, setConferma] = useState(false);


    const [showUpdate, setShowUpdate] = useState(false);
    const [modalError, setModalError] = useState("");
    const [showDelete, setShowDelete] = useState(false);


    const handleShowUpdate = () => setShowUpdate(true);
    const handleCloseUpdate = async () => {
        setShowUpdate(false);
        setUploadPreview("/immagine_profilo_default.svg");
        await setIdcammino();
        await setNome("");
        await setCognome("");
        await setFoto();
        await setPassword("");
        await setAdmin();
        await setEmail("");
        await setTelefono();
        await setData();
        await setCitta("");
        await setCap();
        await setVia("");
        await setNazione("");
        await setTitolo("");
        await setAttivo(0);
    }
    const handleShowInfo = () => setShowInfo(true);
    const handleCloseInfo = async () => {
        setShowInfo(false);
        await setIdcammino();
        await setNome("");
        await setAttivo(0);
        await setCognome("");
        await setFoto();
        await setPassword("");
        await setAdmin();
        await setEmail("");
        await setTelefono();
        await setData();
        await setCitta("");
        await setCap();
        await setVia("");
        await setNazione("");
        await setTitolo("");
    }
    const handleClose = () => {
        setShow(false); setModalError(""); setTappe([]); setNome(""); setDescrizione(""); setFoto(""); setTelefono(0); setEmail(""); setTappa(0); setUploadPreview("/immagine_profilo_default.svg");
    }
    const handleShow = () => setShow(true);
    const [tappe, setTappe] = useState([]);
    const [nome, setNome] = useState("");
    const [upload_preview, setUploadPreview] = useState('/immagine_profilo_default.svg');
    const [attivo, setAttivo] = useState(0);
    const [loader, setLoader] = useState(false);

    const [id_cammino, setIdcammino] = useState(0);
    const [descrizione, setDescrizione] = useState("");
    const [foto, setFoto] = useState("");
    const [file, setFile] = useState();
    const [prezzo_min, setPrezzoMin] = useState(0);
    const [prezzo_max, setPrezzoMax] = useState(0);
    const [telefono, setTelefono] = useState(0);
    const [email, setEmail] = useState("");
    const [tappa, setTappa] = useState(0);
    const [cognome, setCognome] = useState("");
    const [via, setVia] = useState("");
    const [admin, setAdmin] = useState(0);
    const [password, setPassword] = useState("");
    const [cap, setCap] = useState(0);
    const [citta, setCitta] = useState("");
    const [nazione, setNazione] = useState("");
    const [data_di_nascita, setData] = useState();
    const [titolo, setTitolo] = useState();
    const get_alloggi = useEffect(async () => {


        let result = await getAlloggi(token);
        setAlloggi(result);
        setAlloggiBackup(result);

    }, []);


    return (
        <div>

            <Modal show={modalError != ""} onHide={() => { setModalError("") }}>
                <Modal.Body>
                    <div>
                        <img onClick={() => { setModalError("") }} className="btn-close-img" src="/closeBtn.svg" />
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "80px" }}>
                        <img style={{ width: "60px" }} className="btn-close-img1" src="/errorImage.svg" />
                        <div style={{ color: "#FF1400", fontSize: "1.4em", fontWeight: "500", marginTop: "20px", marginBottom: "0px" }}>{modalError}<br></br></div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showDelete} onHide={handleCloseInfo}>
                <Modal.Body>
                    <div>
                        <img onClick={() => { setShowDelete(false) }} className="btn-close-img" src="/closeBtn.svg" />
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "80px" }}>
                        <img style={{ width: "50px" }} className="btn-close-img1" src="/deletePopup.svg" />
                        <p style={{ color: "#FF1400", fontSize: "1.7em", fontWeight: "500", marginTop: "20px", marginBottom: "0px" }} >Disattiva Utente</p>
                        <p style={{ color: "#2F2F2F", fontSize: "1.1em", fontWeight: "500", marginBottom: "5px" }} >Vuoi disattivare <span style={{ fontWeight: "700" }}>{nome}?<br></br>
                        </span></p>
                        <button onClick={async () => {
                            await deletecammino(token, id_cammino, foto);
                            let result_post_delete = await getAlloggi(token);
                            setShowDelete(false)
                            setAlloggi(result_post_delete);
                        }} className="buttonDelete" style={{ marginTop: "15px", fontWeight: "600", height: "55px", backgroundColor: "transparent", border: "1px solid #FF1400", color: "#FF1400", fontSize: "1em", width: "40%", borderRadius: "20px" }}>Disattiva</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Offcanvas className="offcanvas-custom" placement="end" show={showUpdate} onHide={handleCloseUpdate}>
                <Offcanvas.Header >
                    <img onClick={handleCloseUpdate} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Utenti - Modifica</p>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                        <h1 style={{ fontSize: "1.3em", fontWeight: "700", marginBottom: "0", marginTop: "30px" }}>Modifica</h1>
                        <h2 style={{ fontSize: "1.6em", fontWeight: "300" }}>{nome} {cognome}</h2>
                        <div>
                            <div>
                                <div>

                                    <div style={{ marginTop: "40px" }} className="  mb-2">

                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label style={{ marginBottom: "20px" }} data-error="wrong" data-success="right" for="orangeForm-name">Cambia Immagine</label>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ height: "100px", width: "100px", display: "inline-block", backgroundImage: `url(${(foto != "" && foto != null && upload_preview == '/immagine_profilo_default.svg') ? foto : upload_preview})`, backgroundSize: "cover", borderRadius: "100%", backgroundPosition: "center" }}>
                                            </div>
                                            <label htmlFor={`upload-photo-Immagine`} className="file" style={{ width: "70%", position: "relative", left: "20px", bottom: "8px", alignItems: "center", justifyContent: "center" }}>
                                                <span>
                                                    <h6 style={
                                                        { display: "inline-block", marginBottom: "0px" }}>Cambia Immagine</h6>
                                                </span>
                                                <input type="file"
                                                    style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                                                    id={`upload-photo-Immagine`}
                                                    onChange={async (e) => {
                                                        if (e.target.files[0]) {
                                                            await setUploadPreview(URL.createObjectURL(e.target.files[0]))
                                                        }
                                                        await setFile(e.target.files[0]);
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>



                                    {
                                /*
                                   <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Tappa associata</label>
                                        <select defaultValue="Seleziona una tappa associata" onChange={async (e) => {
                                            await setTappa(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">

                                            <option value="gergerge">Seleziona una tappa</option>
                                            {tappe.map((tappa) =>
                                                <option key={tappa.id} value={tappa.id} >{tappa.nome}</option>
                                            )}
                                        </select>
                                    </div>
                                            */}
                                </div>
                                <div>
                                    <div>
                                        <div>
                                            <div>

                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Attivo</label>
                                                    <select defaultValue={attivo} onChange={async (e) => {
                                                        await setAttivo(e.target.value);
                                                    }} type="select" id="orangeForm-name" className="form-control validate">
                                                        <option value={0}>Attivo</option>
                                                        <option value={1}>Non Attivo</option>
                                                    </select>
                                                </div>

                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Nome</label>
                                                    <input defaultValue={nome} onChange={async (e) => {
                                                        await setNome(e.target.value);
                                                    }} type="text" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Cognome</label>
                                                    <input defaultValue={cognome} onChange={async (e) => {
                                                        await setCognome(e.target.value);
                                                    }} type="text" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Email</label>
                                                    <input defaultValue={email} onChange={async (e) => {
                                                        await setEmail(e.target.value);
                                                    }} type="email" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Ruolo</label>
                                                    <select defaultValue={admin} onChange={async (e) => {
                                                        await setAdmin(e.target.value);
                                                    }} type="select" id="orangeForm-name" className="form-control validate">
                                                        <option value={0}>Cliente</option>
                                                        <option value={1}>Admin</option>
                                                    </select>
                                                </div>
                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Via</label>
                                                    <input defaultValue={via} onChange={async (e) => {
                                                        await setVia(e.target.value);
                                                    }} type="text" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Cap</label>
                                                    <input defaultValue={cap} onChange={async (e) => {
                                                        await setCap(e.target.value);
                                                    }} type="number" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Città</label>
                                                    <input defaultValue={citta} onChange={async (e) => {
                                                        await setCitta(e.target.value);
                                                    }} type="text" id="orangeForm-name" className="form-control validate" />
                                                </div>

                                                <div className=" mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Nazione</label>
                                                    <input defaultValue={nazione} onChange={async (e) => {
                                                        await setNazione(e.target.value);
                                                    }} type="text" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className="  mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Telefono</label>
                                                    <input defaultValue={telefono} onChange={async (e) => {
                                                        await setTelefono(e.target.value);
                                                    }} type="tel" pattern="[0-9]*" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className=" mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Password</label>
                                                    <input defaultValue={password} onChange={async (e) => {
                                                        await setPassword(e.target.value);
                                                    }} type="password" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className=" mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Data di nascita</label>
                                                    <input defaultValue={data_di_nascita} onChange={async (e) => {
                                                        await setData(e.target.value);
                                                    }} type="date" id="orangeForm-name" className="form-control validate" />
                                                </div>
                                                <div className=" mb-2">
                                                    <i className="fas fa-user prefix grey-text"></i>
                                                    <label data-error="wrong" data-success="right" for="orangeForm-name">Titolo</label>
                                                    <select defaultValue={titolo} onChange={async (e) => {
                                                        await setTitolo(e.target.value);
                                                    }
                                                    } type="select" id="orangeForm-name" className="form-control validate">
                                                        <option value="Sr">Sr</option>
                                                        <option value="Sra">Sra</option>
                                                    </select>


                                                </div>
                                            </div>
                                            <div className="">
                                                {loader && <div style={{ textAlign: "center", paddingTop: "20px" }}><img src="/loader.gif" style={{ borderRadius: "100%" }} /></div>}

                                                {!loader && <button onClick={async () => {
                                                    setLoader(true);
                                                    let check_campi = await checkCampi(nome, cognome, via, cap, citta, nazione, email, file, admin, telefono, data_di_nascita, titolo, upload_preview, foto);
                                                    if (check_campi == true) {
                                                        await setModalError("");
                                                        let resultInsert = await updatecammino(token, id_cammino, nome, cognome, via, cap, citta, nazione, email, file, password, admin, telefono, data_di_nascita, titolo, foto, attivo);
                                                        if (resultInsert.statusText == "OK") {
                                                            handleCloseUpdate();
                                                            await setFile()
                                                            await setModalError("")
                                                            let result_post_update = await getAlloggi(token);
                                                            setAlloggi(result_post_update);
                                                            setAlloggiBackup(result_post_update);
                                                            let result = await getAlloggi(token);
                                                            setAlloggi(result);
                                                            setAlloggiBackup(result);
                                                        }
                                                        setLoader(false);
                                                    }
                                                    else {
                                                        setLoader(false);
                                                        await setModalError(check_campi);
                                                    }
                                                }}
                                                    className="btn btn-deep-orange close btn-update" data-dismiss="modal" aria-label="Close">Salva</button>
                                                }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>


            <Offcanvas className="offcanvas-custom" placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Header>

                    <img onClick={handleClose} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Utenti - Aggiungi</p>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                        <div>
                            <div>
                                <div>
                                    <h1 style={{ fontSize: "1.3em", fontWeight: "700", marginBottom: "40px", marginTop: "30px" }}>Aggiungi Utente</h1>
                                    <div style={{ marginTop: "40px" }} className="  mb-2">

                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label style={{ marginBottom: "20px" }} data-error="wrong" data-success="right" for="orangeForm-name">Inserisci Immagine</label>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ height: "100px", width: "100px", display: "inline-block", backgroundImage: `url(${(foto != "" && foto != null && upload_preview == '/immagine_profilo_default.svg') ? foto : upload_preview})`, backgroundSize: "cover", borderRadius: "100%", backgroundPosition: "center" }}>
                                            </div>
                                            <label htmlFor={`upload-photo-Immagine`} className="file" style={{ width: "70%", position: "relative", left: "20px", bottom: "8px", alignItems: "center", justifyContent: "center" }}>
                                                <span>
                                                    <h6 style={
                                                        { display: "inline-block", marginBottom: "0px" }}>Inserisci Immagine</h6>
                                                </span>
                                                <input type="file"
                                                    style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                                                    id={`upload-photo-Immagine`}
                                                    onChange={async (e) => {
                                                        if (e.target.files[0]) {
                                                            await setUploadPreview(URL.createObjectURL(e.target.files[0]))
                                                        }
                                                        await setFile(e.target.files[0]);
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Attivo</label>
                                        <select defaultValue={attivo} onChange={async (e) => {
                                            await setAttivo(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">
                                            <option value={0}>Attivo</option>
                                            <option value={1}>Non Attivo</option>
                                        </select>
                                    </div>

                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Nome utente</label>
                                        <input onChange={async (e) => {
                                            await setNome(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Cognome utente</label>
                                        <input onChange={async (e) => {
                                            await setCognome(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Email utente</label>
                                        <input onChange={async (e) => {
                                            await setEmail(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Ruolo</label>
                                        <select defaultValue={admin} onChange={async (e) => {
                                            await setAdmin(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">
                                            <option value={0}>Cliente</option>
                                            <option value={1}>Admin</option>
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Via</label>
                                        <input onChange={async (e) => {
                                            await setVia(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Cap</label>
                                        <input onChange={async (e) => {
                                            await setCap(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Città</label>
                                        <input onChange={async (e) => {
                                            await setCitta(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Nazione</label>
                                        <input onChange={async (e) => {
                                            await setNazione(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Telefono</label>
                                        <input onChange={async (e) => {
                                            await setTelefono(e.target.value);
                                        }} type="tel" pattern="[0-9]*" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Password</label>
                                        <input onChange={async (e) => {
                                            await setPassword(e.target.value);
                                        }} type="password" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Data di nascita</label>
                                        <input onChange={async (e) => {
                                            await setData(e.target.value);
                                        }} type="date" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Titolo</label>

                                        <select onChange={async (e) => {
                                            await setTitolo(e.target.value);
                                        }
                                        } type="select" id="orangeForm-name" className="form-control validate">
                                            <option value="Sr">Sr</option>
                                            <option value="Sra">Sra</option>
                                        </select>



                                    </div>
                                </div>
                                <div className="">
                                    {loader && <div style={{ textAlign: "center", paddingTop: "20px" }}><img src="/loader.gif" style={{ borderRadius: "100%" }} /></div>}
                                    {!loader &&
                                        <button onClick={async () => {
                                            setLoader(true)
                                            let check_campi = await checkCampi(nome, cognome, via, cap, citta, nazione, email, file, admin, telefono, data_di_nascita, titolo, upload_preview, foto);
                                            if (check_campi == true) {
                                                await setModalError("");
                                                let resultInsert = await insertcammino(token, id_cammino, nome, cognome, via, cap, citta, nazione, email, file, password, admin, telefono, data_di_nascita, titolo, attivo);
                                                if (resultInsert.status == "ok") {
                                                    handleClose();
                                                    await setModalError("")
                                                    let result_post_delete = await getAlloggi(token);
                                                    setAlloggi(result_post_delete);
                                                    setAlloggiBackup(result_post_delete);
                                                }
                                                setLoader(false)
                                            }
                                            else {
                                                setLoader(false)
                                                await setModalError(check_campi);
                                            }
                                        }}
                                            className="btn btn-deep-orange close btn-update" data-dismiss="modal" aria-label="Close">Inserisci Utente</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>


            <Offcanvas className="offcanvas-custom" placement="end" show={showInfo} onHide={handleCloseInfo}>
                <Offcanvas.Header >
                    <img onClick={handleCloseInfo} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Strutture - Info</p>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <div>
                            <div>
                                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                    <div className="div-image1" style={{ backgroundImage: `url(${(foto != "" && foto != null && upload_preview == '/immagine_profilo_default.svg') ? foto : upload_preview})`, display: "inline-block" }}>

                                    </div>
                                    <div style={{ display: "inline-block", position: "relative", bottom: "25px", left: "30px" }}>
                                        <img onClick={() => { setShowInfo(false); setShowUpdate(true) }} style={{ marginBottom: "13px", cursor: "pointer" }} src="/updateTable.svg" /><br></br>
                                        <img
                                            onClick={() => {
                                                setShowDelete(true);
                                                setShowInfo(false)
                                            }}
                                            style={{ cursor: "pointer" }} src="/deleteTable.svg" />
                                    </div>
                                    <div className=" mb-2">
                                        <p className="nome">{nome} {cognome}</p>
                                    </div>

                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Attivo</b>
                                        <p style={{ color: "#A9DE5B" }}>{attivo == 0 && "Attivo"}</p>
                                        <p style={{ color: "red", position: "relative", bottom: "10px" }}>{attivo == 1 && "Non Attivo"}</p>
                                    </div>

                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Email</b>
                                        <p>{email}</p>
                                    </div>
                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Telefono</b>
                                        <p>{telefono}</p>
                                    </div>
                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Data di nascita</b>
                                        <p>{new Date(data_di_nascita).toLocaleDateString()}</p>
                                    </div>
                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Titolo</b>
                                        <p>{titolo}</p>
                                    </div>
                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Indirizzo</b>
                                        <p>{via}, {cap}, {citta}, {nazione}</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>


            <div style={{ position: "absolute", top: "0", paddingTop: "65px", width: "100%", backgroundColor: "#F4F4F4", zIndex: "1000" }}>
                <h1 className="title-sezione"><img src="utentiIcon.svg" style={{ position: "relative", bottom: "3px", width: "23px", marginRight: "12px" }} />Utenti</h1>
                <div style={{ marginTop: "40px", width: "75%", position: "relative", paddingBottom: "25px" }}>
                    <SearchForm nome="Cerca" search={search} />
                    <div style={{ float: "right", position: "absolute", right: "0px", top: "0" }}>
                        {!open &&

                            <button
                                variant="primary"
                                onClick={async () => {
                                    let get_tappe_conferma = await getTappeConferma(token);
                                    await setOpen(true)
                                    await setAlloggi(get_tappe_conferma);
                                    await setAlloggiBackup(get_tappe_conferma);
                                }} className="btn btn-default btn-rounded mb-4 btn-prenotazione btn-prenotazione2 " data-toggle="modal" data-target="#modalRegisterForm"
                                style={{ backgroundColor: "#FF9D00", color: "#ffffff", fontWeight: "700", border: "none", boxShadow: "0px 3px 6px #00000029", borderRadius: "20px", height: "50px", width: "280px", marginRight: "15px", fontSize: "0.9em" }}>{conferma && <div style={{ position: "absolute", width: "10px", height: "10px", bottom: "43px", right: "94%", backgroundColor: "red", borderRadius: "100%" }}></div>}Utenti Disattivi</button>
                        }
                        {open &&
                            <button
                                variant="primary"
                                onClick={async () => {
                                    let result = await getAlloggi(token);
                                    await setOpen(false)
                                    await setAlloggi(result);
                                    await setAlloggiBackup(result);

                                    for (let i = 0; i < result.length; i++) {
                                        if (result[i].stato == 0) {
                                            await setConferma(true);
                                            break;
                                        }
                                        else {
                                            await setConferma(false);
                                        }
                                    }

                                }} className="btn btn-default btn-rounded mb-4 btn-prenotazione btn-prenotazione1 " data-toggle="modal" data-target="#modalRegisterForm"
                                style={{ backgroundColor: "#FF9D00", color: "#ffffff", fontWeight: "700", border: "none", boxShadow: "0px 3px 6px #00000029", borderRadius: "20px", height: "50px", width: "232px", marginRight: "15px", fontSize: "0.9em" }}>Utenti Attivi</button>
                        }

                        <button
                            variant="primary"
                            onClick={async () => {
                                handleShow();
                                let get_tappe = await getTappe();
                                await setTappe(get_tappe);
                            }} className="btn btn-default btn-rounded mb-4 aggiungi-struttura " data-toggle="modal" data-target="#modalRegisterForm"
                            style={{ border: "none", boxShadow: "0px 3px 6px #00000029", borderRadius: "20px", height: "50px", width: "232px", fontSize: "0.9em" }}>Aggiungi Utente</button>
                    </div>
                </div>
            </div>

            <div className="alloggi overflow-responsive" style={{ marginTop: "200px", paddingBottom: "60px", bottom: "30px" }}>
                <table className="table" style={{ width: "1400px" }}>
                    <thead>
                        <tr >
                            <th scope="col">ID Utente</th>
                            <th scope="col">Attivo</th>
                            <th scope="col">Nome</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">Azioni</th>
                        </tr>
                    </thead>
                    <tbody className="body-utenti">
                        {alloggi.map((cammino) => {
                            return <tr className="tr-white" key={cammino.id}>
                                <th scope="row">{cammino.id}</th>
                                {cammino.attivo == 1 && <td style={{ color: "red", fontSize: "0.8em", fontWeight: "700", marginBottom: "0" }}>Non Attivo</td>}
                                {cammino.attivo == 0 && <td style={{ color: "#A9DE5B", fontSize: "0.8em", fontWeight: "700", marginBottom: "0" }}>Attivo</td>}
                                <td>{cammino.cognome} {cammino.nome}</td>
                                <td>{cammino.email}</td>
                                <td>{cammino.telefono}</td>
                                <td style={{ width: "150px" }}><button onClick={async () => {
                                    await setIdcammino(cammino.id);
                                    await setNome(cammino.nome);
                                    await setCognome(cammino.cognome);
                                    await setFoto(cammino.immagine_profilo);
                                    await setPassword(cammino.password);
                                    await setAdmin(cammino.admin);
                                    await setEmail(cammino.email);
                                    await setAttivo(cammino.attivo);
                                    await setTelefono(cammino.telefono);
                                    await setData(cammino.data_di_nascita);
                                    await setCitta(cammino.citta);
                                    await setCap(cammino.cap);
                                    await setVia(cammino.via);
                                    await setNazione(cammino.nazione);
                                    await setTitolo(cammino.titolo);
                                    handleShowInfo();
                                }} style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }}><img style={{ width: "25px", position: "relative", bottom: "3px" }} src="./infoTable.svg" /></button>
                                    <button onClick={async () => {
                                        await setIdcammino(cammino.id);
                                        await setNome(cammino.nome);
                                        await setCognome(cammino.cognome);
                                        await setFoto(cammino.immagine_profilo);
                                        await setPassword(cammino.password);
                                        await setAttivo(cammino.attivo);
                                        await setAdmin(cammino.admin);
                                        await setEmail(cammino.email);
                                        await setTelefono(cammino.telefono);
                                        await setData(cammino.data_di_nascita);
                                        await setCitta(cammino.citta);
                                        await setCap(cammino.cap);
                                        await setVia(cammino.via);
                                        await setNazione(cammino.nazione);
                                        await setTitolo(cammino.titolo);
                                        let get_tappe = await getTappe(token);
                                        await setTappe(get_tappe);
                                        handleShowUpdate();
                                    }} style={{ backgroundColor: "transparent", border: "none", boxShadow: "none" }}><img style={{ width: "25px", position: "relative", bottom: "3px" }} src="./updateTable.svg" /></button>
                                    <button onClick={async () => {
                                        await setIdcammino(cammino.id);
                                        await setNome(cammino.nome);
                                        await setCognome(cammino.cognome);
                                        await setFoto(cammino.immagine_profilo);
                                        await setPassword(cammino.password);
                                        await setAttivo(cammino.attivo);
                                        await setAdmin(cammino.admin);
                                        await setEmail(cammino.email);
                                        await setTelefono(cammino.telefono);
                                        await setData(cammino.data_di_nascita);
                                        await setCitta(cammino.citta);
                                        await setCap(cammino.cap);
                                        await setVia(cammino.via);
                                        await setNazione(cammino.nazione);
                                        await setTitolo(cammino.titolo);
                                        let get_tappe = await getTappe(token);
                                        await setTappe(get_tappe);
                                        setShowDelete(true);
                                    }} style={{ backgroundColor: "transparent", border: "none", boxShadow: "none" }}><img style={{ width: "25px", position: "relative", bottom: "3px" }} src="./deleteTable.svg" /></button></td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default Utenti;