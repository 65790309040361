import { Modal } from "react-bootstrap";

export default function ModalDeletePrenotazione(
    { deletecammino,
        showDelete,
        setShowDelete,
        handleCloseInfo,
        id_cammino,
        token,
        getAlloggi,
        setAlloggi,
        setOpen
    }
) {
    return <Modal show={showDelete} onHide={handleCloseInfo}>
        <Modal.Body>
            <div>
                <img
                    onClick={() => {
                        setShowDelete(false);
                    }}
                    className="btn-close-img"
                    src="/closeBtn.svg"
                    alt="close"
                />
            </div>
            <div
                style={{
                    textAlign: "center",
                    paddingTop: "50px",
                    paddingBottom: "80px",
                }}
            >
                <img
                    style={{ width: "50px" }}
                    className="btn-close-img1"
                    src="/deletePopup.svg"
                    alt="delete"
                />
                <p
                    style={{
                        color: "#FF1400",
                        fontSize: "1.7em",
                        fontWeight: "500",
                        marginTop: "20px",
                        marginBottom: "0px",
                    }}
                >
                    Elimina Prenotazione
                </p>
                <p
                    style={{
                        color: "#2F2F2F",
                        fontSize: "1.1em",
                        fontWeight: "500",
                        marginBottom: "5px",
                    }}
                >
                    Vuoi eliminare prenotazione con id{" "}
                    <span style={{ fontWeight: "700" }}>{id_cammino}?</span>
                </p>
                <button
                    onClick={async () => {
                        await deletecammino(token, id_cammino);
                        let result_post_delete = await getAlloggi(token);
                        setShowDelete(false);
                        setOpen();
                        setAlloggi(result_post_delete);
                    }}
                    className="buttonDelete"
                    style={{
                        marginTop: "15px",
                        fontWeight: "600",
                        height: "55px",
                        backgroundColor: "transparent",
                        border: "1px solid #FF1400",
                        color: "#FF1400",
                        fontSize: "1em",
                        width: "40%",
                        borderRadius: "20px",
                    }}
                >
                    Elimina
                </button>
            </div>
        </Modal.Body>
    </Modal>
}