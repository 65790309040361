import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../Components/CheckoutForm"
import useToken from "../../Components/useToken";
import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import SearchForm from "../../Components/SearchForm"
import axios from 'axios';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import Gallery from "../../Components/Gallery"


async function getAlloggi(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/tappe`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())
    return result;
}

async function getRegioni(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/regione`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())
    return result;
}


async function getTappe(token) {
    let result = await fetch(`${process.env.REACT_APP_API_KEY}/admin/cammini`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(data => data.json())
    return result;

}

async function deletecammino(token, id_cammino, foto) {
    const formData = new FormData()

    formData.append('body', JSON.stringify({
        attivo: 1,
        foto_tappa: foto
    }))

    let result = await axios.put(`${process.env.REACT_APP_API_KEY}/admin/tappe/${id_cammino}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })
}

async function checkCampi(nome, file, descrizione, costo, luogo, distanza, ordine, tempo, lat, lng, livello, tappa, upload_preview, foto) {


    if ((file && file.type != "image/png") && (file && file.type != "image/jpeg") && (file && file.type != "image/jpg") && (file && file.type != "image/webp")) {
        return "Formato Immagine non supportato.";
    }
    if (file && file.size > 10000000) {
        return "Immagine troppo pesante. Inserire immagine più leggera.";
    }

    if (upload_preview == "/immagine_profilo_default.svg" && !foto) {
        return "Errore. Inserire Immagine";
    }
    if (!nome || nome == "") {
        return "Errore. Inserire nome";
    }

    if (!descrizione || descrizione == "") {
        return "Errore. Inserire descrizione";
    }


    if (!luogo || luogo == "") {
        return "Errore. Inserire un luogo";
    }


    if ((!lat || lat == 0) || (!lng || lng == 0)) {
        return "Errore. Inserire Latitudine e Logintudine correttamente.";
    }

    if (!tappa) {
        return "Errore. Inserire cammino associato alla tappa";
    }



    else return true;
}

async function insertcammino(token, id_cammino, nome, file, descrizione, costo, luogo, distanza, ordine, tempo, lat, lng, livello, tappa, attivo) {

    console.log(nome, descrizione, luogo, distanza, ordine, tempo, lat, lng, livello, tappa, attivo)

    const formData = new FormData()
    formData.append('profileImg', file)
    formData.append('body', JSON.stringify({
        nome_en: nome,
        descrizione_en: descrizione,
        luogo_en: luogo,
        distanza_prossima: distanza,
        Ordine: ordine,
        tempo_prossima: tempo,
        lat: lat,
        lng: lng,
        costo: costo,
        livello: livello,
        id_cammino: tappa,
        attivo: attivo
    }))
    let result = await axios.post(`${process.env.REACT_APP_API_KEY}/admin/tappe`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })
    return result.data;
}

async function updatecammino(token, id_cammino, nome, file2, descrizione, costo, luogo, distanza, ordine, tempo, lat, lng, livello, tappa, foto, attivo) {
    const formData = new FormData()
    if (file2) {
        formData.append('profileImg', file2)
        formData.append('body', JSON.stringify({
            nome_en: nome,
            descrizione_en: descrizione,
            luogo_en: luogo,
            distanza_prossima: distanza,
            Ordine: ordine,
            tempo_prossima: tempo,
            lat: lat,
            id_cammino: tappa,
            lng: lng,
            costo: costo,
            livello: livello,
            attivo: attivo
        }))
    }
    else {
        formData.append('body', JSON.stringify({
            nome_en: nome,
            descrizione_en: descrizione,
            luogo_en: luogo,
            distanza_prossima: distanza,
            Ordine: ordine,
            tempo_prossima: tempo,
            lat: lat,
            id_cammino: tappa,
            lng: lng,
            costo: costo,
            livello: livello,
            foto_tappa: foto,
            attivo: attivo

        }))
    }


    let result = await axios.put(`${process.env.REACT_APP_API_KEY}/admin/tappe/${id_cammino}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
    })

    return result;
}



const Tappe = () => {


    const search = (keyword) => {

        let alloggi = alloggiBackup.filter((el) => el.nome_en.toUpperCase().includes(keyword.toUpperCase()))
        if (alloggi?.length == 0) {
            alloggi = alloggiBackup.filter((el) => el.luogo_en.toUpperCase().includes(keyword.toUpperCase()))
        }

        setAlloggi(alloggi)
    }


    const [loader, setLoader] = useState(false);

    const { token, setToken } = useToken();
    const [alloggi, setAlloggi] = useState([]);
    const [alloggiBackup, setAlloggiBackup] = useState([]);
    const [show, setShow] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [modalError, setModalError] = useState("");
    const [upload_preview, setUploadPreview] = useState('/immagine_profilo_default.svg');
    const [media, setMedia] = useState([]);


    const handleShowUpdate = () => setShowUpdate(true);
    const handleCloseUpdate = async () => {
        setShowUpdate(false);
        await setIdcammino();
        await setNome("");
        await setDescrizione("");
        await setFoto("");
        await setLuogo("");
        await setLivello(0);
        await setLat(0);
        await setLng(0);
        await setTempo(0);
        await setAttivo(0);
        await setDistanza(0);
        await setCosto(0);
        await setOrdine(0);
        await setUploadPreview('/immagine_profilo_default.svg');

    }
    const handleShowInfo = () => setShowInfo(true);
    const handleCloseInfo = async () => {
        setShowInfo(false)
        await setIdcammino();
        await setNome("");
        await setDescrizione("");
        await setFoto("");
        await setLuogo("");
        await setAttivo(0);
        await setLivello(0);
        await setLat(0);
        await setLng(0);
        await setTempo(0);
        await setDistanza(0);
        await setCosto(0);
        await setOrdine(0);
        await setUploadPreview('/immagine_profilo_default.svg');
    };
    const handleClose = async () => {
        setShow(false);
        await setIdcammino();
        await setNome("");
        await setDescrizione("");
        await setFoto("");
        await setLuogo("");
        await setLivello(0);
        await setLat(0);
        await setLng(0);
        await setTempo(0);
        await setAttivo(0);
        await setDistanza(0);
        await setCosto(0);
        await setOrdine(0);
        await setUploadPreview('/immagine_profilo_default.svg');
    }
    const handleShow = () => setShow(true);
    const [tappe, setTappe] = useState([]);
    const [nome, setNome] = useState("");
    const [id_cammino, setIdcammino] = useState(0);
    const [descrizione, setDescrizione] = useState("");
    const [foto, setFoto] = useState("");
    const [file, setFile] = useState();
    const [prezzo_min, setPrezzoMin] = useState(0);
    const [prezzo_max, setPrezzoMax] = useState(0);
    const [link, setLink] = useState("");
    const [luogo, setLuogo] = useState("");
    const [numero_telefono, setNumeroTelefono] = useState(0);
    const [email, setEmail] = useState("");
    const [tappa, setTappa] = useState(0);
    const [costo_pre_prenotazione, setPrePrenotazione] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [attivo, setAttivo] = useState(0);
    const [id_tappa, setId_tappa] = useState(0);



    const [regioni, setRegioni] = useState([]);
    const [livello, setLivello] = useState(0);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [tempo, setTempo] = useState(0);
    const [distanza, setDistanza] = useState(0);
    const [costo, setCosto] = useState(0);
    const [ordine, setOrdine] = useState(0);


    const get_alloggi = useEffect(async () => {

        //getRegione
        let regioni = await getRegioni(token)
        await setRegioni(regioni)
        let result = await getAlloggi(token);
        setAlloggi(result);
        setAlloggiBackup(result);
    }, []);



    return (
        <div>
            <Modal show={modalError != ""} onHide={() => { setModalError("") }}>
                <Modal.Body>
                    <div>
                        <img onClick={() => { setModalError("") }} className="btn-close-img" src="/closeBtn.svg" />
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "80px" }}>
                        <img style={{ width: "60px" }} className="btn-close-img1" src="/errorImage.svg" />
                        <div style={{ color: "#FF1400", fontSize: "1.4em", fontWeight: "500", marginTop: "20px", marginBottom: "0px" }}>{modalError}<br></br></div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showDelete} onHide={handleCloseInfo}>
                <Modal.Body>
                    <div>
                        <img onClick={() => { setShowDelete(false) }} className="btn-close-img" src="/closeBtn.svg" />
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "80px" }}>
                        <img style={{ width: "50px" }} className="btn-close-img1" src="/deletePopup.svg" />
                        <p style={{ color: "#FF1400", fontSize: "1.7em", fontWeight: "500", marginTop: "20px", marginBottom: "0px" }} >Disattiva Tappa</p>
                        <p style={{ color: "#2F2F2F", fontSize: "1.1em", fontWeight: "500", marginBottom: "5px" }} >Vuoi disattivare <span style={{ fontWeight: "700" }}>{nome}?<br></br>
                        </span></p>
                        <button onClick={async () => {
                            await deletecammino(token, id_cammino, foto);
                            let result_post_delete = await getAlloggi(token);
                            setShowDelete(false)
                            setAlloggi(result_post_delete);
                        }} className="buttonDelete" style={{ marginTop: "15px", fontWeight: "600", height: "55px", backgroundColor: "transparent", border: "1px solid #FF1400", color: "#FF1400", fontSize: "1em", width: "40%", borderRadius: "20px" }}>Disattiva</button>
                    </div>
                </Modal.Body>
            </Modal>


            <Offcanvas className="offcanvas-custom" placement="end" show={showUpdate} onHide={handleCloseUpdate}>
                <Offcanvas.Header >
                    <img onClick={handleCloseUpdate} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Tappe - Modifica</p>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                        <h1 style={{ fontSize: "1.3em", fontWeight: "700", marginBottom: "0", marginTop: "30px" }}>Modifica</h1>
                        <h2 style={{ fontSize: "1.6em", fontWeight: "300" }}>{nome}</h2>
                        <div>
                            <div>
                                <div>

                                    <div style={{ marginTop: "40px" }} className="  mb-2">

                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label style={{ marginBottom: "20px" }} data-error="wrong" data-success="right" for="orangeForm-name">Cambia Immagine</label>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ height: "100px", width: "100px", display: "inline-block", backgroundImage: `url(${(foto != "" && foto != null && upload_preview == '/immagine_profilo_default.svg') ? foto : upload_preview})`, backgroundSize: "cover", borderRadius: "100%", backgroundPosition: "center" }}>
                                            </div>
                                            <label htmlFor={`upload-photo-Immagine`} className="file" style={{ width: "70%", position: "relative", left: "20px", bottom: "8px", alignItems: "center", justifyContent: "center" }}>
                                                <span>
                                                    <h6 style={
                                                        { display: "inline-block", marginBottom: "0px" }}>Cambia Immagine</h6>
                                                </span>
                                                <input type="file"
                                                    style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                                                    id={`upload-photo-Immagine`}
                                                    onChange={async (e) => {
                                                        if (e.target.files[0]) {
                                                            await setUploadPreview(URL.createObjectURL(e.target.files[0]))
                                                        }
                                                        await setFile(e.target.files[0]);
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>


                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Seleziona Itinerario</label>
                                        <select defaultValue={id_tappa} onChange={async (e) => {
                                            await setId_tappa(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">

                                            <option value="gergerge">Seleziona un cammino associato</option>
                                            {tappe.map((tappa) => {
                                                return tappa.nome_en != null && <option key={tappa.id} value={tappa.id} >{tappa.nome_en}</option>
                                            }
                                            )}
                                        </select>
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Attivo</label>
                                        <select defaultValue={attivo} onChange={async (e) => {
                                            await setAttivo(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">
                                            <option value={0}>Attivo</option>
                                            <option value={1}>Non Attivo</option>
                                        </select>
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Nome Tappa</label>
                                        <input defaultValue={nome} onChange={async (e) => {
                                            await setNome(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Descrizione Tappa</label>
                                        <textarea defaultValue={descrizione} onChange={async (e) => {
                                            await setDescrizione(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Costo Bagaglio</label>
                                        <textarea defaultValue={costo} onChange={async (e) => {
                                            await setCosto(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Regione</label>

                                        {
                                            //select regione
                                            <select defaultValue={
                                                regioni.map((regione) => {
                                                    if (regione.title_en == luogo) {
                                                        return regione.title_en
                                                    }
                                                })
                                            } onChange={async (e) => {
                                                await setLuogo(e.target.value);
                                            }
                                            } type="select" id="orangeForm-name" className="form-control validate">

                                                <option value={
                                                    regioni.map((regione) => {
                                                        if (regione.title_en == luogo && regione.title_en != null) {
                                                            return regione.title_en
                                                        }
                                                    })
                                                }>{
                                                        regioni.map((regione) => {
                                                            if (regione.title == luogo && regione.title_en != null) {
                                                                return regione.title_en
                                                            }
                                                        })
                                                    }</option>
                                                {regioni.map((regione) => {
                                                    return regione.title_en != null &&
                                                        <option key={regione.id} value={regione.title_en}>{regione.title_en}</option>
                                                })}
                                            </select>
                                        }

                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Distanza prossima tappa</label>
                                        <textarea defaultValue={distanza} onChange={async (e) => {
                                            await setDistanza(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Tempo prossima tappa</label>
                                        <textarea defaultValue={tempo} onChange={async (e) => {
                                            await setTempo(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>


                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Lat</label>
                                        <textarea defaultValue={lat} onChange={async (e) => {
                                            await setLat(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Lng</label>
                                        <textarea defaultValue={lng} onChange={async (e) => {
                                            await setLng(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Livello difficoltà</label>
                                        <textarea defaultValue={livello} onChange={async (e) => {
                                            await setLivello(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                </div>

                                <Gallery tappa={id_cammino} token_={token} />


                                <div className="">

                                    {loader && <div style={{ textAlign: "center", paddingTop: "20px" }}><img src="/loader.gif" style={{ borderRadius: "100%" }} /></div>}
                                    {!loader &&
                                        <button onClick={async () => {

                                            await setLoader(true);
                                            let check_campi = await checkCampi(nome, file, descrizione, costo, luogo, distanza, ordine, tempo, lat, lng, livello, id_tappa, upload_preview, foto);
                                            if (check_campi == true) {
                                                await setModalError("");
                                                let resultInsert = await updatecammino(token, id_cammino, nome, file, descrizione, costo, luogo, distanza, ordine, tempo, lat, lng, livello, id_tappa, foto, attivo);

                                                if (resultInsert.statusText == "OK") {
                                                    handleCloseUpdate();
                                                    await setFile()
                                                    await setModalError("")
                                                    let result_post_update = await getAlloggi(token);
                                                    setAlloggi(result_post_update);
                                                    setAlloggiBackup(result_post_update);
                                                    setUploadPreview("/immagine_profilo_default.svg")
                                                }
                                                await setLoader(false);
                                            }

                                            else {
                                                await setModalError(check_campi);
                                                await setLoader(false);
                                            }

                                        }}

                                            className="btn btn-deep-orange close btn-update" data-dismiss="modal" aria-label="Close">Salva</button>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>



                </Offcanvas.Body>
            </Offcanvas>




            <Offcanvas className="offcanvas-custom" placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Header>

                    <img onClick={handleClose} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Tappe - Aggiungi</p>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                        <div>
                            <div>
                                <div>
                                    <h1 style={{ fontSize: "1.3em", fontWeight: "700", marginBottom: "40px", marginTop: "30px" }}>Aggiungi Tappa</h1>

                                    <div style={{ marginTop: "40px" }} className="  mb-2">

                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label style={{ marginBottom: "20px" }} data-error="wrong" data-success="right" for="orangeForm-name">Inserisci Immagine</label>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ height: "100px", width: "100px", display: "inline-block",  backgroundImage: `url(${(upload_preview == '/immagine_profilo_default.svg') ? upload_preview : upload_preview})`, backgroundSize: "cover", borderRadius: "100%", backgroundPosition: "center" }}>
                                            </div>
                                            <label htmlFor={`upload-photo-Immagine`} className="file" style={{ width: "70%", position: "relative", left: "20px", bottom: "8px", alignItems: "center", justifyContent: "center" }}>
                                                <span>
                                                    <h6 style={
                                                        { display: "inline-block", marginBottom: "0px" }}>Inserisci Immagine</h6>
                                                </span>
                                                <input type="file"
                                                    style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                                                    id={`upload-photo-Immagine`}
                                                    onChange={async (e) => {
                                                        if (e.target.files[0]) {
                                                            await setUploadPreview(URL.createObjectURL(e.target.files[0]))
                                                        }
                                                        await setFile(e.target.files[0]);
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Attivo</label>
                                        <select defaultValue={attivo} onChange={async (e) => {
                                            await setAttivo(e.target.value);
                                        }} type="select" id="orangeForm-name" className="form-control validate">
                                            <option value={0}>Attivo</option>
                                            <option value={1}>Non Attivo</option>
                                        </select>
                                    </div>

                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Seleziona Itinerario</label>

                                        <select defaultValue="Seleziona una tappa associata" onChange={async (e) => {
                                            await setTappa(e.target.value);

                                        }} type="select" id="orangeForm-name" className="form-control validate">

                                            <option value="gergerge">Seleziona un cammino associato</option>
                                            {tappe.map((tappa) => { return tappa.nome_en != null && <option key={tappa.id} value={tappa.id} >{tappa.nome_en}</option> }
                                            )}
                                        </select>
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Nome Tappa</label>
                                        <input onChange={async (e) => {

                                            await setNome(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Descrizione Tappa</label>
                                        <textarea onChange={async (e) => {
                                            await setDescrizione(e.target.value);
                                        }} type="text" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Costo Bagaglio</label>
                                        <input onChange={async (e) => {
                                            await setCosto(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Regione</label>
                                        {
                                            //select regione
                                            <select defaultValue="Seleziona una regione" onChange={async (e) => {
                                                await setLuogo(e.target.value);

                                            }
                                            } type="select" id="orangeForm-name" className="form-control validate">
                                                <option value="Seleziona una regione">Seleziona una regione</option>
                                                {regioni.map((regione) => {
                                                    return regione.title_en != null && <option key={regione.id} value={regione.title_en}>{regione.title_en}</option>
                                                })}
                                            </select>
                                        }

                                    </div>

                                    <div className="mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Distanza prossima tappa (m)</label>
                                        <input onChange={async (e) => {
                                            await setDistanza(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Tempo prossima tappa(m)</label>
                                        <input onChange={async (e) => {
                                            await setTempo(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>


                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Lat</label>
                                        <input defaultValue={lat} onChange={async (e) => {
                                            await setLat(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>

                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Lng</label>
                                        <input defaultValue={lng} onChange={async (e) => {
                                            await setLng(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>
                                    <div className=" mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <label data-error="wrong" data-success="right" for="orangeForm-name">Livello difficoltà</label>
                                        <input defaultValue={livello} onChange={async (e) => {
                                            await setLivello(e.target.value);
                                        }} type="number" id="orangeForm-name" className="form-control validate" />
                                    </div>



                                </div>

                                <div className="modal-footer d-flex justify-content-center">
                                    {loader && <div style={{ textAlign: "center", paddingTop: "20px" }}><img src="/loader.gif" style={{ borderRadius: "100%" }} /></div>}
                                    {!loader &&
                                        <button onClick={async () => {
                                            let check_campi = await checkCampi(nome, file, descrizione, costo, luogo, distanza, ordine, tempo, lat, lng, livello, tappa, upload_preview, foto);
                                            setLoader(true);

                                            if (check_campi == true) {
                                                
                                                await setModalError("");
                                                let resultInsert = await insertcammino(token, id_cammino, nome, file, descrizione, costo, luogo, distanza, ordine, tempo, lat, lng, livello, tappa, attivo);
                                                if (resultInsert.status == "ok") {
                                                    handleClose();
                                                    await setModalError("")
                                                    let result_post_delete = await getAlloggi(token);
                                                    setAlloggi(result_post_delete);
                                                    setAlloggiBackup(result_post_delete);
                                                    setLoader(false);
                                                }
                                                let result = await getAlloggi(token);
                                                setAlloggi(result);
                                                setAlloggiBackup(result);
                                            }
                                            else {
                                                await setModalError(check_campi);
                                            }
                                        }}
                                            className="btn btn-deep-orange close btn-update" data-dismiss="modal" aria-label="Close">Inserisci Tappa</button>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>


            <Offcanvas className="offcanvas-custom" placement="end" show={showInfo} onHide={handleCloseInfo}>
                <Offcanvas.Header >
                    <img onClick={handleCloseInfo} className="btn-close-img" src="/closeBtn.svg" />
                    <p style={{ color: "#B1B1B1", fontSize: "2em", marginBottom: "0", position: "absolute", left: "60px" }}>Tappe - Info</p>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <div>
                            <div>
                                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                    <div className="div-image" style={{ backgroundImage: `url("${foto}")`, display: "inline-block" }}>
                                    </div>
                                    <div style={{ display: "inline-block", position: "relative", bottom: "50px", left: "30px" }}>
                                        <img onClick={() => { setShowInfo(false); setShowUpdate(true) }} style={{ marginBottom: "13px", cursor: "pointer" }} src="/updateTable.svg" /><br></br>
                                        <img
                                            onClick={() => {
                                                setShowDelete(true);
                                                setShowInfo(false)
                                            }}
                                            style={{ cursor: "pointer" }} src="/deleteTable.svg" />
                                    </div>
                                    <div className=" mb-2">
                                        <p className="nome">{nome}</p>
                                    </div>

                                    <div className="md-form  mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Attivo</b>
                                        <p style={{ color: "#A9DE5B" }}>{attivo == 0 && "Attivo"}</p>
                                        <p style={{ color: "red", position: "relative", bottom: "10px" }}>{attivo == 1 && "Non Attivo"}</p>
                                    </div>

                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Descrizione tappa</b>
                                        <p>{descrizione}</p>
                                    </div>

                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Costo tappa</b>
                                        <p>{costo}</p>
                                    </div>
                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Luogo tappa</b>
                                        <p>{luogo}</p>
                                    </div>

                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Distanza prossima tappa</b>
                                        <p>{distanza}</p>
                                    </div>

                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Tempo per prossima tappa</b>
                                        <p>{tempo}</p>
                                    </div>
                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Ordine tappa</b>
                                        <p>{ordine}</p>
                                    </div>
                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Lat</b>
                                        <p>{lat}</p>
                                    </div>
                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Lng</b>
                                        <p>{lng}</p>
                                    </div>
                                    <div className="md-form mb-2">
                                        <i className="fas fa-user prefix grey-text"></i>
                                        <b>Livello difficoltà</b>
                                        <p>{livello}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            <div style={{ position: "absolute", top: "0", paddingTop: "65px", width: "100%", backgroundColor: "#F4F4F4", zIndex: "1000" }}>

                <h1 className="title-sezione"><img src="/tappeIcone.svg" style={{ position: "relative", bottom: "3px", width: "23px", marginRight: "12px" }} />Tappe</h1>

                <div style={{ marginTop: "40px", width: "75%" }}>
                    <SearchForm nome="Cerca" search={search} />
                    <button
                        variant="primary"
                        onClick={async () => {
                            handleShow();
                            let get_tappe = await getTappe(token);
                            await setTappe(get_tappe);
                            handleShow();

                        }} className="btn btn-default btn-rounded mb-4 aggiungi-struttura " data-toggle="modal" data-target="#modalRegisterForm"
                        style={{ position: "relative", float: "right", border: "none", boxShadow: "0px 3px 6px #00000029", borderRadius: "20px", height: "50px", width: "20%", fontSize: "0.9em" }}>Aggiungi Tappa</button>
                </div>
            </div>
            <div className="alloggi overflow-responsive Tappe" style={{ marginTop: "190px", paddingBottom: "100px", borderRadius: "25px", position: "relative", left: "" }}>





                {alloggi.map((cammino) =>
                    <div className="div-cammini" style={{ padding: "10px", paddingBottom: "0", marginBottom: "2%", marginLeft: "1%",  display: "inline-block", backgroundColor: "#ffffff", boxShadow: "0px 3px 6px #00000029", borderRadius: "20px" }} key={cammino.id}>

                        <div style={{ height: "125px", width: "100px", backgroundImage: `url("${cammino.foto_tappa}")`, backgroundPosition: "center", backgroundSize: "cover", borderRadius: "20px", display: "inline-block" }}></div>
                        <div style={{ paddingLeft: "10", paddingBottom: "0", display: "inline-block", position: "relative", bottom: "20px", left: "20px" }}>
                            {cammino.attivo == 1 && <p style={{ color: "red", fontSize: "0.8em", fontWeight: "700", marginBottom: "0" }}>Non Attivo</p>}
                            {cammino.attivo == 0 && <p style={{ color: "#A9DE5B", fontSize: "0.8em", fontWeight: "700", marginBottom: "0" }}>Attivo</p>}
                            <p style={{ color: "#B1B1B1", fontSize: "1em", fontWeight: "300", marginBottom: "0" }}>{cammino.luogo_en?.substring(0, 25)}{cammino.luogo_en?.length > 25 ? <>..</> : null}</p>
                            <p style={{ color: "#2F2F2F", fontSize: "1em", fontWeight: "700", marginBottom: "10px" }}>{cammino.nome_en?.substring(0, 25)}{cammino.nome_en?.length > 25 ? <>..</> : null}</p>
                            <div>
                                <button onClick={async () => {
                                    await setIdcammino(cammino.id);
                                    await setNome(cammino.nome_en);
                                    await setAttivo(cammino.attivo);
                                    await setDescrizione(cammino.descrizione_en);
                                    await setFoto(cammino.foto_tappa);
                                    await setLuogo(cammino.luogo_en);
                                    await setLivello(cammino.livello);
                                    await setLat(cammino.lat);
                                    await setLng(cammino.lng);
                                    await setTempo(cammino.tempo_prossima);
                                    await setDistanza(cammino.distanza_prossima);
                                    await setCosto(cammino.costo);
                                    await setOrdine(cammino.Ordine);
                                    await setId_tappa(cammino.id_cammino);


                                    handleShowInfo();
                                }} style={{ backgroundColor: "transparent", boxShadow: "none", border: "none", paddingLeft: "0" }}><img style={{ width: "25px" }} src="/infoTable.svg" /></button>
                                <button onClick={async () => {
                                    await setIdcammino(cammino.id);
                                    await setNome(cammino.nome_en);
                                    await setDescrizione(cammino.descrizione_en);
                                    await setFoto(cammino.foto_tappa);
                                    await setLuogo(cammino.luogo_en);
                                    await setLivello(cammino.livello);
                                    await setAttivo(cammino.attivo);
                                    await setLat(cammino.lat);
                                    await setLng(cammino.lng);
                                    await setCosto(cammino.costo);
                                    await setDistanza(cammino.distanza_prossima);
                                    await setCosto(cammino.costo);
                                    await setOrdine(cammino.Ordine);
                                    await setTempo(cammino.tempo_prossima);
                                    await setId_tappa(cammino.id_cammino);
                                    let get_tappe = await getTappe(token);
                                    await setTappe(get_tappe);
                                    handleShowUpdate();
                                }} style={{ backgroundColor: "transparent", border: "none", boxShadow: "none", paddingLeft: "0" }}><img style={{ width: "25px" }} src="/updateTable.svg" /></button>
                                <button onClick={async () => {
                                    await setIdcammino(cammino.id);
                                    await setNome(cammino.nome_en);
                                    await setDescrizione(cammino.descrizione_en);
                                    await setFoto(cammino.foto_tappa);
                                    await setLuogo(cammino.luogo_en);
                                    await setAttivo(cammino.attivo);
                                    await setLivello(cammino.livello);
                                    await setLat(cammino.lat);
                                    await setLng(cammino.lat); await setCosto(cammino.costo);
                                    await setDistanza(cammino.distanza_prossima);
                                    await setCosto(cammino.costo);
                                    await setId_tappa(cammino.id_cammino);
                                    let get_tappe = await getTappe(token);
                                    await setTappe(get_tappe);
                                    setShowDelete(true);
                                }} style={{ backgroundColor: "transparent", border: "none", boxShadow: "none", paddingLeft: "0" }}><img style={{ width: "25px" }} src="/deleteTable.svg" /></button></div>
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
};

export default Tappe;